import "./EmployeeFollowUpItemsPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";

import { AddEmployeeFollowUpItemPopup } from "./popups/AddEmployeeFollowUpItemPopup";
import { ReactComponent as AddIcon } from "../../../../lib/assets/icons/add.svg";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { DateTimeHelper } from "../../../../lib/helpers/datetime/DateTime";
import { ReactComponent as DeleteIcon } from "../../../../lib/assets/icons/remover.svg";
import { EditEmployeeFollowUpItemPopup } from "./popups/EditEmployeeFollowUpItemPopup";
import { ReactComponent as EditIcon } from "../../../../lib/assets/icons/editar.svg";
import { EmployeeFollowUpItemsService } from "../../services/EmployeeFollowUpItemsService";
import { EmployeeFollowUpItemsSummaryDTO } from "../../models/dtos/EmployeeFollowUpItemsSummaryDTO";
import { EmployeeStatusTranslationTerms } from "../../models/dtos/EmployeeFollowUpItemStatus";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { Permission } from "../../../../models/api/gate/Permission";
import { QueryOptions } from "../../../../models/api/QueryOptions";
import { ResponsiveButton } from "../../../../lib/components/buttons/ResponsiveButton";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { ReactComponent as SearchIcon } from "../../../../lib/assets/icons/search-icon.svg";
import { Tag } from "../../../../lib/components/tag/Tag";
import { Tooltip } from "../../../../lib/components/tooltip/Tooltip";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import axios from "axios";
import { getEmployeeFollowUpItemStatusCss } from "../../../../common/helpers/getEmployeeFollowUpItemStatusCss";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useParams } from "react-router-dom";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";

var svc = new EmployeeFollowUpItemsService();

export interface IOrderInfo {
    direction: "ASC" | "DESC" | "NONE";
    columnKey: string;
}

const topPermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES];

export function EmployeeFollowUpItemsPage() {
    const { isLoadingPermissions, hasPermission } = useHasPermissions(topPermissions);
    const windowResize = useWindowResize();
    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();
    const locationID = useGlobalLocation();
    const locationIDAsString = useMemo(() => "" + locationID, [locationID])
    const { employeeId } = useParams();

    const [items, setItems] = useState<EmployeeFollowUpItemsSummaryDTO[]>();
    const [totalItems, setTotalItems] = useState<number>();

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentpage, setCurrentPage] = useState(0);

    const [timer, setTimer] = useState<NodeJS.Timeout>();
    const [searchWord, setSearchWord] = useState<string>();

    const getFollowUpItemsCall = useServiceCallPro(svc.getFollowUpItems);
    const deleteFollowUpItemCall = useServiceCallPro(svc.deleteFollowUpItem);

    var searchboxController = useFormControl<string>({
        isDisabled: false,
        enableAutoValidate: false,
    });


    /****************************
    * DATA REQUESTS
    *****************************/

    const getFollowUpItems = useCallback((controller: AbortController | undefined) => {
        if (!locationIDAsString || !employeeId) return;

        var queryOpts: QueryOptions = {
            searchWord: searchWord,
            page: currentpage,
            pageLength: itemsPerPage
        };

        getFollowUpItemsCall.invoke(locationIDAsString, employeeId, queryOpts, controller?.signal)
            .then((data) => {
                if (data) {
                    setItems(data.items);
                    setTotalItems(data.totalItems)
                }
            })
            .catch((error) => {
                if (!error || axios.isCancel(error)) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, employeeId, getFollowUpItemsCall.invoke, openPopup, currentpage, itemsPerPage, searchWord]);


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        const controller = new AbortController();
        getFollowUpItems(controller);
        return () => { controller.abort() };
    }, [currentpage, itemsPerPage, searchWord]);


    useEffect(() => {
        if (timer) { clearTimeout(timer); }

        var responseTimeOut = setTimeout(() => {
            if (timer) { setSearchWord(searchboxController.value); }
            setCurrentPage(0);
        }, 1000);

        setTimer(responseTimeOut);
    }, [searchboxController.value]);


    /****************************
     * USER ACTIONS
     *****************************/

    const handleOnPageAndItemsChanged = useCallback((items: number, currentPage: number) => {
        setItemsPerPage(items);
        setCurrentPage(currentPage);
    }, [setItemsPerPage, setCurrentPage]);


    const handleAddItemButtonClicked = useCallback(() => {
        openPopup(<AddEmployeeFollowUpItemPopup locationId={locationIDAsString} employeeId={employeeId} onCompletedOperations={() => getFollowUpItems(undefined)} />)
    }, [openPopup, locationIDAsString, employeeId, getFollowUpItems]);


    const handleEditItem = useCallback((id: number) => {
        openPopup(<EditEmployeeFollowUpItemPopup locationId={locationIDAsString} employeeId={employeeId} itemId={id} onCompletedOperations={() => getFollowUpItems(undefined)} />)
    }, [openPopup, locationIDAsString, employeeId]);


    const handleDeleteItem = useCallback((itemId: number) => {
        if (!locationIDAsString || !employeeId || !itemId) return;

        deleteFollowUpItemCall
            .invoke(locationIDAsString, "" + employeeId, "" + itemId)
            .then(() => {
                getFollowUpItems(undefined);
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("EMPLOYEES.ITEMS.POPUPS.DeleteFollowUpItemSuccess")}
                    />
                );
            })
            .catch((error) => {
                if (!error) return;
                const errorMessage = error?.response?.data?.message || translate("ERRORS.UnknownError");
                openPopup(<ErrorPopup>{errorMessage}</ErrorPopup>);
            });
    }, [openPopup, locationIDAsString, employeeId, getFollowUpItems, createNotification, deleteFollowUpItemCall.invoke]);


    const handleDeleteItemWarningPopup = useCallback((id: number) => {
        openPopup(
            <WarningPopup
                className="delete-item-popup"
                onDismissClicked={() => closePopup()}
                onConfirmClicked={() => handleDeleteItem(id)}
                dismissText={translate("COMMON.Cancel")}
                confirmText={translate("COMMON.Delete")}
            >
                {translate("EMPLOYEES.ITEMS.POPUPS.DeleteFollowUpItemConfirmText")}
            </WarningPopup>
        )
    }, [openPopup, closePopup, handleDeleteItem]);


    /****************************
     * CSS & HTML
     *****************************/

    const columns: ColumnDefinition<EmployeeFollowUpItemsSummaryDTO>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => v.name,
                headerRender: translate("EMPLOYEES.ITEMS.INFOANDFORM.Name"),
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v) => v.description,
                headerRender: translate("EMPLOYEES.ITEMS.INFOANDFORM.Description"),
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v) => (
                    <Tag
                        text={translate(EmployeeStatusTranslationTerms[v.status])}
                        backgroundColor={getEmployeeFollowUpItemStatusCss(v.status)}
                        isTiny={windowResize < 1024}
                    />
                ),
                headerRender: translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.Status"),
                isStatusCell: true,
                width: "120px"
            },
            {
                cellRenderProp: (v) => DateTimeHelper.formatDateTimeLocale(v.startDate),
                headerRender: translate("EMPLOYEES.ITEMS.INFOANDFORM.StartDate"),
                width: "140px",
            },
            {
                cellRenderProp: (v) => DateTimeHelper.formatDateTimeLocale(v.conclusionDate),
                headerRender: translate("EMPLOYEES.ITEMS.INFOANDFORM.ConclusionDate"),
                width: "140px",
            },
            {
                cellRenderProp: (v) => {
                    return (
                        <Tooltip text={translate("EMPLOYEES.ITEMS.POPUPS.EditFollowUpItem")}>
                            <ScalableIconButton
                                icon={<EditIcon />}
                                onClick={() => handleEditItem(v.id)}
                                size={24}
                            />
                        </Tooltip>
                    );
                },
                width: "24px",
                isMobileHeaderIcon: true,
            },
            {
                cellRenderProp: (v) => {
                    return (
                        <Tooltip text={translate("EMPLOYEES.ITEMS.INFOANDFORM.DeleteFollowUpItem")}>
                            <ScalableIconButton
                                icon={<DeleteIcon />}
                                onClick={() => handleDeleteItemWarningPopup(v.id)}
                                size={24}
                            />
                        </Tooltip>
                    );
                },
                width: "24px",
                isMobileHeaderIcon: true,
            },
        ], [handleEditItem, handleDeleteItemWarningPopup, windowResize]);


    const renderActionButtons = useMemo(() => {
        if (isLoadingPermissions || !hasPermission)
            return null;

        return <ResponsiveButton
            text={translate("EMPLOYEES.ITEMS.AddFollowUpItem")}
            type="secondary"
            icon={<AddIcon />}
            onClick={handleAddItemButtonClicked}
        />;
    }, [isLoadingPermissions, hasPermission, handleAddItemButtonClicked]);


    return <PageLayout
        tabTitle={translate("EMPLOYEES.ITEMS.FollowUpItems")}
        pageTitle={translate("EMPLOYEES.ITEMS.FollowUpItems")}
        className="items-page"
        showBreadcrumb
        actions={renderActionButtons}
    >
        <FormFieldTextInput
            formControl={searchboxController}
            icon={<SearchIcon />}
            placeholder={translate("COMMON.FORM.SearchPlaceholder")}
            className="search-box"
        />
        <ResponsiveDataTable
            columnDefinitions={columns}
            items={items || []}
            totalitems={totalItems || 0}
            paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 20, 30] }}
            currentpage={currentpage}
            isLoading={getFollowUpItemsCall.isLoading}
            onPageAndItemsChanged={handleOnPageAndItemsChanged}
        />
    </PageLayout>;
}