import { SkillDomainDTO } from "../domain/SkillDomainDTO";
import { SkillSummaryDTO } from "../dtos/SkillSummaryDTO";


export function APISkillSummaryDTOToDomainMapper(apiDto: SkillSummaryDTO): SkillDomainDTO {
    return {
        id: apiDto.skillID,
        name: apiDto.name,
        isDisabled: apiDto.isDisabled
    };
}
