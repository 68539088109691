import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { EmployeeFollowUpItemCreateDTO } from "../models/dtos/EmployeeFollowUpItemCreateDTO";
import { EmployeeFollowUpItemStatus } from "../models/dtos/EmployeeFollowUpItemStatus";
import { EmployeeFollowUpItemUpdateDTO } from "../models/dtos/EmployeeFollowUpItemUpdateDTO";
import { EmployeeFollowUpItemsResponseDTO } from "../models/dtos/EmployeeFollowUpItemsResponseDTO";
import { EmployeeFollowUpItemsSummaryDTO } from "../models/dtos/EmployeeFollowUpItemsSummaryDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { QueryOptions } from "../../../models/api/QueryOptions";

const employeeFollowUpItemsMock: EmployeeFollowUpItemsResponseDTO = {
    items: [{
        id: 1,
        name: "Item A",
        description: "Lorem Ipsum",
        startDate: "2024-01-01T00:00:00+00:00",
        conclusionDate: "2024-01-01T00:00:00+00:00",
        status: EmployeeFollowUpItemStatus.REGISTERED
    }, {
        id: 2,
        name: "Item B",
        description: "Lorem Ipsum",
        startDate: "2024-01-01T00:00:00+00:00",
        conclusionDate: "2024-01-01T00:00:00+00:00",
        status: EmployeeFollowUpItemStatus.IN_PROGRESS
    }],
    totalItems: 2
}

const employeeFollowUpItemMock: EmployeeFollowUpItemsSummaryDTO = {
    id: 1,
    name: "Item A",
    description: "Lorem Ipsum",
    startDate: "2024-01-01T00:00:00+00:00",
    conclusionDate: "2024-01-01T00:00:00+00:00",
    status: EmployeeFollowUpItemStatus.REGISTERED
};

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class EmployeeFollowUpItemsService {

    getFollowUpItems(locationId: string, employeeId: string, query: QueryOptions, abortSignal: AbortSignal | undefined): Promise<EmployeeFollowUpItemsResponseDTO> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    items: employeeFollowUpItemsMock.items,
                    totalItems: employeeFollowUpItemsMock.totalItems
                });
            }, 1000);
        });

        // return HttpClient.sessionRequest<EmployeeFollowUpItemsResponseDTO>({
        //     method: "GET",
        //     url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Items`),
        //     signal: abortSignal,
        //     params: query,
        //     paramsSerializer: (params) => {
        //         return qs.stringify(params, { arrayFormat: "repeat" });
        //     },
        // })
        //     .then((response) => {
        //         if (!response.data) return null;
        //         return {
        //             items: response.data.items,
        //             totalItems: response.data.totalItems
        //         }
        //     })
        //     .catch((error) => {
        //         throw error;
        //     });
    }

    getFollowUpItemSummaryById(locationId: string, employeeId: string, itemId: string): Promise<EmployeeFollowUpItemsSummaryDTO> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(employeeFollowUpItemMock);
            }, 1000);
        });

        // return HttpClient.sessionRequest<EmployeeFollowUpItemsSummaryDTO>({
        //     method: "GET",
        //     url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Items/${itemId}`),
        // })
        //     .then((res) => {
        //         if (!res.data) return null;
        //         return res.data;
        //     })
        //     .catch((error) => {
        //         throw error;
        //     });
    }

    addFollowUpItem(locationId: string, employeeId: string, requestDto: EmployeeFollowUpItemCreateDTO): Promise<number> {
        return HttpClient.sessionRequest({
            method: "POST",
            // url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Items`),
            data: requestDto,
        })
            .then((response) => response.data.id)
            .catch((error) => {
                throw error;
            });
    }

    editFollowUpItem(locationId: string, employeeId: string, itemId: string, requestDto: EmployeeFollowUpItemUpdateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            // url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Items/${itemId}`),
            data: requestDto
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    deleteFollowUpItem(locationId: string, employeeId: string, itemId: string): Promise<void> {
        return HttpClient.sessionRequest({
            method: "DELETE",
            // url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Items/${itemId}`),
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

}
