import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { AttachmentDetailsDTO } from "../../../models/dtos/AttachmentDetailsDTO";
import { Checkbox } from "../../../../../lib/components/checkbox/Checkbox";
import { ColumnDefinition } from "../../../../../lib/components/table/TableInterfaces";
import { DateTime } from "luxon";
import { ReactComponent as DeleteIcon } from "../../../../../lib/assets/icons/remover.svg";
import { EmployeeCertificationUpdateDTO } from "../../../models/dtos/EmployeeCertificationUpdateDTO";
import { EmployeeCertificationsService } from "../../../services/EmployeeCertificationsService";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FileUploadButton } from "../../../../../lib/components/file/FileUploadButton";
import { FormFieldDatePicker } from "../../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { ReactComponent as ImportIcon } from "../../../../../lib/assets/icons/import.svg";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { ResponsiveDataTable } from "../../../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../../../lib/components/buttons/ScalableIconButton";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../../lib/hooks/useServiceCall";
import { validateDateTime } from "../../../../../lib/validators/ValidateDateTime";
import { validateWhiteSpaceStrings } from "../../../../../lib/validators/ValidateWhiteSpaceStrings";

interface IEditEmployeeCertificationPopupProps {
    locationId: string | undefined;
    employeeId: string | undefined;
    certificationId: number;
    onCompletedOperations: () => void;
}

var svc = new EmployeeCertificationsService();

export function EditEmployeeCertificationPopup(props: IEditEmployeeCertificationPopupProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();
    const { locationId, employeeId, certificationId } = props;

    const [isApproved, setIsApproved] = useState<boolean>(false);
    const [attachmentFile, setAttachmentFile] = useState<File>();
    const [attachments, setAttachments] = useState<AttachmentDetailsDTO[]>();


    const getCertificationSummaryByIdCall = useServiceCallPro(svc.getCertificationSummaryById);
    const editCertificationCall = useServiceCallPro(svc.editCertification);
    const uploadCertificationAttachmentCall = useServiceCallPro(svc.uploadCertificationAttachment);
    const deleteCertificationAttachmentCall = useServiceCallPro(svc.deleteCertificationAttachment);

    const nameFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const dateFormControl = useFormControl<DateTime>({
        validators: [validateDateTime()],
        enableAutoValidate: false
    });



    /****************************
    * DATA REQUESTS
    *****************************/

    const getCertificationSummaryById = useCallback(() => {
        if (!locationId || !employeeId || !certificationId) return;

        getCertificationSummaryByIdCall.invoke(locationId, employeeId, "" + certificationId)
            .then((data) => {
                if (data) {
                    nameFormControl.setValue(data.name)
                    if (data.date) { dateFormControl.setValue(DateTime.fromISO(data.date)); }
                    setIsApproved(data.isApproved);
                    if (data.attachment) {
                        setAttachments([{
                            name: data.attachment.name,
                            url: data.attachment.url
                        }]);
                    }
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationId, employeeId, certificationId, getCertificationSummaryByIdCall.invoke, openPopup, dateFormControl.setValue, nameFormControl.setValue]);

    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/

    useEffect(() => {
        getCertificationSummaryById();
    }, []);


    /****************************
    * USER ACTIONS
    *****************************/

    const handleCheckboxChange = useCallback((isChecked: boolean) => {
        setIsApproved(isChecked);
    }, [])


    const handleFileImport = useCallback((file: File) => {
        setAttachmentFile(file);
        setAttachments([{
            name: file.name,
            url: file.webkitRelativePath
        }]);
    }, []);


    const handleDeleteAttachment = useCallback(() => {
        if (!locationId || !employeeId || !certificationId) return;

        deleteCertificationAttachmentCall
            .invoke(locationId, employeeId, "" + certificationId)
            .then((_) => {
                setAttachments([]);
                setAttachmentFile(undefined);
            }).catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationId, employeeId, certificationId, deleteCertificationAttachmentCall, openPopup]);


    const handleSaveClicked = useCallback(() => {
        if (!locationId || !employeeId || !certificationId) return;
        if (!AllValid(nameFormControl.validate(), dateFormControl.validate())) return;

        if (!nameFormControl.value) return;
        let request: EmployeeCertificationUpdateDTO = {
            name: nameFormControl.value,
            date: dateFormControl.value?.toISO() || null,
            isApproved: isApproved,
        };

        editCertificationCall
            .invoke(locationId, employeeId, "" + certificationId, request)
            .then(() => {
                if (attachmentFile) {
                    uploadCertificationAttachmentCall
                        .invoke(locationId, employeeId, "" + certificationId, attachmentFile)
                        .then(_ => {
                            createNotification(<Notification
                                type="success"
                                title={translate("COMMON.SYSTEMPOPUPS.Success")}
                                text={translate("EMPLOYEES.CERTIFICATIONS.POPUPS.EditCertificationSuccess")}
                            />);
                            closePopup();
                            props.onCompletedOperations();
                        }).catch(error => {
                            if (!error) return;
                            openPopup(<ErrorPopup>{translate("EMPLOYEES.CERTIFICATIONS.POPUPS.AddCertificationUploadError")}</ErrorPopup>);
                        });
                }
                else {
                    createNotification(<Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("EMPLOYEES.CERTIFICATIONS.POPUPS.EditCertificationSuccess")}
                    />);
                    closePopup();
                    props.onCompletedOperations();
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [closePopup, nameFormControl, dateFormControl, isApproved, createNotification, openPopup, employeeId, locationId, certificationId, props.onCompletedOperations, editCertificationCall.invoke, uploadCertificationAttachmentCall.invoke, attachmentFile]);


    /****************************
     * CSS & HTML
     *****************************/

    const columns: ColumnDefinition<AttachmentDetailsDTO>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => v.name,
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v) => {
                    return <ScalableIconButton
                        icon={<DeleteIcon />}
                        onClick={handleDeleteAttachment}
                        size={24}
                    />;
                },
                width: "24px",
                isMobileHeaderIcon: true,
            },
        ], [handleDeleteAttachment]);


    return <PopupContainer className="popup-edit-employee-certification">
        {editCertificationCall.isLoading ? <FullScreenLoader /> : null}
        <PopupHeader title={translate("EMPLOYEES.CERTIFICATIONS.POPUPS.EditCertification")} />
        <PopupContent isLoading={getCertificationSummaryByIdCall.isLoading}>
            <FormSection isInputGap childrenFlexDirColumn>
                <FormFieldTextInput
                    formControl={nameFormControl}
                    label={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.Name")}
                    placeholder={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.NamePlaceholder")}
                />
                <FormFieldDatePicker
                    formControl={dateFormControl}
                    label={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.Date")}
                    placeholder={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.DatePlaceholder")}
                    maxDate={dateFormControl.value}
                />
                <Spacer mode={"vertical"} px="0" />
                <Checkbox
                    text={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.Approved")}
                    isChecked={isApproved}
                    onClick={(isChecked) => handleCheckboxChange(isChecked)}
                />
                <Spacer mode={"vertical"} px="0" />
                <div className="attachment-info">
                    {(!attachments || attachments.length === 0) && (
                        <>
                            <FileUploadButton
                                text={translate("COMMON.AddAttachment")}
                                type="secondary"
                                onFileSelected={handleFileImport}
                                icon={<ImportIcon />}
                            />
                            <Spacer mode={"vertical"} px="10" />
                        </>
                    )}
                    {attachments && attachments.length > 0 && (
                        <>
                            <div>{translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.Attachment")}</div>
                            <Spacer mode={"vertical"} px="8" />
                            <ResponsiveDataTable
                                columnDefinitions={columns}
                                items={attachments}
                                totalitems={0}
                                onClickRow={(attachment) => attachment.url && window.open(attachment.url, "_blank")}
                            />
                        </>
                    )}
                </div>
            </FormSection>
            <Spacer mode={"vertical"} px="30" />
            <PopupActionButtons
                buttons={[
                    {
                        text: translate("COMMON.Cancel"),
                        type: "tertiary",
                        onClick: () => closePopup(),
                    },
                    {
                        text: translate("COMMON.Save"),
                        type: "primary",
                        onClick: handleSaveClicked,
                    },
                ]}
            />
        </PopupContent>
    </PopupContainer>;
}