import "./CreateEvaluationMatrixPage.css";

import { useCallback, useMemo, useState } from "react";
import { useCreateNotification, usePopup, useWindowResize } from "../../../lib/infrastructure/ui/UIServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ReactComponent as AddIcon } from "../../../lib/assets/icons/add.svg";
import { AllValid } from "../../../common/validators/ValidateFormControls";
import { Button } from "../../../lib/components/buttons/Button";
import { ChecklistCard } from "../components/ChecklistCard";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { EvaluationMatricesService } from "../services/EvaluationMatricesService";
import { EvaluationMatrixCategorySkillsCreateDTO } from "../models/dtos/create-matrix/EvaluationMatrixCategorySkillsCreateDTO";
import { EvaluationMatrixCreateDTO } from "../models/dtos/create-matrix/EvaluationMatrixCreateDTO";
import { EvaluationMatrixSkillRoleRelationDTO } from "../models/dtos/EvaluationMatrixSkillRoleRelationDTO";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { IconButton } from "../../../lib/components/buttons/IconButton";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { PagePermissions } from "../../../lib/infrastructure/authorization/PagePermissions";
import { Permission } from "../../../models/api/gate/Permission";
import { RoleSummary } from "../../roles/models/domain/RoleSummary";
import { ReactComponent as RolesIcon } from "../../../lib/assets/icons/roles.svg";
import { SelectRolesPopup } from "../popups/SelectRolesPopup";
import { SelectSkillsPopup } from "../popups/SelectSkillsPopup";
import { SkillDomainDTO } from "../../admin/skills/models/domain/SkillDomainDTO";
import { SkillsCategoryCard } from "../components/SkillsCategoryCard";
import { ReactComponent as SkillsIcon } from "../../../lib/assets/icons/skills.svg";
import { SkillsService } from "../../admin/skills/services/SkillsService";
import { TitledCardContainer } from "../../../lib/layouts/containers/card/TitledCardContainer";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../lib/utils/TrimString";
import { useFormControl } from "../../../lib/components/form/Form";
import { useGlobalBusinessLine } from "../../../lib/infrastructure/business-line/BusinessLineServices";
import { useGlobalLocation } from "../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";
import { validateWhiteSpaceStrings } from "../../../lib/validators/ValidateWhiteSpaceStrings";

export interface SkillLevelDTO {
  skillId: number;
  roleId: number;
  competenceLevel: string;
  isValid?: boolean;
}

export interface SkillsCategoryDTO {
  id: number;
  name: string;
  skills: SkillDomainDTO[];
}

const topPermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES];

const skillsSvc = new SkillsService();
const matrixSvc = new EvaluationMatricesService();

export function CreateEvaluationMatrixPage() {

  const { isLoadingPermissions, hasPermission } = useHasPermissions(topPermissions);
  const { id: businessLineID, matrixId } = useParams();
  const windowResize = useWindowResize();
  const openPopup = usePopup();
  const location = useLocation();
  const locationID = useGlobalLocation();
  const useSelectedBusinessLine = useGlobalBusinessLine();
  const createNotification = useCreateNotification();
  const navigate = useNavigate();


  const getSkillsByCategoryIdsCall = useServiceCallPro(skillsSvc.getMultipleCategoriesIncludeSkills);

  const createMatrixCall = useServiceCallPro(matrixSvc.createEvaluationMatrix);

  const [evaluationMatrixSkills, setEvaluationMatrixSkills] = useState<SkillsCategoryDTO[]>([]);
  const [evaluationMatrixRoles, setEvaluationMatrixRoles] = useState<RoleSummary[]>([]);
  const [evaluationMatrixChecklist, setEvaluationMatrixChecklist] = useState<string[]>([]);
  const [skillLevels, setSkillLevels] = useState<SkillLevelDTO[]>([]);



  const nameFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
  });

  const managerFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
  });



  const getSkillsByCategoryIds = useCallback((categoryIDs: number[]) => {
    if (!locationID) return;


    getSkillsByCategoryIdsCall.invoke("" + locationID, { categoryIds: categoryIDs })
      .then((response) => {

        const mappedResponse: SkillsCategoryDTO[] = response.categories.map((category) => ({
          id: category.id,
          name: category.name,
          skills: category.skills.map((skill) => ({
            id: skill.skillID,
            name: skill.name,
            isDisabled: skill.isDisabled
          }))
        }));

        setEvaluationMatrixSkills(mappedResponse);

      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });

  }, [locationID, getSkillsByCategoryIdsCall.invoke, setEvaluationMatrixSkills, openPopup]);




  const handleSelectRoles = useCallback((selectedRoles: RoleSummary[]) => {
    setEvaluationMatrixRoles(selectedRoles);
  }, [setEvaluationMatrixRoles]);


  const handleSelectSkills = useCallback((selectedCategories: SkillsCategoryDTO[]) => {
    setEvaluationMatrixSkills(selectedCategories);
    getSkillsByCategoryIds(selectedCategories.map(category => category.id));
  }, [setEvaluationMatrixSkills, getSkillsByCategoryIds]);


  const handleLevelInputChange = useCallback((skillLevels: SkillLevelDTO[]) => {
    setSkillLevels(skillLevels)
  }, [setSkillLevels])


  const handleChecklistChange = useCallback((checklist: string[]) => {
    setEvaluationMatrixChecklist(checklist)
  }, [setEvaluationMatrixChecklist])



  const handleCreateMatrix = useCallback(() => {

    if (!locationID || !businessLineID) return;

    if (evaluationMatrixRoles.length === 0) {
      openPopup(<ErrorPopup>{translate("BUSINESSLINES.MATRIX.ERRORS.NoRolesSelected")}</ErrorPopup>);
      return;
    }

    if (skillLevels.length === 0) {
      openPopup(<ErrorPopup>{translate("BUSINESSLINES.MATRIX.ERRORS.NoSkillsSelected")}</ErrorPopup>);
      return;
    }

    if (!AllValid(nameFormControl.validate(), managerFormControl.validate())) {
      return;
    }

    var skillLevelsClone = [...skillLevels];

    if (skillLevelsClone.filter(sl => !sl.isValid).length > 0) {
      openPopup(<ErrorPopup>{translate("BUSINESSLINES.MATRIX.ERRORS.InputFieldInvalid")}</ErrorPopup>);
      return;
    }

    const evaluationMatrixCategorySkills: EvaluationMatrixCategorySkillsCreateDTO[] = evaluationMatrixSkills.map(category => ({
      categoryId: category.id,
      skillRoles: category.skills.flatMap(skill =>
        skillLevelsClone
          .filter(sl => sl.skillId === skill.id)
          .map(sl => ({
            skillId: sl.skillId,
            roleId: sl.roleId,
            competenceLevel: sl.competenceLevel ? parseInt(sl.competenceLevel) || null : null,
          } as EvaluationMatrixSkillRoleRelationDTO)
          )
      )
    }));


    var requestDto: EvaluationMatrixCreateDTO = {
      managerEmail: trimString(managerFormControl.value || ""),
      description: trimString(nameFormControl.value || ""),

      categorySkills: evaluationMatrixCategorySkills,

      checklistItems: evaluationMatrixChecklist.map(item => ({
        description: item
      }))
    };

    createMatrixCall.invoke("" + locationID, businessLineID, requestDto)
      .then((response) => {
        createNotification(
          <Notification
            type="success"
            title={translate("COMMON.SYSTEMPOPUPS.Success")}
            text={translate("BUSINESSLINES.MATRIX.NOTIFICATIONS.CreateMatrixSuccess")}
          />
        );
        const currentPath = location.pathname;
        const newPath = currentPath.replace("/create", `/${response.id}/versions/${response.matrixVersionId}/details`);
        navigate(newPath);
      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      })

  }, [locationID, businessLineID, evaluationMatrixRoles.length, skillLevels,
    nameFormControl, managerFormControl, evaluationMatrixSkills, evaluationMatrixChecklist,
    createMatrixCall, openPopup, createNotification, location.pathname, navigate]);



  const renderCreateEvaluationMatrixBtns = useMemo(() => {
    if (windowResize > 768)
      return (<>
        {evaluationMatrixSkills && evaluationMatrixSkills.length ?
          <Button
            text={translate("BUSINESSLINES.MATRIX.SelectRoles")}
            type="secondary"
            onClick={() => locationID && businessLineID ? openPopup(<SelectRolesPopup locationId={`${locationID}`} businessLineId={"" + businessLineID} selectedRoles={evaluationMatrixRoles} onCompletedOperations={handleSelectRoles} />) : null}
          /> : null}
        <Button
          text={translate("BUSINESSLINES.MATRIX.SelectSkills")}
          type="secondary"
          onClick={() => locationID && businessLineID ? openPopup(<SelectSkillsPopup locationId={`${locationID}`} businessLineId={"" + businessLineID} selectedCategories={evaluationMatrixSkills} onCompletedOperations={handleSelectSkills} />) : null}
        />
        <Button
          text={translate("BUSINESSLINES.MATRIX.CreateMatrix")}
          type="primary"
          onClick={() => locationID && businessLineID ? handleCreateMatrix() : null}
        />
      </>
      );
    else
      return (<>
        {evaluationMatrixSkills && evaluationMatrixSkills.length ?
          <IconButton
            icon={<RolesIcon />}
            type="secondary"
            onClick={() => locationID && businessLineID ? openPopup(<SelectRolesPopup locationId={`${locationID}`} businessLineId={"" + businessLineID} selectedRoles={evaluationMatrixRoles} onCompletedOperations={handleSelectRoles} />) : null}
          /> : null}
        <IconButton
          icon={<SkillsIcon />}
          type="secondary"
          onClick={() => locationID && businessLineID ? openPopup(<SelectSkillsPopup locationId={`${locationID}`} businessLineId={"" + businessLineID} selectedCategories={evaluationMatrixSkills} onCompletedOperations={handleSelectSkills} />) : null}
        />
        <IconButton
          icon={<AddIcon />}
          type="primary"
          onClick={() => locationID && businessLineID ? handleCreateMatrix() : null}
        />
      </>
      );
  }, [windowResize, locationID, businessLineID, openPopup, evaluationMatrixSkills, handleSelectRoles, handleSelectSkills, handleCreateMatrix, evaluationMatrixRoles]);


  const renderSkillsCategoriesCards = useMemo(() => {
    if (evaluationMatrixSkills && evaluationMatrixSkills.length)
      return <SkillsCategoryCard
        isEditionModeEnabled
        locationId={"" + locationID}
        businessLineId={"" + businessLineID}
        matrixId={matrixId}
        roles={evaluationMatrixRoles}
        skillsCategories={evaluationMatrixSkills}
        skillsLevels={skillLevels}
        handleLevelInputChange={handleLevelInputChange}
        handleManageSkills={(selectedSkills: SkillsCategoryDTO[]) => setEvaluationMatrixSkills(selectedSkills)}
      />
  }, [businessLineID, evaluationMatrixRoles, evaluationMatrixSkills, handleLevelInputChange, locationID, matrixId, skillLevels])


  return (
    <PagePermissions permissions={topPermissions} >
      <PageLayout
        tabTitle={translate("BUSINESSLINES.MATRIX.NewMatrix")}
        pageTitle={translate("BUSINESSLINES.MATRIX.NewMatrix")}
        className="create-evaluation-matrix"
        showBreadcrumb
        actions={useSelectedBusinessLine && !useSelectedBusinessLine.isDisabled && renderCreateEvaluationMatrixBtns}
      >
        {createMatrixCall.isLoading && <FullScreenLoader />}
        <>
          <TitledCardContainer className="basic-info-card" title={translate("BUSINESSLINES.MATRIX.INFOANDFORM.BasicInfo")}>
            <FormSection isInputGap>
              <FormFieldTextInput
                formControl={nameFormControl}
                label={translate("BUSINESSLINES.MATRIX.INFOANDFORM.Area")}
                placeholder={translate("BUSINESSLINES.MATRIX.INFOANDFORM.AreaPlaceholder")}
                alternativeStyle
              />
              <FormFieldTextInput
                formControl={managerFormControl}
                label={translate("BUSINESSLINES.MATRIX.INFOANDFORM.Manager")}
                placeholder={translate("BUSINESSLINES.MATRIX.INFOANDFORM.ManagerPlaceholder")}
                alternativeStyle
              />
            </FormSection>
          </TitledCardContainer>
          {renderSkillsCategoriesCards}
          <ChecklistCard
            isEditionModeEnabled
            locationId={"" + locationID}
            businessLineId={"" + businessLineID}
            matrixId={matrixId}
            onChange={handleChecklistChange} />
        </>
      </PageLayout>
    </PagePermissions>
  );
}