import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { EmployeeBonusDetailsDTO } from "../models/dtos/EmployeeBonusDetailsDTO";

const employeeBonusDetailsMock: EmployeeBonusDetailsDTO = {
    evaluationPeriodStartDate: "2023-01-01T00:00:00+00:00",
    evaluationPeriodEndDate: "2023-12-31T00:00:00+00:00",
    evaluationPeriodName: "2023",
    evaluationPeriodId: 10,
    id: 1,
    companyCriterias: [
        {
            name: "Fator 01",
            achievementPercentage: 78,
        },
        {
            name: "Fator 02",
            achievementPercentage: 40,
        },
    ],
    employeeCriterias: [
        {
            factorName: "Fator 01",
            evaluationValue: 80,
        },
        {
            factorName: "Fator 02",
            evaluationValue: 70,
        },
    ],
    employeeScoreTotalPercentage: 80,
    finalScoreWithCutPercentage: 78,
    feedback: "Lorem Ipsum"
};

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class EmployeeBonusService {

    getBonusDetailsById(locationId: string, employeeId: string, periodId: string): Promise<EmployeeBonusDetailsDTO> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(employeeBonusDetailsMock);
            }, 1000);
        });

        // return HttpClient.sessionRequest<EmployeeBonusDetailsDTO>({
        //     method: "GET",
        //     url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/evaluations/${periodId}`),
        // })
        //     .then((res) => {
        //         if (!res.data) return null;
        //         return res.data;
        //     })
        //     .catch((error) => {
        //         throw error;
        //     });
    }

}
