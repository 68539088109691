import { useCallback, useEffect, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { ConditionalRender } from "../../../../../lib/functional/ConditionalRender";
import { DateTime } from "luxon";
import { EmployeeCareerLadderService } from "../../../services/EmployeeCareerLadderService";
import { EmployeeCareerLadderStepCreateDTO } from "../../../models/dtos/EmployeeCareerLadderStepCreateDTO";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { ExceptionType } from "../../../../../lib/infrastructure/http/ExceptionType";
import { FormFieldDatePicker } from "../../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldSelectSingle } from "../../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormSection } from "../../../../../lib/components/form/section/FormSection";
import { MessageBar } from "../../../../../lib/components/message-bar/MessageBar";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { RoleSummary } from "../../../../roles/models/domain/RoleSummary";
import { RolesService } from "../../../../roles/services/RolesService";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { ValidationErrorMessage } from "../../../../../lib/infrastructure/http/APIError";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../../lib/hooks/useServiceCall";
import { validateDateTime } from "../../../../../lib/validators/ValidateDateTime";

interface IAddEmployeeCareerLadderNodePopupProps {
    locationId: string | undefined;
    businessLineId: string | undefined;
    employeeId: string | undefined;
    onCompletedOperations: () => void;
}

const labelRoleSelector = (item: RoleSummary) => item.description;
const idRoleSelector = (item: RoleSummary) => item.roleId;

var rolesSvc = new RolesService();
var employeeCareerLadderSvc = new EmployeeCareerLadderService();

export function AddEmployeeCareerLadderNodePopup(props: IAddEmployeeCareerLadderNodePopupProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();

    const getActiveRolesCall = useServiceCallPro(rolesSvc.getRoles);
    const addEmployeeNodeCall = useServiceCallPro(employeeCareerLadderSvc.addEmployeeNode);

    const [roles, setRoles] = useState<RoleSummary[]>([]);
    const [validationAlert, setValidationAlert] = useState<ValidationErrorMessage>({ showAlert: false });

    const rolesFormControl = useFormControl<RoleSummary>({});

    const startDateFormControl = useFormControl<DateTime>({
        validators: [validateDateTime()],
        enableAutoValidate: false
    });

    const endDateFormControl = useFormControl<DateTime>({
        validators: [validateDateTime()],
        enableAutoValidate: false
    });


    /****************************
    * DATA REQUESTS
    *****************************/

    const getActiveRoles = useCallback(() => {
        if (!props.locationId || !props.businessLineId) return;

        getActiveRolesCall.invoke(props.locationId, "" + props.businessLineId, { filterByIsActive: true }, undefined)
            .then((data) => {
                setRoles(data?.roles || []);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationId, props.businessLineId, getActiveRolesCall, openPopup]);


    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/

    useEffect(() => {
        getActiveRoles();
    }, [])


    /****************************
    * USER ACTIONS
    *****************************/

    const handleSelectClicked = useCallback(() => {
        if (!props.locationId) return;
        if (!AllValid(rolesFormControl.validate(), startDateFormControl.validate(), endDateFormControl.validate())) return;

        if (!rolesFormControl.value) return;
        let request: EmployeeCareerLadderStepCreateDTO = {
            roleId: rolesFormControl.value?.roleId,
            startDate: startDateFormControl.value?.toISO() || null,
            endDate: endDateFormControl.value?.toISO() || null,
        };


        const handleSuccess = () => {
            closePopup();
            props.onCompletedOperations();
            createNotification(
                <Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("BUSINESSLINES.CAREER.POPUP.AddRoleSuccess")}
                />
            );
        };


        const handleError = (error: any) => {
            if (!error) return;

            const errorMessage = error?.response?.data?.message || translate("ERRORS.UnknownError");
            const enumCode = error?.response?.data?.enumCode;

            if (enumCode === ExceptionType.ERROR_VALIDATION_NAME_ALREADY_EXISTS) {
                setValidationAlert({
                    showAlert: true,
                    message: errorMessage,
                });
            } else {
                openPopup(<ErrorPopup>{errorMessage}</ErrorPopup>);
            }
        };


        addEmployeeNodeCall
            .invoke(props.locationId, request)
            .then(handleSuccess)
            .catch(handleError);
    }, [closePopup, rolesFormControl, createNotification, openPopup, props, addEmployeeNodeCall.invoke]);


    return <PopupContainer className="popup-add-employee-career-ladder-role">
        <PopupHeader title={translate("EMPLOYEES.CAREER.POPUPS.AddRole")} />
        <PopupContent isLoading={getActiveRolesCall.isLoading}>
            <FormSection isInputGap childrenFlexDirColumn>
                <FormFieldSelectSingle
                    formControl={rolesFormControl}
                    label={translate("EMPLOYEES.CAREER.INFOANDFORM.Roles")}
                    placeholder={translate("EMPLOYEES.CAREER.INFOANDFORM.RolesPlaceholder")}
                    options={roles}
                    labelSelector={labelRoleSelector}
                    idSelector={idRoleSelector}
                    maxHeightOptions={"16rem"}
                />
                <FormFieldDatePicker
                    formControl={startDateFormControl}
                    label={translate("EMPLOYEES.CAREER.INFOANDFORM.StartDate")}
                    placeholder={translate("EMPLOYEES.CAREER.INFOANDFORM.StartDatePlaceholder")}
                    maxDate={endDateFormControl.value}
                />
                <FormFieldDatePicker
                    formControl={endDateFormControl}
                    label={translate("EMPLOYEES.CAREER.INFOANDFORM.EndDate")}
                    placeholder={translate("EMPLOYEES.CAREER.INFOANDFORM.EndDatePlaceholder")}
                    minDate={startDateFormControl.value}
                />
            </FormSection>
            <ConditionalRender if={validationAlert.showAlert}>
                <Spacer mode="vertical" px="10" />
                <MessageBar type="error" text={"" + validationAlert.message} />
            </ConditionalRender>
            <Spacer mode={"vertical"} px="30" />
            <PopupActionButtons
                buttons={[
                    {
                        text: translate("COMMON.Cancel"),
                        type: "tertiary",
                        onClick: () => closePopup(),
                    },
                    {
                        text: translate("COMMON.Add"),
                        type: "primary",
                        onClick: handleSelectClicked,
                    },
                ]}
            />
        </PopupContent>
    </PopupContainer>;
}