import "./SkillsCategoriesPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as AddIcon } from "../../../../lib/assets/icons/add.svg";
import { AddSkillsCategoryPopup } from "./popups/AddSkillsCategoryPopup";
import { Button } from "../../../../lib/components/buttons/Button";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { ReactComponent as EditIcon } from "../../../../lib/assets/icons/editar.svg";
import { EditSkillsCategoryPopup } from "./popups/EditSkillsCategoryPopup";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { Permission } from "../../../../models/api/gate/Permission";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { SkillCategoriesQueryOptions } from "../models/dtos/SkillsCategoriesQueryOptions";
import { SkillsCategory } from "../models/domain/SkillsCategory";
import { SkillsService } from "../services/SkillsService";
import { Toggle } from "../../../../lib/components/form/toggle/Toggle";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useHasPermissions } from "../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { ImportPopup } from "../../../../lib/components/popup/ImportPopup";
import { ConfigurationProvider } from "../../../../lib/infrastructure/configuration/ConfigurationProvider";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";

var skillsSvc = new SkillsService();

const permissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN];


export function SkillsCategoriesPage() {
  const navigate = useNavigate();
  const windowResize = useWindowResize();
  const openPopup = usePopup();
  const closePopup = useClosePopup();
  const { id: locationID } = useParams();
  const createNotification = useCreateNotification();

  const { isLoadingPermissions, hasPermission } = useHasPermissions(permissions);

  const [skillsCategories, setSkillsCategories] = useState<SkillsCategory[]>();
  const [totalSkillsCategories, setTotalSkillsCategories] = useState<number>();

  const getSkillsCategoriesCall = useServiceCallPro(skillsSvc.getSkillCategories);
  const updateSkillsCategoryStateCall = useServiceCallPro(skillsSvc.updateSkillsCategoryState);
  const uploadXLSXFileCall = useServiceCallPro(skillsSvc.uploadSkillCategoriesFile);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentpage, setCurrentPage] = useState(0);

  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [searchWord, setSearchWord] = useState<string>();


  var searchboxController = useFormControl<string>({
    isDisabled: false,
    enableAutoValidate: false,
  });


  /****************************
 * DATA REQUESTS
 *****************************/

  const getSkillsCategories = useCallback(() => {
    if (!locationID) return;

    var queryOptions: SkillCategoriesQueryOptions = {
      page: currentpage,
      pageLength: itemsPerPage,
      searchWord: searchWord,
    };

    getSkillsCategoriesCall.invoke("" + locationID, queryOptions)
      .then((data) => {
        if (data) {
          setSkillsCategories(data.skillsCategories);
          setTotalSkillsCategories(data.totalItems);
        }
      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [locationID, setSkillsCategories, getSkillsCategoriesCall.invoke,
    openPopup, setTotalSkillsCategories, currentpage, itemsPerPage, searchWord]);





  const handleUpdateSkillCategoryStateClicked = useCallback((skillCategoryID: number, isToDisable: boolean) => {

    if (skillCategoryID && locationID) {

      updateSkillsCategoryStateCall.invoke("" + locationID, skillCategoryID, isToDisable)
        .then((_) => {
          getSkillsCategories();
          createNotification(<Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")} text={isToDisable ? translate("GLOBALADMIN.LOCATIONS.SKILLS.POPUP.DeactivateSkillsCategorySuccess") : translate("GLOBALADMIN.LOCATIONS.SKILLS.POPUP.ActivateSkillsCategorySuccess")}></Notification>);
        })
        .catch((error) => {
          if (!error) return;
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }
  }, [locationID, updateSkillsCategoryStateCall, getSkillsCategories, createNotification, openPopup]);








  const handleOnPageAndItemsChanged = useCallback(
    (items: number, currentPage: number) => {
      setItemsPerPage(items);
      setCurrentPage(currentPage);
    },
    [setItemsPerPage, setCurrentPage]
  );


  /****************************
  * DATA MANIPULATION EFFECTS
  *****************************/

  useEffect(() => {
    getSkillsCategories();
  }, [currentpage, itemsPerPage, searchWord]);


  useEffect(() => {
    if (timer)
      clearTimeout(timer);

    var responseTimeOut = setTimeout(() => {
      if (timer) {
        setCurrentPage(0);
        setSearchWord(searchboxController.value);
      }
    }, 1000);

    setTimer(responseTimeOut);
  }, [searchboxController.value]);






  const columns: ColumnDefinition<SkillsCategory>[] = useMemo(
    () => [
      {
        cellRenderProp: (v) => v.categoryName,
        headerRender: translate("GLOBALADMIN.LOCATIONS.SKILLS.INFOANDFORM.Category"),
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => v.numberOfSkills,
        headerRender: translate("GLOBALADMIN.LOCATIONS.SKILLS.INFOANDFORM.NumOfSkills"),
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => (
          <div className="toggle-div">
            <Toggle value={!v.isDisabled} />
            <div
              className="toggle-div-overlay"
              onClick={(ev) => {
                ev.stopPropagation();
                if (!v.isDisabled) {
                  openPopup(
                    <WarningPopup
                      className="disable-skills-category-popup"
                      onDismissClicked={() => closePopup()}
                      onConfirmClicked={() => handleUpdateSkillCategoryStateClicked(v.id, true)}
                      dismissText={translate("COMMON.Cancel")}
                      confirmText={translate("GLOBALADMIN.LOCATIONS.SKILLS.POPUP.Deactivate")}
                    >
                      {translate("GLOBALADMIN.LOCATIONS.SKILLS.POPUP.DeactivateSkillsCategoryConfirmText")}
                    </WarningPopup>
                  );
                }
                else {
                  if (v.id) {
                    openPopup(
                      <WarningPopup
                        onDismissClicked={() => closePopup()}
                        onConfirmClicked={() => handleUpdateSkillCategoryStateClicked(v.id, false)}
                        dismissText={translate("COMMON.Cancel")}
                        confirmText={translate("GLOBALADMIN.LOCATIONS.SKILLS.POPUP.Activate")}
                      >
                        {translate("GLOBALADMIN.LOCATIONS.SKILLS.POPUP.ActivateSkillsCategoryConfirmText")}
                      </WarningPopup>
                    );
                  }
                }
              }}
            />
          </div>
        ),
        headerRender: <></>,
        columnKey: "status",
        width: "5%",
        isSortable: false,
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => {
          if (locationID && !isLoadingPermissions && hasPermission)
            return (
              <ScalableIconButton
                icon={<EditIcon />}
                onClick={() =>
                  openPopup(
                    <EditSkillsCategoryPopup
                      skillCategoryID={v.id}
                      locationID={locationID}
                      onCompleted={() => getSkillsCategories()}
                    />
                  )
                }
                size={24}
              />)
          return null;
        },
        columnKey: "edit",
        width: "24px",
        isMobileHeaderIcon: true,
      },
    ], [openPopup, closePopup, isLoadingPermissions, hasPermission, getSkillsCategories, locationID, handleUpdateSkillCategoryStateClicked]);



  const handleClickAddBtn = useCallback(() => {
    if (locationID)
      openPopup(<AddSkillsCategoryPopup onChangesMade={() => getSkillsCategories()} locationId={locationID} />);
  }, [getSkillsCategories, locationID, openPopup])



  const handleFileImport = useCallback((file: File) => {
    if (!locationID) return;

    uploadXLSXFileCall.invoke(locationID, file)
      .then((_) => {
        getSkillsCategories();
        createNotification(
          <Notification
            type="success"
            title={translate("COMMON.SYSTEMPOPUPS.Success")}
            text={translate("GLOBALADMIN.LOCATIONS.SKILLS.POPUP.ImportXLSXSuccess")}
          />
        );
      }).catch(error => {
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });


  }, [locationID, uploadXLSXFileCall, getSkillsCategories, createNotification, openPopup]);

  
  /****************************
  * USER ACTIONS
  *****************************/

  const renderAddSkillsCategoryBtn = useMemo(() => {

    if (!isLoadingPermissions && hasPermission) {
      if (windowResize > 768)
        return (<div className="header-btns">
          <Button
            text={translate("COMMON.Import")}
            type="secondary"
            onClick={() => openPopup(<ImportPopup onClickDownloadTemplateBtn={() =>

              window.open(ConfigurationProvider.getConfiguration().App.BackendUrl + "/templates/VLMT_Skill_Categories_Template.xlsx", "_blank")}

              onClickImportBtn={handleFileImport} />)}
            className="import-skill-categories"
          />
          <Button
            text={translate("COMMON.Add")}
            type="primary"
            onClick={handleClickAddBtn}
          />
        </div>
        );
      else
        return (
          <IconButton
            icon={<AddIcon />}
            type="primary"
            onClick={handleClickAddBtn}
          />
        );
    }
    //}
  }, [isLoadingPermissions, hasPermission, windowResize, handleClickAddBtn, openPopup, handleFileImport]);





  /****************************
   * CSS & HTML
   *****************************/

  return <PageLayout
    tabTitle={translate("GLOBALADMIN.LOCATIONS.SKILLS.SkillsCategories")}
    showBreadcrumb
    pageTitle={translate("GLOBALADMIN.LOCATIONS.SKILLS.SkillsCategories")}
    className="skills-categories-page"
    actions={renderAddSkillsCategoryBtn}
  >

    {updateSkillsCategoryStateCall.isLoading && <FullScreenLoader />}

    <ResponsiveDataTable
      columnDefinitions={columns}
      items={skillsCategories || []}
      totalitems={totalSkillsCategories || 0}
      paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 15, 20] }}
      currentpage={currentpage}
      isLoading={getSkillsCategoriesCall.isLoading}
      onPageAndItemsChanged={handleOnPageAndItemsChanged}
      onClickRow={(c) => navigate(`${c.id}`)}
    />
  </PageLayout>;
}