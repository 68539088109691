
export function getEmployeeFollowUpItemStatusCss(statusId: number) {
    switch (statusId) {
        case 1:
            return "status-light-grey";
        case 2:
            return "status-yellow";
        case 3:
            return "status-green";
        case 4:
            return "status-red";
        default:
            return undefined;
    }
}
