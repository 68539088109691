import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { EmployeeEvaluationPeriodsResponseDTO } from "../models/dtos/EmployeeEvaluationPeriodsResponseDTO";
import { EvaluationDetailsDTO } from "../../evaluations/models/EvaluationDetailsDTO";
import { EvaluationFollowUpItemStatus } from "../../evaluations/models/EvaluationFollowUpItemStatus";
import { EvaluationFollowUpItemsResponseDTO } from "../../evaluations/models/EvaluationFollowUpItemsResponseDTO";
import { EvaluationStatus } from "../../evaluations/models/EvaluationStatus";
import { QueryOptions } from "../../../models/api/QueryOptions";

const employeeEvaluationPeriods: EmployeeEvaluationPeriodsResponseDTO = {
    items: [
        {
            id: 10,
            name: "2023",
            employeeId: "1",
            employeeRole: "Operador Logística",
            evaluationTotal: 10,
        }
    ],
    totalItems: 1,
};

const employeeEvaluationDetailsMock: EvaluationDetailsDTO = {
    evaluationId: 10,
    evaluationStatus: EvaluationStatus.READY_TO_EVALUATE,
    employee: {
        userId: "1",
        userEmployeeID: "1",
        userEmail: "john.doe@doamin.com",
        userDisplayName: "John Doe"
    },
    employeeRoleId: 1,
    employeeRoleDescription: "Operador Logística",
    evaluationTotal: 0,
    skillsCategories: [
        {
            id: 1,
            name: "Behavioral Skills",
            skills: [
                {
                    id: 1,
                    name: "Adapting and Responding to Change",
                    value: "",
                    refValue: "1",
                    isValid: true
                },
                {
                    id: 2,
                    name: "Analysing",
                    value: "",
                    refValue: "1",
                    isValid: true
                },
            ]
        },
        {
            id: 2,
            name: "Needling",
            skills: [
                {
                    id: 3,
                    name: "Cabine Agulhas",
                    value: "",
                    refValue: "1",
                    isValid: true
                },
                {
                    id: 4,
                    name: "Needling Di 4.5",
                    value: "",
                    refValue: "1",
                    isValid: true
                },
            ]
        },
    ],
    checklistItems: [
        {
            id: 2,
            description: "Desempenho: Média da avaliação do bónus > 4 nos últimos 12 meses",
            isChecked: false
        },
        {
            id: 1,
            description: "Cumprimento do regulamento interno: Últimos 3 anos",
            isChecked: true
        },
        {
            id: 3,
            description: "Absentismo: Máx X faltas injustificadas nos últimos 3 anos",
            isChecked: false
        },
    ],
    feedbackManagerEmployee: "Lorem Ipsum",
    feedbackEmployeeManager: "Lorem Ipsum",
    canCalibrate: true,
    canReject: true,
    nextRoleID: undefined
}

const employeeFollowUpItemsMock: EvaluationFollowUpItemsResponseDTO = {
    items: [
        // {
        //     name: "Item A",
        //     description: "Lorem Ipsum",
        //     startDate: "2024-01-01T00:00:00+00:00",
        //     conclusionDate: "2024-01-01T00:00:00+00:00",
        //     status: EvaluationFollowUpItemStatus.REGISTERED,
        // },
        {
            name: "Item B",
            description: "Lorem Ipsum",
            startDate: "2024-01-01T00:00:00+00:00",
            conclusionDate: "2024-01-01T00:00:00+00:00",
            status: EvaluationFollowUpItemStatus.INPROGRESS,
        },
    ]
};

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class EmployeeEvaluationsService {

    getEmployeeEvaluationPeriods(locationId: string, employeeId: string, query: QueryOptions, abortSignal: AbortSignal | undefined): Promise<EmployeeEvaluationPeriodsResponseDTO | null> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    items: employeeEvaluationPeriods.items,
                    totalItems: employeeEvaluationPeriods.totalItems
                });
            }, 1000);
        });
    }

    getEmployeeEvaluationDetailsById(locationId: string, employeeId: string, evaluationId: string): Promise<EvaluationDetailsDTO> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(employeeEvaluationDetailsMock);
            }, 1000);
        });
    }

    getEmployeeEvaluationFollowUpItems(locationId: string, employeeId: string, evaluationId: string): Promise<EvaluationFollowUpItemsResponseDTO> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(employeeFollowUpItemsMock);
            }, 1000);
        });
    }

}
