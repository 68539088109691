import { Outlet, Route, Routes, } from "react-router-dom";

import { EmployeesContainer } from "../common/layouts/content/EmployeesContainer";
import { EmployeesPage } from "../features/employees/pages/EmployeesPage";
import { ModuleDefinition } from "../lib/infrastructure/modules/ModuleDefinition";
import { Permission } from "../models/api/gate/Permission";
import { RouteGuard } from "../lib/infrastructure/authorization/RouteGuard";
import { translate } from "../lib/infrastructure/i18n/InternationalizationService";
import { useEffect } from "react";
import { useRegisterBreadcrumbRoutes } from "../lib/infrastructure/navigation/hooks/useBreadcrumb";

const moduleDefinition: ModuleDefinition = {
    basePath: "/me",
    routes: [
        {
            name: () => translate("EMPLOYEES.Employees"),
            pathPattern: "/",
        },
        {
            name: () => "##EMPLOYEE##",
            pathPattern: "/:id",
        },
    ],
};


export const humanResourcePermissions = [
    Permission.LOCATION_ADMIN,
    Permission.HUMAN_RESOURCES
];

export const adminPermissions = [
    Permission.GLOBAL_ADMIN,
    Permission.LOCATION_ADMIN,
    Permission.HUMAN_RESOURCES
];

export const basePermissions = [
    Permission.GLOBAL_ADMIN,
    Permission.LOCATION_ADMIN,
    Permission.HUMAN_RESOURCES,
    Permission.LOCATION_MANAGER
];


export function CollaboratorModule() {

    const registerBreadcrumbRoutes = useRegisterBreadcrumbRoutes();

    useEffect(() => {
        registerBreadcrumbRoutes(moduleDefinition);
    }, []);

    return (
        <RouteGuard permissions={basePermissions} >
            <Routes>
                <Route path="/" element={<EmployeesPage />} />
                <Route
                    path="/:id"
                    element={
                        <EmployeesContainer>
                            <Outlet />
                        </EmployeesContainer>}
                >
                    {/* <Route path="evaluations" element={<EmployeesEvaluationsPage />} /> */}
                </Route>
            </Routes>
        </RouteGuard >
    );
}
