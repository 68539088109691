import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { EmployeeDetailsDTO } from "../models/dtos/EmployeeDetailsDTO";
import { EmployeesResponseDTO } from "../models/dtos/EmployeesResponseDTO";
import { QueryOptions } from "../../../models/api/QueryOptions";

const employeesMock: EmployeesResponseDTO = {
    items: [
        {
            employeeInfo: {
                userId: "1",
                userEmployeeID: "45230",
                userEmail: "john.doe@doamin.com",
                userDisplayName: "John Doe"
            },
            roleId: 1,
            roleDescription: "Operador Logística",
            evaluationTotal: 0,
        },
        {
            employeeInfo: {
                userId: "2",
                userEmployeeID: "53100",
                userEmail: "john.doe2@doamin.com",
                userDisplayName: "John Doe 2"
            },
            roleId: 1,
            roleDescription: "Operador Logística",
            evaluationTotal: 0,
        },
    ],
    totalItems: 2
}

const employeeSummaryMock: EmployeeDetailsDTO = {
    employeeInfo: {
        userId: "1",
        userEmployeeID: "45230",
        userEmail: "john.doe@doamin.com",
        userDisplayName: "John Doe"
    },
    roleId: 1,
    roleDescription: "Operador Logística",
    evaluationTotal: 0,
}

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class EmployeesService {

    getEmployees(locationId: string, query: QueryOptions, abortSignal: AbortSignal | undefined): Promise<EmployeesResponseDTO> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    items: employeesMock.items,
                    totalItems: employeesMock.totalItems
                });
            }, 1000);
        });
    }

    getEmployeeDetails(locationId: string, employeeId: string): Promise<EmployeeDetailsDTO> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(employeeSummaryMock);
            }, 1000);
        });
    }

}
