import "./CertificatesEvaluationCard.css";

import { useCallback, useEffect, useMemo, useState } from "react";

import { ReactComponent as CheckIcon } from "../../../../lib/assets/icons/check.svg";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { DateTimeHelper } from "../../../../lib/helpers/datetime/DateTime";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { EvaluationCertificateDTO } from "../../models/EvaluationCertificateDTO";
import { EvaluationsService } from "../../services/EvaluationsService";
import { ReactComponent as RejectedIcon } from "../../../../lib/assets/icons/reject-dark.svg";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { TitledCardContainer } from "../../../../lib/layouts/containers/card/TitledCardContainer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";

interface ICertificatesEvaluationCardProps {
    locationId: string;
    businessLineId: string;
    periodId: string;
    matrixId: string;
    evaluationId: string;
}

var svc = new EvaluationsService();

export function CertificatesEvaluationCard(props: ICertificatesEvaluationCardProps) {
    const openPopup = usePopup();

    const [certificates, setCertificates] = useState<EvaluationCertificateDTO[]>([]);

    const getEvaluationCertificatesCall = useServiceCallPro(svc.getEvaluationCertificates);


    /****************************
    * DATA REQUESTS
    *****************************/
    const getEvaluationCertificates = useCallback(() => {

        if (!props.locationId || !props.businessLineId || !props.periodId || !props.matrixId || !props.evaluationId) return;

        getEvaluationCertificatesCall.invoke(props.locationId, props.businessLineId, props.periodId, props.matrixId, props.evaluationId)
            .then((res) => {
                if (!res) return;
                setCertificates(res.items);
            })

            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [props.locationId, props.businessLineId, props.periodId, props.matrixId, props.evaluationId, getEvaluationCertificatesCall.invoke, openPopup]);


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/
    useEffect(() => {
        getEvaluationCertificates();
    }, [])


    /****************************
     * CSS & HTML
     *****************************/
    const columns: ColumnDefinition<EvaluationCertificateDTO>[] = useMemo(() => [
        {
            cellRenderProp: (item) => item.certificateName,
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.CertificateName"),
            isMobilePrimaryCell: true,
        },
        {
            cellRenderProp: (item) => item.date ? DateTimeHelper.formatDateTimeLocale(item.date) : "-",
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.CertificateDate"),
            width: "120px",
        },
        {
            cellRenderProp: (item) => item.isApproved ? <CheckIcon /> : <RejectedIcon />,
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.CertificateApproval"),
            width: "100px",
            isMobilePrimaryCell: true,
        },
    ], []);


    return <TitledCardContainer className="certificates-evaluation-card" title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Certificates")}>
        <ResponsiveDataTable
            columnDefinitions={columns}
            items={certificates}
            totalitems={certificates.length}
            isLoading={getEvaluationCertificatesCall.isLoading}
        />
    </TitledCardContainer>;
}