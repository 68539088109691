import "./EmployeeCertificationsPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { AddEmployeeCertificationPopup } from "./popups/AddEmployeeCertificationPopup";
import { ReactComponent as AddIcon } from "../../../../lib/assets/icons/add.svg";
import { ReactComponent as AttachmentIcon } from "../../../../lib/assets/icons/anexo.svg";
import { Button } from "../../../../lib/components/buttons/Button";
import { ReactComponent as CheckIcon } from "../../../../lib/assets/icons/check.svg";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { ConfigurationProvider } from "../../../../lib/infrastructure/configuration/ConfigurationProvider";
import { DateTimeHelper } from "../../../../lib/helpers/datetime/DateTime";
import { ReactComponent as DeleteIcon } from "../../../../lib/assets/icons/remover.svg";
import { EditEmployeeCertificationPopup } from "./popups/EditEmployeeCertificationPopup";
import { ReactComponent as EditIcon } from "../../../../lib/assets/icons/editar.svg";
import { EmployeeCertificationsService } from "../../services/EmployeeCertificationsService";
import { EmployeeCertificationsSummaryDTO } from "../../models/dtos/EmployeeCertificationsSummaryDTO";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { ImportPopup } from "../../../../lib/components/popup/ImportPopup";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { Permission } from "../../../../models/api/gate/Permission";
import { QueryOptions } from "../../../../models/api/QueryOptions";
import { ReactComponent as RejectIcon } from "../../../../lib/assets/icons/reject-dark.svg";
import { ResponsiveButton } from "../../../../lib/components/buttons/ResponsiveButton";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { ReactComponent as SearchIcon } from "../../../../lib/assets/icons/search-icon.svg";
import { Tooltip } from "../../../../lib/components/tooltip/Tooltip";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import axios from "axios";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useParams } from "react-router-dom";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";

var svc = new EmployeeCertificationsService();

export interface IOrderInfo {
    direction: "ASC" | "DESC" | "NONE";
    columnKey: string;
}

const topPermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES];

export function EmployeeCertificationsPage() {
    const { isLoadingPermissions, hasPermission } = useHasPermissions(topPermissions);
    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();
    const locationID = useGlobalLocation();
    const locationIDAsString = useMemo(() => "" + locationID, [locationID])
    const { employeeId } = useParams();

    const [certifications, setCertifications] = useState<EmployeeCertificationsSummaryDTO[]>();
    const [totalItems, setTotalItems] = useState<number>();

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentpage, setCurrentPage] = useState(0);

    const [timer, setTimer] = useState<NodeJS.Timeout>();
    const [searchWord, setSearchWord] = useState<string>();

    const getCertificationsCall = useServiceCallPro(svc.getCertifications);
    const deleteCertificationCall = useServiceCallPro(svc.deleteCertification);
    const uploadCertificationsFileCall = useServiceCallPro(svc.uploadCertificationsFile);

    var searchboxController = useFormControl<string>({
        isDisabled: false,
        enableAutoValidate: false,
    });


    /****************************
    * DATA REQUESTS
    *****************************/

    const getCertifications = useCallback((controller: AbortController | undefined) => {
        if (!locationIDAsString || !employeeId) return;

        var queryOpts: QueryOptions = {
            searchWord: searchWord,
            page: currentpage,
            pageLength: itemsPerPage
        };

        getCertificationsCall.invoke(locationIDAsString, employeeId, queryOpts, controller?.signal)
            .then((data) => {
                if (data) {
                    setCertifications(data.items);
                    setTotalItems(data.totalItems)
                }
            })
            .catch((error) => {
                if (!error || axios.isCancel(error)) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, employeeId, getCertificationsCall.invoke, openPopup, currentpage, itemsPerPage, searchWord]);


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        const controller = new AbortController();
        getCertifications(controller);
        return () => { controller.abort() };
    }, [currentpage, itemsPerPage, searchWord]);

    useEffect(() => {
        if (timer) { clearTimeout(timer); }

        var responseTimeOut = setTimeout(() => {
            if (timer) { setSearchWord(searchboxController.value); }
            setCurrentPage(0);
        }, 1000);

        setTimer(responseTimeOut);
    }, [searchboxController.value]);


    /****************************
     * USER ACTIONS
     *****************************/

    const handleOnPageAndItemsChanged = useCallback((items: number, currentPage: number) => {
        setItemsPerPage(items);
        setCurrentPage(currentPage);
    }, [setItemsPerPage, setCurrentPage]);


    const handleAddCertificateButtonClicked = useCallback(() => {
        openPopup(<AddEmployeeCertificationPopup locationId={locationIDAsString} employeeId={employeeId} onCompletedOperations={() => getCertifications(undefined)} />)
    }, [openPopup, locationIDAsString, employeeId, getCertifications]);


    const handleViewAttachment = useCallback((attachmentUrl: string) => {
        if (attachmentUrl) { window.open(attachmentUrl, "_blank"); }
        else { openPopup(<ErrorPopup>{translate("EMPLOYEES.CERTIFICATIONS.POPUPS.ViewAttachmentError")}</ErrorPopup>); }
    }, [openPopup]);


    const handleEditCertification = useCallback((id: number) => {
        openPopup(<EditEmployeeCertificationPopup locationId={locationIDAsString} employeeId={employeeId} certificationId={id} onCompletedOperations={() => getCertifications(undefined)} />)
    }, [openPopup, locationIDAsString, employeeId, getCertifications]);


    const handleDeleteCertification = useCallback((certificationId: number) => {
        if (!locationIDAsString || !employeeId || !certificationId) return;

        deleteCertificationCall
            .invoke(locationIDAsString, "" + employeeId, "" + certificationId)
            .then(() => {
                getCertifications(undefined);
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("EMPLOYEES.CERTIFICATIONS.POPUPS.DeleteCertificationSuccess")}
                    />
                );
            })
            .catch((error) => {
                if (!error) return;
                const errorMessage = error?.response?.data?.message || translate("ERRORS.UnknownError");
                openPopup(<ErrorPopup>{errorMessage}</ErrorPopup>);
            });
    }, [openPopup, locationIDAsString, employeeId, getCertifications, createNotification, deleteCertificationCall.invoke]);


    const handleDeleteCertificationWarningPopup = useCallback((id: number) => {
        openPopup(
            <WarningPopup
                className="delete-certification-popup"
                onDismissClicked={() => closePopup()}
                onConfirmClicked={() => handleDeleteCertification(id)}
                dismissText={translate("COMMON.Cancel")}
                confirmText={translate("COMMON.Delete")}
            >
                {translate("EMPLOYEES.CERTIFICATIONS.POPUPS.DeleteCertificationConfirmText")}
            </WarningPopup>
        )
    }, [openPopup, closePopup, handleDeleteCertification]);


    const handleFileImport = useCallback((file: File) => {
        if (!locationIDAsString || !employeeId) return;

        uploadCertificationsFileCall.invoke(locationIDAsString, employeeId, file)
            .then((_) => {
                getCertifications(undefined);
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("EMPLOYEES.CERTIFICATIONS.POPUPS.ImportXLSXSuccess")}
                    />
                );
            }).catch(error => {
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, employeeId, uploadCertificationsFileCall.invoke, getCertifications, createNotification, openPopup]);


    /****************************
     * CSS & HTML
     *****************************/

    const columns: ColumnDefinition<EmployeeCertificationsSummaryDTO>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => v.name,
                headerRender: translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.Name"),
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v) => DateTimeHelper.formatDateTimeLocale(v.date),
                headerRender: translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.Date"),
                width: "140px",
            },
            {
                cellRenderProp: (v) => v.isApproved ? <CheckIcon /> : <RejectIcon />,
                headerRender: translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.Approval"),
                width: "130px",
            },
            {
                cellRenderProp: (v) => v.attachment &&
                    <Tooltip text={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.ViewAttachment")}>
                        <ScalableIconButton
                            icon={<AttachmentIcon />}
                            onClick={() => v.attachment && handleViewAttachment(v.attachment.url || "")}
                            size={24}
                        />
                    </Tooltip>,
                width: "24px",
                isMobileHeaderIcon: true,
            },
            {
                cellRenderProp: (v) => {
                    return (
                        <Tooltip text={translate("EMPLOYEES.CERTIFICATIONS.POPUPS.EditCertification")}>
                            <ScalableIconButton
                                icon={<EditIcon />}
                                onClick={() => handleEditCertification(v.id)}
                                size={24}
                            />
                        </Tooltip>
                    );
                },
                width: "24px",
                isMobileHeaderIcon: true,
            },
            {
                cellRenderProp: (v) => {
                    return (
                        <Tooltip text={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.DeleteCertification")}>
                            <ScalableIconButton
                                icon={<DeleteIcon />}
                                onClick={() => handleDeleteCertificationWarningPopup(v.id)}
                                size={24}
                            />
                        </Tooltip>
                    );
                },
                width: "24px",
                isMobileHeaderIcon: true,
            },
        ], [handleEditCertification, handleDeleteCertificationWarningPopup, handleViewAttachment]);


    const renderActionButtons = useMemo(() => {
        if (isLoadingPermissions || !hasPermission) return null;

        return <>
            <Button
                text={translate("COMMON.Import")}
                type="secondary"
                onClick={() => openPopup(<ImportPopup
                    onClickDownloadTemplateBtn={() => window.open(ConfigurationProvider.getConfiguration().App.BackendUrl + "/templates/VLMT_Skill_Categories_Template.xlsx", "_blank")} //TODO change template link
                    onClickImportBtn={handleFileImport}
                />)}
            />
            <ResponsiveButton
                text={translate("EMPLOYEES.CERTIFICATIONS.AddCertificate")}
                type="secondary"
                icon={<AddIcon />}
                onClick={handleAddCertificateButtonClicked}
            />
        </>;
    }, [isLoadingPermissions, hasPermission, handleAddCertificateButtonClicked]);


    return <PageLayout
        tabTitle={translate("EMPLOYEES.CERTIFICATIONS.Certifications")}
        pageTitle={translate("EMPLOYEES.CERTIFICATIONS.Certifications")}
        className="certifications-page"
        showBreadcrumb
        actions={renderActionButtons}
    >
        <FormFieldTextInput
            formControl={searchboxController}
            icon={<SearchIcon />}
            placeholder={translate("COMMON.FORM.SearchPlaceholder")}
            className="search-box"
        />
        <ResponsiveDataTable
            columnDefinitions={columns}
            items={certifications || []}
            totalitems={totalItems || 0}
            paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 20, 30] }}
            currentpage={currentpage}
            isLoading={getCertificationsCall.isLoading}
            onPageAndItemsChanged={handleOnPageAndItemsChanged}
        />
    </PageLayout>;
}