import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { EmployeeCertificationCreateDTO } from "../models/dtos/EmployeeCertificationCreateDTO";
import { EmployeeCertificationUpdateDTO } from "../models/dtos/EmployeeCertificationUpdateDTO";
import { EmployeeCertificationsResponseDTO } from "../models/dtos/EmployeeCertificationsResponseDTO";
import { EmployeeCertificationsSummaryDTO } from "../models/dtos/EmployeeCertificationsSummaryDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { QueryOptions } from "../../../models/api/QueryOptions";

const employeeCertificationsMock: EmployeeCertificationsResponseDTO = {
    items: [{
        id: 1,
        name: "Certificação A",
        date: "2024-01-01T00:00:00+00:00",
        isApproved: true,
        attachment: {
            name: "teste.pdf",
            url: "teste.pdf"
        },
    }, {
        id: 2,
        name: "Certificação B",
        date: "2024-01-01T00:00:00+00:00",
        isApproved: false,
        attachment: {
            name: null,
            url: null
        },
    }],
    totalItems: 2
}

const employeeCertificationMock: EmployeeCertificationsSummaryDTO = {
    id: 1,
    name: "Certificação A",
    date: "2024-01-01T00:00:00+00:00",
    isApproved: true,
    attachment: {
        name: "teste.pdf",
        url: "teste.pdf"
    },
};

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class EmployeeCertificationsService {

    getCertifications(locationId: string, employeeId: string, query: QueryOptions, abortSignal: AbortSignal | undefined): Promise<EmployeeCertificationsResponseDTO> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    items: employeeCertificationsMock.items,
                    totalItems: employeeCertificationsMock.totalItems
                });
            }, 1000);
        });

        // return HttpClient.sessionRequest<EmployeeCertificationsResponseDTO>({
        //     method: "GET",
        //     url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Certifications`),
        //     signal: abortSignal,
        //     params: query,
        //     paramsSerializer: (params) => {
        //         return qs.stringify(params, { arrayFormat: "repeat" });
        //     },
        // })
        //     .then((response) => {
        //         if (!response.data) return null;
        //         return {
        //             items: response.data.items,
        //             totalItems: response.data.totalItems
        //         }
        //     })
        //     .catch((error) => {
        //         throw error;
        //     });
    }

    getCertificationSummaryById(locationId: string, employeeId: string, certificationId: string): Promise<EmployeeCertificationsSummaryDTO> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(employeeCertificationMock);
            }, 1000);
        });

        // return HttpClient.sessionRequest<EmployeeCertificationsSummaryDTO>({
        //     method: "GET",
        //     url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Certifications/${certificationId}`),
        // })
        //     .then((res) => {
        //         if (!res.data) return null;
        //         return res.data;
        //     })
        //     .catch((error) => {
        //         throw error;
        //     });
    }

    addCertification(locationId: string, employeeId: string, requestDto: EmployeeCertificationCreateDTO): Promise<number> {
        return HttpClient.sessionRequest({
            method: "POST",
            // url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Certifications`),
            data: requestDto,
        })
            .then((response) => response.data.id)
            .catch((error) => {
                throw error;
            });
    }

    uploadCertificationAttachment(locationId: string, employeeId: string, certificationId: string, file: File): Promise<void> {
        var formData = new FormData();
        formData.append("file", file, file.name);

        return HttpClient.sessionRequest({
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
            },
            // url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Certifications/${certificationId}/Attachment`),
            data: formData
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    editCertification(locationId: string, employeeId: string, certificationId: string, requestDto: EmployeeCertificationUpdateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            // url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Certifications/${certificationId}`),
            data: requestDto
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    deleteCertification(locationId: string, employeeId: string, certificationId: string): Promise<void> {
        return HttpClient.sessionRequest({
            method: "DELETE",
            // url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Certifications/${certificationId}`),
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    deleteCertificationAttachment(locationId: string, employeeId: string, certificationId: string): Promise<void> {
        return HttpClient.sessionRequest({
            method: "DELETE",
            // url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Certifications/${certificationId}`),
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    uploadCertificationsFile(locationId: string, employeeId: string, file: File): Promise<void> {
        var formData = new FormData();
        formData.append("file", file)

        return HttpClient.sessionRequest({
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
            },
            url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/FromXLS`),
            data: formData
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

}
