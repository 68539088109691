import "./EmployeeCareerLadderPage.css"

import { useCallback, useEffect, useMemo, useState } from "react";

import { AddEmployeeCareerLadderNodePopup } from "./popups/AddEmployeeCareerLadderNodePopup";
import { ReactComponent as AddIcon } from "../../../../lib/assets/icons/add.svg";
import { EmployeeCareerLadderService } from "../../services/EmployeeCareerLadderService";
import { EmployeeCareerLadderStepTreeNodeDTO } from "../../models/dtos/EmployeeCareerLadderStepTreeNodeDTO";
import { EmployeeCareerLadderTree } from "./components/tree-node/EmployeeCareerLadderTree";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { PagePermissions } from "../../../../lib/infrastructure/authorization/PagePermissions";
import { Permission } from "../../../../models/api/gate/Permission";
import { RoundIconButton } from "../../../../lib/components/buttons/RoundIconButton";
import { SelectBusinessLinePopup } from "./popups/SelectBusinessLinePopup";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useParams } from "react-router-dom";
import { usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";

const topPermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES];

const svc = new EmployeeCareerLadderService();

interface IEmployeeCareerLadderPageProps { }

export function EmployeeCareerLadderPage(props: IEmployeeCareerLadderPageProps) {
    const locationID = useGlobalLocation();
    const locationIDAsString = useMemo(() => "" + locationID, [locationID])

    const { employeeId } = useParams();
    const openPopup = usePopup();

    const [careerLadderNodes, setCareerLadderNodes] = useState<EmployeeCareerLadderStepTreeNodeDTO[] | undefined>([])

    const getEmployeeCareerLadderCall = useServiceCallPro(svc.getEmployeeCareerLadder);


    /****************************
    * DATA REQUESTS
    *****************************/

    const getEmployeeCareerLadder = useCallback(() => {
        if (!locationIDAsString || !employeeId) return;

        getEmployeeCareerLadderCall.invoke(locationIDAsString, "" + employeeId)
            .then((data) => {
                if (data) {
                    setCareerLadderNodes(data.nodes);
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, employeeId, getEmployeeCareerLadderCall.invoke, openPopup]);


    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/

    useEffect(() => {
        getEmployeeCareerLadder();
    }, []);


    /****************************
     * USER ACTIONS
     *****************************/

    const handleAddRoleNode = useCallback((businessLineId: number) => {
        openPopup(<AddEmployeeCareerLadderNodePopup locationId={locationIDAsString} businessLineId={"" + businessLineId} employeeId={employeeId} onCompletedOperations={getEmployeeCareerLadder} />);
    }, [openPopup, locationIDAsString, employeeId, getEmployeeCareerLadder]);


    const handleSelectBusinessLine = useCallback(() => {
        openPopup(<SelectBusinessLinePopup locationId={locationIDAsString} onCompletedOperations={handleAddRoleNode} />);
    }, [openPopup, locationIDAsString, handleAddRoleNode]);


    /****************************
    * CSS & HTML
    *****************************/

    const renderCareerLadderTree = useMemo(() => {
        if (careerLadderNodes) {
            return <EmployeeCareerLadderTree careerLadderNodes={careerLadderNodes} locationId={locationIDAsString} employeeId={employeeId} onCompleteOperations={getEmployeeCareerLadder} />
        }
    }, [locationIDAsString, careerLadderNodes, employeeId, getEmployeeCareerLadder])


    return <PagePermissions permissions={topPermissions} >
        <PageLayout
            tabTitle={translate("EMPLOYEES.CAREER.CareerLadder")}
            pageTitle={translate("EMPLOYEES.CAREER.CareerLadder")}
            className="employee-career-ladder"
            showBreadcrumb
        >
            {getEmployeeCareerLadderCall.isLoading && <FullScreenLoader />}
            <RoundIconButton
                className="add-employee-career-ladder-role"
                icon={<AddIcon />}
                onClick={handleSelectBusinessLine}
            />
            {renderCareerLadderTree}
        </PageLayout>
    </PagePermissions>;
}