import "./EmployeeCareerLadderTree.css";

import { useCallback, useMemo } from "react";
import { useCreateNotification, usePopup } from "../../../../../../lib/infrastructure/ui/UIServices";

import { DateTimeHelper } from "../../../../../../lib/helpers/datetime/DateTime";
import { ReactComponent as DeleteIcon } from "../../../../../../lib/assets/icons/remover.svg";
import { EmployeeCareerLadderService } from "../../../../services/EmployeeCareerLadderService";
import { EmployeeCareerLadderStepTreeNodeDTO } from "../../../../models/dtos/EmployeeCareerLadderStepTreeNodeDTO";
import { ErrorPopup } from "../../../../../../lib/components/popup/ErrorPopup";
import { Notification } from "../../../../../../lib/components/notifications/Notification";
import { ScalableIconButton } from "../../../../../../lib/components/buttons/ScalableIconButton";
import { Spacer } from "../../../../../../lib/components/separator/Spacer";
import { translate } from "../../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useServiceCallPro } from "../../../../../../lib/hooks/useServiceCall";

interface IEmployeeCareerLadderTreeProps {
    locationId: string | undefined;
    employeeId: string | undefined;
    careerLadderNodes: EmployeeCareerLadderStepTreeNodeDTO[];
    onCompleteOperations: () => void;
}

var svc = new EmployeeCareerLadderService();

export function EmployeeCareerLadderTree(props: IEmployeeCareerLadderTreeProps) {
    const openPopup = usePopup();
    const createNotification = useCreateNotification();

    const removeEmployeeNodeCall = useServiceCallPro(svc.removeEmployeeNode);


    /****************************
     * USER ACTIONS
     *****************************/

    const handleRemoveRoleNode = useCallback((nodeId: number) => {
        if (!props.locationId || !props.employeeId || !nodeId) return;

        removeEmployeeNodeCall
            .invoke(props.locationId, "" + props.employeeId, "" + nodeId)
            .then(() => {
                props.onCompleteOperations();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("EMPLOYEES.CAREER.POPUPS.RemoveRoleSuccess")}
                    />
                );
            }
            )
            .catch((error) => {
                if (!error) return;
                const errorMessage = error?.response?.data?.message || translate("ERRORS.UnknownError");
                openPopup(<ErrorPopup>{errorMessage}</ErrorPopup>);
            });
    }, [createNotification, openPopup, removeEmployeeNodeCall.invoke, props.locationId, props.employeeId, props.onCompleteOperations]);


    /****************************
     * CSS & HTML
     *****************************/

    const renderTree = useMemo(() => {
        return props.careerLadderNodes.map((node: EmployeeCareerLadderStepTreeNodeDTO, index: number) =>
            <div key={node.careerLadderStepId} className='employe-role-node'>
                <div className="role-node-wrapper">
                    {index !== 0 && <div className="lines"><div className="first-line horizontal-line"></div><div className="second-line"></div></div>}
                    <div className='role-node-content-wrapper'>
                        <div className="role-node-content">
                            <div className="role-node-header-info">
                                <div className='small-copy'>{node.role?.description}</div>
                                <ScalableIconButton icon={<DeleteIcon />} size={18} onClick={() => handleRemoveRoleNode(node.careerLadderStepId)} />
                            </div>
                            <div className="role-node-additional-info">
                                <div className="role-node-additional-info-block">
                                    <div className='small-copy label'>{translate("EMPLOYEES.CAREER.INFOANDFORM.JobGrade")}</div>
                                    <div className='small-copy'>{node.role?.jobGrade || "-"}</div>
                                </div>
                                <div className="role-node-additional-info-block">
                                    <div className='small-copy label'>{translate("EMPLOYEES.CAREER.INFOANDFORM.StartDate")}</div>
                                    <div className='small-copy'>{DateTimeHelper.formatDateTimeLocale(node.startDate) || "-"}</div>
                                </div>
                                <div className="role-node-additional-info-block">
                                    <div className='small-copy label'>{translate("EMPLOYEES.CAREER.INFOANDFORM.EndDate")}</div>
                                    <div className='small-copy'>{DateTimeHelper.formatDateTimeLocale(node.endDate) || "-"}</div>
                                </div>
                            </div>
                        </div>
                        <Spacer mode="vertical" px={20} />
                    </div>
                    <div className="lines"><div className={"first-line " + (index !== props.careerLadderNodes.length - 1 && "horizontal-line")}></div></div>
                </div>
            </div>)
    }, [props.careerLadderNodes, handleRemoveRoleNode])


    return (
        <div className="employee-career-ladder-tree">
            {renderTree}
        </div>
    );
};
