import { EmployeeFollowUpItemStatusAsEnum, EmployeeFollowUpItemStatusInfo, getAnalysisRequestStatus } from "../../../models/dtos/EmployeeFollowUpItemStatus";
import { useCallback, useMemo } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { DateTime } from "luxon";
import { EmployeeFollowUpItemCreateDTO } from "../../../models/dtos/EmployeeFollowUpItemCreateDTO";
import { EmployeeFollowUpItemsService } from "../../../services/EmployeeFollowUpItemsService";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FormFieldDatePicker } from "../../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldSelectSingle } from "../../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextArea } from "../../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { hasOneElementSelected } from "../../../../../lib/validators/ValidateSelectSingle";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../../lib/hooks/useServiceCall";
import { validateDateTime } from "../../../../../lib/validators/ValidateDateTime";
import { validateWhiteSpaceStrings } from "../../../../../lib/validators/ValidateWhiteSpaceStrings";

interface IAddEmployeeFollowUpItemPopupProps {
    locationId: string | undefined;
    employeeId: string | undefined;
    onCompletedOperations: () => void;
}

var svc = new EmployeeFollowUpItemsService();

const labelItemStatusSelector = (item: EmployeeFollowUpItemStatusInfo) => item.description;
const idItemStatusSelector = (item: EmployeeFollowUpItemStatusInfo) => item.id;

export function AddEmployeeFollowUpItemPopup(props: IAddEmployeeFollowUpItemPopupProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();
    const { locationId, employeeId } = props;

    const addFollowUpItemCall = useServiceCallPro(svc.addFollowUpItem);

    const itemsStatus = useMemo(() => {
        return getAnalysisRequestStatus();
    }, []);

    const nameFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const descriptionFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const startDateFormControl = useFormControl<DateTime>({
        validators: [validateDateTime()],
        enableAutoValidate: false
    });

    const conclusionDateFormControl = useFormControl<DateTime>({
        validators: [validateDateTime()],
        enableAutoValidate: false
    });

    const statusFormControl = useFormControl<EmployeeFollowUpItemStatusInfo>({
        validators: [hasOneElementSelected()],
        enableAutoValidate: true,
    });


    /****************************
    * DATA REQUESTS
    *****************************/



    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/



    /****************************
    * USER ACTIONS
    *****************************/

    const handleSelectClicked = useCallback(() => {
        if (!locationId || !employeeId) return;
        if (!AllValid(nameFormControl.validate(), descriptionFormControl.validate(), startDateFormControl.validate(), conclusionDateFormControl.validate(), statusFormControl.validate())) return;

        if (!nameFormControl.value || !descriptionFormControl.value || !statusFormControl.value) return;
        let request: EmployeeFollowUpItemCreateDTO = {
            name: nameFormControl.value,
            description: descriptionFormControl.value,
            startDate: startDateFormControl.value?.toISO() || null,
            conclusionDate: conclusionDateFormControl.value?.toISO() || null,
            status: EmployeeFollowUpItemStatusAsEnum[parseInt(statusFormControl.value?.id)]
        };

        addFollowUpItemCall
            .invoke(locationId, employeeId, request)
            .then(() => {
                createNotification(<Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("EMPLOYEES.ITEMS.POPUPS.AddFollowUpItemSuccess")}
                />);
                closePopup();
                props.onCompletedOperations();
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [closePopup, nameFormControl, descriptionFormControl, startDateFormControl, conclusionDateFormControl, createNotification, openPopup, employeeId, locationId, props.onCompletedOperations, addFollowUpItemCall.invoke]);


    return <PopupContainer className="popup-add-employee-item">
        {addFollowUpItemCall.isLoading ? <FullScreenLoader /> : null}
        <PopupHeader title={translate("EMPLOYEES.ITEMS.POPUPS.AddFollowUpItem")} />
        <PopupContent>
            <FormSection isInputGap childrenFlexDirColumn>
                <FormFieldTextInput
                    formControl={nameFormControl}
                    label={translate("EMPLOYEES.ITEMS.INFOANDFORM.Name")}
                    placeholder={translate("EMPLOYEES.ITEMS.INFOANDFORM.NamePlaceholder")}
                />
                <FormFieldTextArea
                    formControl={descriptionFormControl}
                    label={translate("EMPLOYEES.ITEMS.INFOANDFORM.Description")}
                    placeholder={translate("EMPLOYEES.ITEMS.INFOANDFORM.DescriptionPlaceholder")}
                />
                <FormSection isInputGap>
                    <FormFieldDatePicker
                        formControl={startDateFormControl}
                        label={translate("EMPLOYEES.ITEMS.INFOANDFORM.StartDate")}
                        placeholder={translate("EMPLOYEES.ITEMS.INFOANDFORM.StartDatePlaceholder")}
                        maxDate={conclusionDateFormControl.value}
                    />
                    <FormFieldDatePicker
                        formControl={conclusionDateFormControl}
                        label={translate("EMPLOYEES.ITEMS.INFOANDFORM.ConclusionDate")}
                        placeholder={translate("EMPLOYEES.ITEMS.INFOANDFORM.ConclusionDatePlaceholder")}
                        minDate={startDateFormControl.value}
                    />
                </FormSection>
                <FormFieldSelectSingle
                    formControl={statusFormControl}
                    label={translate("EMPLOYEES.ITEMS.INFOANDFORM.Status")}
                    placeholder={translate("EMPLOYEES.ITEMS.INFOANDFORM.StatusPlaceholder")}
                    options={itemsStatus}
                    labelSelector={labelItemStatusSelector}
                    idSelector={idItemStatusSelector}
                    maxHeightOptions={"16rem"}
                />
            </FormSection>
            <Spacer mode={"vertical"} px="30" />
            <PopupActionButtons
                buttons={[
                    {
                        text: translate("COMMON.Cancel"),
                        type: "tertiary",
                        onClick: () => closePopup(),
                    },
                    {
                        text: translate("COMMON.Add"),
                        type: "primary",
                        onClick: handleSelectClicked,
                    },
                ]}
            />
        </PopupContent>
    </PopupContainer>;
}