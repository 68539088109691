import { useCallback, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { Checkbox } from "../../../../../lib/components/checkbox/Checkbox";
import { DateTime } from "luxon";
import { EmployeeCertificationCreateDTO } from "../../../models/dtos/EmployeeCertificationCreateDTO";
import { EmployeeCertificationsService } from "../../../services/EmployeeCertificationsService";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FileUploadButton } from "../../../../../lib/components/file/FileUploadButton";
import { FormFieldDatePicker } from "../../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { ReactComponent as ImportIcon } from "../../../../../lib/assets/icons/import.svg";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../../lib/hooks/useServiceCall";
import { validateDateTime } from "../../../../../lib/validators/ValidateDateTime";
import { validateWhiteSpaceStrings } from "../../../../../lib/validators/ValidateWhiteSpaceStrings";

interface IAddEmployeeCertificationPopupProps {
    locationId: string | undefined;
    employeeId: string | undefined;
    onCompletedOperations: () => void;
}

var svc = new EmployeeCertificationsService();

export function AddEmployeeCertificationPopup(props: IAddEmployeeCertificationPopupProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();
    const { locationId, employeeId } = props;

    const [isApproved, setIsApproved] = useState<boolean>(false);
    const [attachmentFile, setAttachmentFile] = useState<File>();

    const addCertificationCall = useServiceCallPro(svc.addCertification);
    const uploadCertificationAttachmentCall = useServiceCallPro(svc.uploadCertificationAttachment);

    const nameFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const dateFormControl = useFormControl<DateTime>({
        validators: [validateDateTime()],
        enableAutoValidate: false
    });



    /****************************
    * DATA REQUESTS
    *****************************/



    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/



    /****************************
    * USER ACTIONS
    *****************************/

    const handleCheckboxChange = useCallback((isChecked: boolean) => {
        setIsApproved(isChecked);
    }, [])


    const handleFileImport = useCallback((file: File) => {
        setAttachmentFile(file);
    }, []);


    const handleSelectClicked = useCallback(() => {
        if (!locationId || !employeeId) return;
        if (!AllValid(nameFormControl.validate(), dateFormControl.validate())) return;

        if (!nameFormControl.value) return;
        let request: EmployeeCertificationCreateDTO = {
            name: nameFormControl.value,
            date: dateFormControl.value?.toISO() || null,
            isApproved: isApproved,
        };

        addCertificationCall
            .invoke(locationId, employeeId, request)
            .then((certificationId) => {
                if (attachmentFile) {
                    uploadCertificationAttachmentCall
                        .invoke(locationId, employeeId, "" + certificationId, attachmentFile)
                        .then(_ => {
                            createNotification(<Notification
                                type="success"
                                title={translate("COMMON.SYSTEMPOPUPS.Success")}
                                text={translate("EMPLOYEES.CERTIFICATIONS.POPUPS.AddCertificationSuccess")}
                            />);
                            closePopup();
                            props.onCompletedOperations();
                        }).catch(error => {
                            if (!error) return;
                            openPopup(<ErrorPopup>{translate("EMPLOYEES.CERTIFICATIONS.POPUPS.AddCertificationUploadError")}</ErrorPopup>);
                        });
                }
                else {
                    createNotification(<Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("EMPLOYEES.CERTIFICATIONS.POPUPS.AddCertificationSuccess")}
                    />);
                    closePopup();
                    props.onCompletedOperations();
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [closePopup, nameFormControl, dateFormControl, isApproved, createNotification, openPopup, employeeId, locationId, props.onCompletedOperations, addCertificationCall.invoke, uploadCertificationAttachmentCall.invoke, attachmentFile]);


    return <PopupContainer className="popup-add-employee-certification">
        {addCertificationCall.isLoading ? <FullScreenLoader /> : null}
        <PopupHeader title={translate("EMPLOYEES.CERTIFICATIONS.POPUPS.AddCertification")} />
        <PopupContent>
            <FormSection isInputGap childrenFlexDirColumn>
                <FormFieldTextInput
                    formControl={nameFormControl}
                    label={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.Name")}
                    placeholder={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.NamePlaceholder")}
                />
                <FormFieldDatePicker
                    formControl={dateFormControl}
                    label={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.Date")}
                    placeholder={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.DatePlaceholder")}
                    maxDate={dateFormControl.value}
                />
                <Spacer mode={"vertical"} px="0" />
                <Checkbox
                    text={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.Approved")}
                    isChecked={isApproved}
                    onClick={(isChecked) => handleCheckboxChange(isChecked)}
                />
                <Spacer mode={"vertical"} px="0" />
                <div className="attachment-info">
                    <FileUploadButton
                        text={translate("COMMON.AddAttachment")}
                        type="secondary"
                        onFileSelected={handleFileImport}
                        icon={<ImportIcon />}
                    />
                    <Spacer mode={"vertical"} px="10" />
                    {attachmentFile ? <div className="attachment-name">{attachmentFile.name}</div> : null}
                </div>
            </FormSection>
            <Spacer mode={"vertical"} px="30" />
            <PopupActionButtons
                buttons={[
                    {
                        text: translate("COMMON.Cancel"),
                        type: "tertiary",
                        onClick: () => closePopup(),
                    },
                    {
                        text: translate("COMMON.Add"),
                        type: "primary",
                        onClick: handleSelectClicked,
                    },
                ]}
            />
        </PopupContent>
    </PopupContainer>;
}