import "./BonusEvaluationCard.css";

import { useCallback, useEffect, useMemo, useState } from "react";

import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { EvaluationBonusDTO } from "../../models/EvaluationBonusDTO";
import { EvaluationsService } from "../../services/EvaluationsService";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { TitledCardContainer } from "../../../../lib/layouts/containers/card/TitledCardContainer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";

interface IBonusEvaluationCardProps {
    locationId: string;
    businessLineId: string;
    periodId: string;
    matrixId: string;
    evaluationId: string;
}

var svc = new EvaluationsService();

export function BonusEvaluationCard(props: IBonusEvaluationCardProps) {
    const openPopup = usePopup();

    const [bonus, setBonus] = useState<EvaluationBonusDTO[]>([]);

    const getEvaluationBonusCall = useServiceCallPro(svc.getEvaluationBonus);


    /****************************
    * DATA REQUESTS
    *****************************/
    const getEvaluationBonus = useCallback(() => {

        if (!props.locationId || !props.businessLineId || !props.periodId || !props.matrixId || !props.evaluationId) return;

        getEvaluationBonusCall.invoke(props.locationId, props.businessLineId, props.periodId, props.matrixId, props.evaluationId)
            .then((res) => {
                if (!res) return;
                setBonus(res.items);
            })

            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [props.locationId, props.businessLineId, props.periodId, props.matrixId, props.evaluationId, getEvaluationBonusCall.invoke, openPopup]);


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/
    useEffect(() => {
        getEvaluationBonus();
    }, [])


    /****************************
     * CSS & HTML
     *****************************/
    const columns: ColumnDefinition<EvaluationBonusDTO>[] = useMemo(() => [
        {
            cellRenderProp: (item) => item.periodName,
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Period"),
            isMobilePrimaryCell: true,
        },
        {
            cellRenderProp: (item) => item.evaluationValue,
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Evaluation"),
            width: "100px",
            isMobilePrimaryCell: true,
        },
    ], []);


    return <TitledCardContainer className="bonus-evaluation-card" title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Bonus")}>
        <ResponsiveDataTable
            columnDefinitions={columns}
            items={bonus}
            totalitems={bonus.length}
            isLoading={getEvaluationBonusCall.isLoading}
        />
    </TitledCardContainer>;
}