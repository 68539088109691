import "./CreatePeriodPopup.css";

import { useCallback, useEffect } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../common/validators/ValidateFormControls";
import { DateTime } from "luxon";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { EvaluationPeriodCreateDTO } from "../../models/dtos/EvaluationPeriodCreateDTO";
import { EvaluationPeriodsService } from "../../services/EvaluationPeriodsService";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldDatePicker } from "../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../lib/utils/TrimString";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { validateDateTime } from "../../../../lib/validators/ValidateDateTime";
import { validateExternalDateTimeControl } from "../../../../common/validators/ValidateDateTimeFormControls";
import { validateWhiteSpaceStrings } from "../../../../lib/validators/ValidateWhiteSpaceStrings";

var evaluationPeriodsSvc = new EvaluationPeriodsService();


export interface ICreatePeriodPopupPopup {
    locationID: string;
    businessLineId: string;
    onCompletedOperations?: () => void;
}

export function CreatePeriodPopup(props: ICreatePeriodPopupPopup) {
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();

    const nameFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const initialDateFormControl = useFormControl<DateTime>({
        validators: [validateDateTime()],
        enableAutoValidate: false
    });

    const finalDateFormControl = useFormControl<DateTime>({
        validators: [validateDateTime()],
        enableAutoValidate: false
    });

    const evaluationsStartDayControl = useFormControl<DateTime>({
        validators: [],
        enableAutoValidate: false
    });

    const evaluationsFinalDayControl = useFormControl<DateTime>({
        validators: [validateExternalDateTimeControl(evaluationsStartDayControl)],
        enableAutoValidate: false,
        isDisabled: true
    });

    const calibrationsStartDayControl = useFormControl<DateTime>({
        validators: [],
        isDisabled: true
    });

    const calibrationsFinalDayControl = useFormControl<DateTime>({
        validators: [validateExternalDateTimeControl(calibrationsStartDayControl)],
        enableAutoValidate: false,
        isDisabled: true
    });


    const createEvaluationPeriodCall = useServiceCallPro(evaluationPeriodsSvc.createEvaluationPeriods);



    /****************************
    * DATA REQUESTS
    *****************************/

    const createEvaluationPeriod = useCallback(() => {

        if (!props.locationID) return;
        if (!AllValid(nameFormControl.validate(), calibrationsFinalDayControl.validate(), evaluationsFinalDayControl.validate())) return;

        const request: EvaluationPeriodCreateDTO = {
            name: trimString(nameFormControl.value),
            periodStartDate: initialDateFormControl.value?.toISO() || undefined,
            periodEndDate: finalDateFormControl.value?.toISO() || undefined,

            evaluationsStartDate: evaluationsStartDayControl.value && evaluationsFinalDayControl.value ? evaluationsStartDayControl.value?.toISO() : undefined,
            evaluationsEndDate: evaluationsStartDayControl.value && evaluationsFinalDayControl.value ? evaluationsFinalDayControl.value?.toISO() : undefined,

            calibrationsStartDate: calibrationsStartDayControl.value && calibrationsFinalDayControl.value ? calibrationsStartDayControl.value?.toISO() : undefined,
            calibrationsEndDate: calibrationsStartDayControl.value && calibrationsFinalDayControl.value ? calibrationsFinalDayControl.value?.toISO() : undefined,
        };

        createEvaluationPeriodCall
            .invoke(props.locationID, props.businessLineId, request)
            .then((_) => {
                props.onCompletedOperations && props.onCompletedOperations();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("BUSINESSLINES.EVALUATIONPERIODS.MESSAGES.CreatePeriodSuccess")}
                    ></Notification>
                );
                closePopup();
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [props, nameFormControl, calibrationsFinalDayControl, evaluationsFinalDayControl, createEvaluationPeriodCall.invoke, initialDateFormControl.value, finalDateFormControl.value, evaluationsStartDayControl.value, calibrationsStartDayControl.value, createNotification, closePopup, openPopup])


    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/

    useEffect(() => {
        if (evaluationsStartDayControl.value) {
            evaluationsFinalDayControl.setIsDisabled(false);
        } else {
            evaluationsFinalDayControl.setIsDisabled(true);
            evaluationsFinalDayControl.setValue(undefined);
        }
    }, [evaluationsStartDayControl.value]);


    useEffect(() => {
        if (evaluationsFinalDayControl.value) {
            calibrationsStartDayControl.setIsDisabled(false);
        } else {
            calibrationsStartDayControl.setIsDisabled(true);
            calibrationsStartDayControl.setValue(undefined);
        }
    }, [evaluationsFinalDayControl.value]);


    useEffect(() => {
        if (calibrationsStartDayControl.value) {
            calibrationsFinalDayControl.setIsDisabled(false);
        } else {
            calibrationsFinalDayControl.setIsDisabled(true);
            calibrationsFinalDayControl.setValue(undefined);
        }
    }, [calibrationsStartDayControl.value]);


    /****************************
     * USER ACTIONS
     *****************************/
    const handleCreatePeriodButtonClicked = useCallback(() => {

        createEvaluationPeriod();

    }, [createEvaluationPeriod])


    /****************************
    * CSS & HTML
    *****************************/

    return (
        <PopupContainer className="create-period-popup">
            <PopupHeader
                title={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.EvaluationPeriodData")}
            />
            <PopupContent >

                {createEvaluationPeriodCall.isLoading && <FullScreenLoader />}

                <FormContainer>

                    <FormSection isInputGap>
                        <FormFieldTextInput
                            formControl={nameFormControl}
                            label={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.Name")}
                            placeholder={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.NamePlaceholder")} />
                    </FormSection>

                    <FormSection isInputGap>
                        <FormFieldDatePicker
                            formControl={initialDateFormControl}
                            placeholder={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.StartDatePlaceholder")}
                            label={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.StartDate")}
                            maxDate={finalDateFormControl.value}
                        />
                        <FormFieldDatePicker
                            formControl={finalDateFormControl}
                            placeholder={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.EndDatePlaceholder")}
                            label={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.EndDate")}
                            minDate={initialDateFormControl.value} />
                    </FormSection>

                    <Spacer mode="vertical" px="5" />

                    <div className="separator">{translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.DatesToNotificateManagersByEmail")}</div>

                    <FormSection isInputGap>
                        <FormFieldDatePicker
                            formControl={evaluationsStartDayControl}
                            placeholder={translate("COMMON.POPUPS.SelectDate")}
                            label={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.InitialDayToEvaluate")}
                            maxDate={evaluationsFinalDayControl.value ?? calibrationsStartDayControl.value ?? calibrationsFinalDayControl.value}
                        />

                        <FormFieldDatePicker
                            formControl={evaluationsFinalDayControl}
                            placeholder={translate("COMMON.POPUPS.SelectDate")}
                            label={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.FinalDayToEvaluate")}
                            minDate={evaluationsStartDayControl.value}
                            maxDate={calibrationsStartDayControl.value ?? calibrationsFinalDayControl.value}
                        />
                    </FormSection>

                    <FormSection isInputGap>
                        <FormFieldDatePicker
                            formControl={calibrationsStartDayControl}
                            placeholder={translate("COMMON.POPUPS.SelectDate")}
                            label={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.InitialDayToCalibrate")}
                            minDate={evaluationsFinalDayControl.value ?? evaluationsStartDayControl.value}
                            maxDate={calibrationsFinalDayControl.value}
                        />

                        <FormFieldDatePicker
                            formControl={calibrationsFinalDayControl}
                            placeholder={translate("COMMON.POPUPS.SelectDate")}
                            label={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.FinalDayToCalibrate")}
                            minDate={calibrationsStartDayControl.value}
                        />
                    </FormSection>

                </FormContainer>

                <Spacer mode={"vertical"} px="30" />

                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("BUSINESSLINES.EVALUATIONPERIODS.CreatePeriod"),
                            type: "primary",
                            onClick: handleCreatePeriodButtonClicked,
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
