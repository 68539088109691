import { AllValid } from "../../../../common/validators/ValidateFormControls";
import { DateTime } from "luxon";
import { EvaluationFollowUpItemDTO } from "../../models/EvaluationFollowUpItemDTO";
import { EvaluationFollowUpItemStatus } from "../../models/EvaluationFollowUpItemStatus";
import { FormFieldDatePicker } from "../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldTextArea } from "../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../lib/components/form/section/FormSection";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../lib/utils/TrimString";
import { useCallback } from "react";
import { useClosePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { useFormControl } from "../../../../lib/components/form/Form";
import { validateDateTime } from "../../../../lib/validators/ValidateDateTime";
import { validateWhiteSpaceStrings } from "../../../../lib/validators/ValidateWhiteSpaceStrings";

interface IAddFollowUpItemPopupProps {
    onCompletedOperations: (followUpItem: EvaluationFollowUpItemDTO) => void;
}

export function AddFollowUpItemPopup(props: IAddFollowUpItemPopupProps) {
    const closePopup = useClosePopup();

    const nameFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const descriptionFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const startDateFormControl = useFormControl<DateTime>({
        validators: [validateDateTime()],
        enableAutoValidate: false,
    });

    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/


    /****************************
     * USER ACTIONS
     *****************************/
    const handleAddClicked = useCallback(() => {
        if (!AllValid(nameFormControl.validate(), descriptionFormControl.validate(), startDateFormControl.validate())) return;

        props.onCompletedOperations({
            name: trimString(nameFormControl.value),
            description: trimString(descriptionFormControl.value),
            startDate: startDateFormControl.value ? startDateFormControl.value.toISO() : null,
            conclusionDate: null,
            status: EvaluationFollowUpItemStatus.INPROGRESS,
        });
        closePopup();
    }, [props.onCompletedOperations, closePopup, nameFormControl, descriptionFormControl, startDateFormControl]);


    return (
        <PopupContainer className="popup-add-follow-up-item">
            <PopupHeader title={translate("BUSINESSLINES.MATRIX.POPUP.AddChecklistItem")} />
            <PopupContent>
                <FormSection childrenFlexDirColumn>
                    <FormFieldTextInput
                        formControl={nameFormControl}
                        label={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FollowUpItemName")}
                        placeholder={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FollowUpItemNamePlaceholder")}
                    />
                    <FormFieldTextArea
                        formControl={descriptionFormControl}
                        label={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FollowUpDescription")}
                        placeholder={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FollowUpDescriptionPlaceholder")}
                    />
                    <FormSection isInputGap>
                        <FormFieldDatePicker
                            formControl={startDateFormControl}
                            label={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FollowUpStartDate")}
                            placeholder={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FollowUpStartDatePlaceholder")}
                        />
                    </FormSection>
                </FormSection>
                <Spacer mode={"vertical"} px="30" />
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Add"),
                            type: "primary",
                            onClick: handleAddClicked,
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
