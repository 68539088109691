import "./AppMainLayout.css";

import {
  InternationalizationProvider,
  LanguageLocale,
} from "../../../lib/infrastructure/i18n/InternationalizationProvider";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGlobalLocation, useSetGlobalLocation } from "../../../lib/infrastructure/location/LocationServices";
import { useLocation, useNavigate } from "react-router-dom";
import {
  usePopup,
  useSideBarButtons,
  useWindowResize,
} from "../../../lib/infrastructure/ui/UIServices";

import { ContextualMenuItems } from "../../../lib/components/contextual-menu/ContextualMenu";
import { ReactComponent as EndSessionSVG } from "../../../lib/assets/icons/end-session.svg";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { ReactComponent as IconEN } from "../../../lib/assets/icons/en-icon.svg";
import { ReactComponent as IconPT } from "../../../lib/assets/icons/pt-icon.svg";
import { LangChangedEvent } from "../../../events/LangChangedEvent";
import { LocationService } from "../../../features/admin/locations/services/LocationServices";
import { MiniDropdown } from "../../../lib/components/form/mini-dropdown/MiniDropdown";
import { MobileNavBar } from "../../../lib/components/nav-bar/MobileNavBar";
import { NavBar } from "../../../lib/components/nav-bar/NavBar";
import { SplashScreen } from "../../pages/splash-screen/SplashScreen";
import { UserLocation } from "../../../models/api/users/UserLocation";
import { UserSettingsService } from "../../../services/UserSettingsService";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../lib/components/form/Form";
import { useIdentityIsProcessing } from "../../../lib/infrastructure/identity/hooks/useIdentityIsProcessing";
import { useIdentityUserDisplayName } from "../../../lib/infrastructure/identity/hooks/useIdentityUserDisplayName";
import { useIsAuthenticated } from "@azure/msal-react";

interface IAppMainLayoutProps {
  children: React.ReactNode;
}

interface Location {
  name: string;
  id: string;
  isDisabled?: boolean;
}

const labelSelector = (item: Location) => item.name;
const idSelector = (item: Location) => item.id;

function mapLocationResponseDTOtoLocation(values: UserLocation[]) {

  var optionsMapped = values.map((obj) => {
    var opMapped: Location = {
      id: obj.locationId + "",
      name: obj.locationName,

    };
    return opMapped;
  });
  return optionsMapped;
}

var locationsService = new LocationService();
var userSettingsSvc = new UserSettingsService();

export function AppMainLayout(props: IAppMainLayoutProps) {
  const openPopup = usePopup();
  const navigate = useNavigate();
  const routerLocation = useLocation();
  const isLogged = useIsAuthenticated();
  const windowResize = useWindowResize();
  const sideBarButtons = useSideBarButtons();
  const globalLocationID = useGlobalLocation();
  const isProcessing = useIdentityIsProcessing();
  const useIdentity = useIdentityUserDisplayName();
  const setGlobalLocation = useSetGlobalLocation();

  const [locations, setLocations] = useState<Location[]>([]);
  const [isLoadingNavbarLocations, setIsLoadingNavbarLocations] = useState<boolean>(true);
  const locationFormControl = useFormControl<Location>({});


  useEffect(() => {
    if (!isProcessing && !isLogged) {
      navigate("/identity/login");
      return;
    }
    getLocations();
  }, []);


  const getSelectedLocation = useCallback((allLocations: Location[]) => {
    locationsService.getSelectedLocation().then((response) => {

      var foundLocation = allLocations.find(ob => ob.id === `${response.locationId}`)

      if (response?.locationId && foundLocation) {
        locationFormControl.setValue({
          name: response.name,
          id: `${response.locationId}`,
          isDisabled: response.isDisabled
        });
      } else {
        locationFormControl.setValue(allLocations[0]);
      }
      setIsLoadingNavbarLocations(false);
    })
      .catch((error) => {
        setIsLoadingNavbarLocations(false);
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>)
      });
  },
    [locationFormControl.setValue, setGlobalLocation, openPopup, setIsLoadingNavbarLocations]
  );


  const handleOnClickLocation = useCallback(
    (value: Location | undefined) => {
      if (value) {
        navigate("/");
        locationFormControl.setValue(value);


        locationsService.selectLocation(value.id)
          .then((_) => {

          })
          .catch((error) => {
            if (!error) return;
            openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>)
          });
      }
    },
    [locationFormControl.setValue, navigate, openPopup]
  );


  const getLocations = useCallback(() => {
    userSettingsSvc.getUserLocations()
      .then((response) => {

        if (!response && !routerLocation.pathname.includes("settings"))
          navigate("/settings");

        if (!response) return;

        var mappedLocations = mapLocationResponseDTOtoLocation(response);
        setLocations(mappedLocations);
        getSelectedLocation(mappedLocations);
        setIsLoadingNavbarLocations(false);

      })
      .catch((error) => {

        setIsLoadingNavbarLocations(false);
        if (!error) return;
        openPopup(<ErrorPopup>{error?.response?.data?.message}</ErrorPopup>);
      });

  }, [navigate, openPopup, routerLocation.pathname, setLocations, getSelectedLocation]);


  useEffect(() => {
    if (locationFormControl.value?.id) {
      setGlobalLocation(parseInt(locationFormControl.value.id));
    }
  }, [locationFormControl.value]);


  const handleOnClickLanguage = useCallback((value: LanguageLocale) => {
    InternationalizationProvider.setCurrentLocale(value);
    LangChangedEvent.fireLanguageChanged();
  }, []);


  const languages: ContextualMenuItems[] = useMemo(() => [
    {
      label: "PT",
      icon: <IconPT />,
      navigateTo: "",
      onClick: () => {
        handleOnClickLanguage("pt-PT");
      },
    },
    {
      label: "EN",
      icon: <IconEN />,
      navigateTo: "",
      onClick: () => {
        handleOnClickLanguage("en-EN");
      },
    },
  ], [handleOnClickLanguage]);


  const accountInfo = useMemo(() => {
    return {
      info: {
        name: useIdentity.userDisplayName || "",
        email: useIdentity.userDisplayEmail || "",
      },
      menuItems: [
        {
          icon: <EndSessionSVG />,
          label: translate("COMMON.Logout"),
          navigateTo: "identity/logout",
        },
      ],
    };
  }, [useIdentity.userDisplayName, useIdentity.userDisplayEmail]);


  const renderNavBar = useMemo(() => {
    if (windowResize > 768)
      return (
        <NavBar
          additionalOptions={<>
            <MiniDropdown
              options={locations || []}
              labelSelector={labelSelector}
              idSelector={idSelector}
              hasError={locationFormControl.hasErrors}
              onChange={handleOnClickLocation}
              value={locationFormControl.value}
              maxHeightOptions={"16rem"}
            />
          </>
          }
          accountInfo={useIdentity.userDisplayName ? accountInfo : undefined}
          languages={languages}
        />
      );
    else
      return (
        <MobileNavBar buttons={sideBarButtons} accountInfo={accountInfo} />
      );
  }, [
    windowResize,
    locations,
    locationFormControl.hasErrors,
    locationFormControl.isDisabled,
    locationFormControl.value,
    locationFormControl.setValue,
    handleOnClickLocation,
    languages,
    accountInfo,
    useIdentity.userDisplayName,
    sideBarButtons,
  ]);


  if (!globalLocationID && !routerLocation.pathname.includes("settings") || isLoadingNavbarLocations) {
    return <SplashScreen />
  }


  return (
    <>
      {renderNavBar}
      <div className="content-wrapper">{props.children}</div>
    </>
  );
}
