import { useCallback, useEffect, useState } from "react";
import { useClosePopup, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { BusinessLine } from "../../../../admin/business-lines/models/domain/BusinessLine";
import { BusinessLinesQueryOptions } from "../../../../admin/business-lines/models/dtos/BusinessLinesQueryOptions";
import { BusinessLinesService } from "../../../../admin/business-lines/services/BusinessLinesService";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FormFieldSelectSingle } from "../../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../../lib/hooks/useServiceCall";

interface ISelectBusinessLinePopupProps {
    locationId: string | undefined;
    onCompletedOperations: (businessLineId: number) => void;
}

const labelBusinessLineSelector = (item: BusinessLine) => item.name;
const idBusinessLineSelector = (item: BusinessLine) => item.id;

var svc = new BusinessLinesService();

export function SelectBusinessLinePopup(props: ISelectBusinessLinePopupProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();

    const getBusinessLinesCall = useServiceCallPro(svc.getBusinessLines);

    const [businessLines, setBusinessLines] = useState<BusinessLine[]>([]);

    const businessLinesFormControl = useFormControl<BusinessLine>({});


    /****************************
    * DATA REQUESTS
    *****************************/

    const getActiveBusinessLines = useCallback(() => {
        if (!props.locationId) return;

        var queryOptions: BusinessLinesQueryOptions = {
            filterByIsActive: true
        };

        getBusinessLinesCall.invoke(props.locationId, queryOptions)
            .then((data) => {
                setBusinessLines(data?.businessLines || []);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationId, getBusinessLinesCall, openPopup]);


    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/

    useEffect(() => {
        getActiveBusinessLines();
    }, [])


    /****************************
    * USER ACTIONS
    *****************************/

    const handleSelectClicked = useCallback(() => {
        if (!AllValid(businessLinesFormControl.validate())) return;
        businessLinesFormControl.value && props.onCompletedOperations(businessLinesFormControl.value.id)
    }, [businessLinesFormControl.validate, businessLinesFormControl.value, props.onCompletedOperations]);


    return <PopupContainer className="popup-select-business-line">
        <PopupHeader title={translate("EMPLOYEES.CAREER.POPUPS.SelectBusinessLine")} />
        <PopupContent isLoading={getBusinessLinesCall.isLoading}>
            <FormFieldSelectSingle
                formControl={businessLinesFormControl}
                label={translate("EMPLOYEES.CAREER.INFOANDFORM.BusinessLines")}
                placeholder={translate("EMPLOYEES.CAREER.INFOANDFORM.BusinessLinesPlaceholder")}
                options={businessLines}
                labelSelector={labelBusinessLineSelector}
                idSelector={idBusinessLineSelector}
                maxHeightOptions={"16rem"}
            />
            <Spacer mode={"vertical"} px="30" />
            <PopupActionButtons
                buttons={[
                    {
                        text: translate("COMMON.Cancel"),
                        type: "tertiary",
                        onClick: () => closePopup(),
                    },
                    {
                        text: translate("COMMON.Select"),
                        type: "primary",
                        onClick: handleSelectClicked,
                    },
                ]}
            />
        </PopupContent>
    </PopupContainer>;
}