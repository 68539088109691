import { Optional, ValidatorFunction } from "../components/form/Form";

import { translate } from "../infrastructure/i18n/InternationalizationService";

export function validateRegex(regex: RegExp, allowNull: boolean, translation: string): ValidatorFunction<Optional<string>> {

    return (value: string | undefined) => {
        if (allowNull && !value)
            return null;
        if (!regex.test(value || "")) {
            return translate(translation);
        }
        return null;
    }
}

export function validateRegexOrEmpty(regex: RegExp, allowNull: boolean, translation: string): ValidatorFunction<Optional<string>> {

    return (value: string | undefined) => {
        if (allowNull && !value)
            return null;
        if (!translation && !regex.test(value || ""))
            return "EMPTY_MESSAGE";
        if (!regex.test(value || "")) {
            return translate(translation);
        }
        return null;
    }
}


/***************
 * Regex expressions
 **************/

export const regex7Characters = /^\S{7}$/;
export const regexSamplesBatch = /^[A-Za-z0-9-]{7}(\/[A-Za-z0-9-]{1,2})?$/;
export const regexOnlyPositiveIntegers = /^[0-9]\d*$/;
export const regexOnlyPositiveIntegersExcludingZeroAndAllowNull = /^(?:[1-9]\d*|)$/;
export const regexIntegerOrDecimals = /^(?!0+([.,]0+)?$)\d+([.,]\d+)?$/;

export const weigthPercentageExpression = /^(100|(\d{1,2}([.,]\d+)?))$/;

export const achievementPercentage = /^(100(\.00?)?|\d{1,2}(\.\d{1,2})?)$/;

export const weigthPercentageExpression2 = /^(100|[0-9][0-9]?|0)$/;

export const decimalsAndZero = /^\d+([.,]\d+)?$/;


