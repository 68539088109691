import "./SkillsCatEvaluationsCard.css"

import { useCallback, useEffect, useState } from "react";

import { ReactComponent as AddRoundIcon } from "../../../../lib/assets/icons/add-round-v2.svg";
import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { EvaluationInput } from "../../../matrices/components/EvaluationInput";
import { NextRoleDetailsDTO } from "../EvaluationDetailsPage";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { SkillsCategoryEvaluationDTO } from "../../models/SkillsCategoryEvaluationDTO";
import { SkillsEvaluationDTO } from "../../models/SkillsEvaluationDTO";
import { TextButton } from "../../../../lib/components/buttons/TextButton";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";

export interface IFieldWrapperProps {
    children: React.ReactNode;
    value: string | number | undefined;
    isLocked: boolean;
}

export function FieldWrapper(props: IFieldWrapperProps) {
    if (props.isLocked) {
        return <>{props.value}</>
    }
    return <>{props.children}</>
}
interface ISkillsCatEvaluationsCardProps {
    busineLineId?: string;
    isEditionModeEnabled: boolean;
    isNextRole: boolean;
    nextRoleDetails?: NextRoleDetailsDTO;
    skillsEvaluations: SkillsCategoryEvaluationDTO[];
    role: string | "";
    roleId: number | undefined;
    handleLevelInputChange: (skillLevels: SkillsCategoryEvaluationDTO[]) => void;
    handleSuggestNextRole: () => void;
}


export function SkillsCatEvaluationsCard(props: ISkillsCatEvaluationsCardProps) {

    const [skillsEvaluations, setSkillsEvaluations] = useState<SkillsCategoryEvaluationDTO[]>(props.skillsEvaluations);


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/
    useEffect(() => {
        setSkillsEvaluations(props.skillsEvaluations);
    }, [props.skillsEvaluations]);

    useEffect(() => {
        props.handleLevelInputChange(skillsEvaluations);
    }, [skillsEvaluations])


    /****************************
     * USER ACTIONS
     *****************************/
    const handleInputChange = useCallback((skillId: number, categoryId: number, value: string, isValid: boolean) => {
        const updatedSkillsEvaluations = skillsEvaluations.map(category => {
            if (category.id === categoryId) {
                return {
                    ...category,
                    skills: category.skills.map(skill => skill.id === skillId ? { ...skill, value, isValid } : skill)
                };
            }
            return category;
        });
        setSkillsEvaluations(updatedSkillsEvaluations);
    }, [skillsEvaluations]);


    /****************************
     * CSS & HTML
     *****************************/
    const getColumns = useCallback((showTextBtn: boolean, categoryId: number): ColumnDefinition<SkillsEvaluationDTO>[] => [
        {
            cellRenderProp: (skill) => skill.name,
            headerRender: translate('BUSINESSLINES.MATRIX.INFOANDFORM.Skill'),
            isMobilePrimaryCell: true,
        },
        {
            cellRenderProp: (skill) => {
                if (props.roleId && props.busineLineId) {
                    return (
                        <FieldWrapper value={skill.value} isLocked={!props.isEditionModeEnabled} key={skill.id}>
                            <EvaluationInput
                                skillId={skill.id}
                                roleId={props.roleId}
                                initialValue={skill?.value ?? ""}
                                onChange={(id, role, val, isValid) => handleInputChange(id, categoryId, val, isValid)}
                            />
                        </FieldWrapper>
                    );
                }
                else {
                    return skill.value || "-";
                }
            },
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Evaluation"),
            width: "100px"
        },
        {
            cellRenderProp: (skill) => props.busineLineId ? skill.refValue : null,
            headerRender: props.busineLineId ? props.role : <></>,
            width: props.busineLineId ? "100px" : "0px"
        },
        {
            cellRenderProp: (skill) => props.busineLineId ? <div className="next-role-column">{props.nextRoleDetails && props.nextRoleDetails.skills.find(level => level.skillId === skill.id)?.competenceLevel}</div> : null,
            headerRender: props.busineLineId ? (!props.isNextRole && props.isEditionModeEnabled && showTextBtn ? <TextButton
                text={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.SuggestNextRole")}
                type="primary"
                icon={<AddRoundIcon />} iconBefore
                onClick={props.handleSuggestNextRole}
                isDisabled={false}
            /> : <div className="next-role-column">{props.nextRoleDetails && props.nextRoleDetails.roleDescription}</div>) : <></>,
            width: props.busineLineId ? "12%" : "0%",
        },
    ], [props.busineLineId, props.role, props.roleId, handleInputChange, props.isNextRole, props.isEditionModeEnabled, props.handleSuggestNextRole, props.nextRoleDetails]);


    return <>
        {skillsEvaluations.map((category, index) => (
            <CardContainer key={category.id} className="skills-cat-evaluations-card">
                <div className="card-header">
                    <div className="subtitle">{category.name}</div>
                </div>
                <ResponsiveDataTable
                    columnDefinitions={getColumns(index === 0, category.id)}
                    items={category.skills}
                    totalitems={category.skills.length}
                />
            </CardContainer>
        ))}
    </>
}