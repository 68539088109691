import "./EmployeeBonusPage.css";

import { ColumnDefinition, PaginationOptions } from "../../../../lib/components/table/TableInterfaces";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";

import { EmployeeEvaluationPeriodSummaryDTO } from "../../models/dtos/EmployeeEvaluationPeriodSummaryDTO";
import { EmployeeEvaluationsService } from "../../services/EmployeeEvaluationsService";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { Permission } from "../../../../models/api/gate/Permission";
import { QueryOptions } from "../../../../models/api/QueryOptions";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { ReactComponent as SearchIcon } from "../../../../lib/assets/icons/search-icon.svg";
import { Tag } from "../../../../lib/components/tag/Tag";
import axios from "axios";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useDebounce } from "../../../../lib/hooks/useDebounce";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";

var svc = new EmployeeEvaluationsService();

export interface IOrderInfo {
    direction: "ASC" | "DESC" | "NONE";
    columnKey: string;
}


const topPermissions = [
    Permission.GLOBAL_ADMIN,
    Permission.LOCATION_ADMIN,
    Permission.HUMAN_RESOURCES
];


export function EmployeeBonusPage() {
    const { isLoadingPermissions, hasPermission } = useHasPermissions(topPermissions);
    const navigate = useNavigate();
    const windowResize = useWindowResize();
    const openPopup = usePopup();
    const locationID = useGlobalLocation();
    const locationIDAsString = useMemo(() => "" + locationID, [locationID])
    const { employeeId } = useParams();

    const [evaluationPeriods, setEvaluationPeriods] = useState<EmployeeEvaluationPeriodSummaryDTO[]>([]);
    const [totalItems, setTotalItems] = useState<number>();

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentpage, setCurrentPage] = useState(0);
    const [paginationOptions, setPaginationOptions] = useState<PaginationOptions>({ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 20, 30] });

    var searchFormControl = useFormControl<string>({
        isDisabled: false,
        enableAutoValidate: false,
    });

    const [searchWord, setSearchWord] = useState<string>();
    const debouncedSearchTerm = useDebounce(searchFormControl.value, 1000);

    const getEmployeeEvaluationPeriodsCall = useServiceCallPro(svc.getEmployeeEvaluationPeriods);


    /****************************
    * DATA REQUESTS
    *****************************/

    const getEmployeeEvaluationPeriods = useCallback((controller: AbortController | undefined) => {
        if (!locationIDAsString || !employeeId) return;

        var queryOpts: QueryOptions = {
            searchWord: searchWord,
            page: currentpage,
            pageLength: itemsPerPage
        };

        getEmployeeEvaluationPeriodsCall.invoke(locationIDAsString, employeeId, queryOpts, controller?.signal)
            .then((data) => {
                if (data) {
                    setEvaluationPeriods(data.items);
                    setTotalItems(data.totalItems);
                }
            })
            .catch((error) => {
                if (!error || axios.isCancel(error)) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, employeeId, getEmployeeEvaluationPeriodsCall.invoke, openPopup,
        setEvaluationPeriods, setTotalItems, searchWord, currentpage, itemsPerPage]);


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        const controller = new AbortController();
        getEmployeeEvaluationPeriods(controller);
        return () => { controller.abort() };
    }, [currentpage, itemsPerPage, searchWord]);

    useEffect(() => {
        setCurrentPage(0);
        setSearchWord(debouncedSearchTerm);
    }, [debouncedSearchTerm]);


    /****************************
     * USER ACTIONS
     *****************************/

    const handleOnPageAndItemsChanged = useCallback((items: number, currentPage: number) => {
        setItemsPerPage(items);
        setCurrentPage(currentPage);
    }, [setItemsPerPage, setCurrentPage]);


    const handleEvaluationPeriodDetailsClicked = useCallback((period: EmployeeEvaluationPeriodSummaryDTO) => {
        navigate(`${period.id}/details`);
    }, [navigate]);


    /****************************
     * CSS & HTML
     *****************************/

    const columns: ColumnDefinition<EmployeeEvaluationPeriodSummaryDTO>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => v.name,
                headerRender: translate("EMPLOYEES.BONUS.INFOANDFORM.EvaluationPeriod"),
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v) => v.employeeRole,
                headerRender: translate("EMPLOYEES.BONUS.INFOANDFORM.Role"),
            },
            {
                cellRenderProp: (v) => <Tag
                    text={"" + v.evaluationTotal}
                    isTiny={windowResize < 1024}
                />,
                headerRender: translate("EMPLOYEES.BONUS.INFOANDFORM.Evaluation"),
                width: "110px"
            },
        ], [windowResize]);


    return (
        <PageLayout
            tabTitle={translate("EMPLOYEES.BONUS.Bonus")}
            pageTitle={translate("EMPLOYEES.BONUS.Bonus")}
            className="employee-bonus"
            showBreadcrumb
        >
            <FormFieldTextInput
                formControl={searchFormControl}
                icon={<SearchIcon />}
                placeholder={translate("COMMON.FORM.SearchPlaceholder")}
                className="search-box"
            />

            <ResponsiveDataTable
                columnDefinitions={columns}
                items={evaluationPeriods || []}
                totalitems={totalItems || 0}
                paginationOptions={paginationOptions}
                currentpage={currentpage}
                isLoading={getEmployeeEvaluationPeriodsCall.isLoading}
                onPageAndItemsChanged={handleOnPageAndItemsChanged}
                onClickRow={handleEvaluationPeriodDetailsClicked}
            />
        </PageLayout>

    );
}
