import "./EvaluationDetailsPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useCreateNotification, usePopup } from "../../../lib/infrastructure/ui/UIServices";

import { BonusEvaluationCard } from "./components/BonusEvaluationCard";
import { CertificatesEvaluationCard } from "./components/CertificatesEvaluationCard";
import { ReactComponent as CheckIcon } from "../../../lib/assets/icons/check-btn.svg";
import { ChecklistEvaluationCard } from "./components/ChecklistEvaluationCard";
import { EmployeeEvaluationsService } from "../../employees/services/EmployeeEvaluationsService";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { EvaluationChecklistDTO } from "../models/EvaluationChecklistDTO";
import { EvaluationDetailsDTO } from "../models/EvaluationDetailsDTO";
import { EvaluationDetailsUpdateDTO } from "../models/EvaluationDetailsUpdateDTO";
import { EvaluationFollowUpItemDTO } from "../models/EvaluationFollowUpItemDTO";
import { EvaluationFollowupItemsUpdateDTO } from "../models/EvaluationFollowupItemsUpdateDTO";
import { EvaluationStatus } from "../models/EvaluationStatus";
import { EvaluationsService } from "../services/EvaluationsService";
import { FollowUpEvaluationCard } from "./components/FollowUpEvaluationCard";
import { FormFieldTextArea } from "../../../lib/components/form/form-field/FormFieldTextArea";
import { FormSection } from "../../../lib/components/form/section/FormSection";
import { InfoBlock } from "../../../lib/components/info-block/InfoBlock";
import { Notification } from "../../../lib/components/notifications/Notification";
import { ObjectHelper } from "../../../lib/helpers/ObjectHelper";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { PagePermissions } from "../../../lib/infrastructure/authorization/PagePermissions";
import { Permission } from "../../../models/api/gate/Permission";
import { ReactComponent as RejectIcon } from "../../../lib/assets/icons/reject.svg";
import { ResponsiveButton } from "../../../lib/components/buttons/ResponsiveButton";
import { ReactComponent as RevertIcon } from "../../../lib/assets/icons/revert.svg";
import { ReactComponent as SaveIcon } from "../../../lib/assets/icons/save.svg";
import { SkillsCatEvaluationsCard } from "./components/SkillsCatEvaluationsCard";
import { SkillsCategoryEvaluationDTO } from "../models/SkillsCategoryEvaluationDTO";
import { StatusCard } from "../../../lib/components/cards/stats-card/StatusCard";
import { ReactComponent as SuggestNextRoleIcon } from "../../../lib/assets/icons/career-ladder.svg";
import { SuggestNextRolePopup } from "./popups/SuggestNextRolePopup";
import { TitledCardContainer } from "../../../lib/layouts/containers/card/TitledCardContainer";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useParams } from "react-router-dom";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";
import { validateWhiteSpaceStrings } from "../../../lib/validators/ValidateWhiteSpaceStrings";

export interface NextRoleSkillLevelsDTO {
    skillId: number;
    competenceLevel: string;
}
export interface NextRoleDetailsDTO {
    roleId: number;
    roleDescription: string;
    skills: NextRoleSkillLevelsDTO[];
}

var svc = new EvaluationsService();
var employeeEvaluationsSvc = new EmployeeEvaluationsService();

const basePermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES, Permission.LOCATION_MANAGER];
const topPermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES];

export function EvaluationDetailsPage() {

    const openPopup = usePopup();
    const locationID = useGlobalLocation();
    const locationIDAsString = useMemo(() => "" + locationID, [locationID])
    const { id: businessLineID, periodId: evaluationPeriodID, matrixId, evaluationId, employeeId } = useParams();
    const createNotification = useCreateNotification();

    const { isLoadingPermissions: loadingTopPermissions, hasPermission: hasTopPermissions } = useHasPermissions(topPermissions);

    const getEvaluationDetailsByIdCall = useServiceCallPro(svc.getEvaluationDetailsById);
    const getEvaluationFollowUpItemsCall = useServiceCallPro(svc.getEvaluationFollowUpItems);
    const getUnjustifiedAbsencesCall = useServiceCallPro(svc.getUnjustifiedAbsences);
    const updateEvaluationDetailsCall = useServiceCallPro(svc.updateEvaluationDetails);
    const updateEvaluationFollowUpItemsCall = useServiceCallPro(svc.updateEvaluationFollowUpItems);
    const getNextRoleDetailsCall = useServiceCallPro(svc.getNextRoleDetails);

    const getEmployeeEvaluationDetailsByIdCall = useServiceCallPro(employeeEvaluationsSvc.getEmployeeEvaluationDetailsById);
    const getEmployeeEvaluationFollowUpItemsCall = useServiceCallPro(employeeEvaluationsSvc.getEmployeeEvaluationFollowUpItems);

    const [evaluationDetails, setEvaluationDetails] = useState<EvaluationDetailsDTO>();
    const [initialEvaluationDetails, setInitialEvaluationDetails] = useState<EvaluationDetailsDTO>();
    const [evaluationTotal, setEvaluationTotal] = useState<number>(evaluationDetails?.evaluationTotal || 0);
    const [nextRoleTotal, setNextRoleTotal] = useState<number>(0);
    const [unjustifiedAbsences, setUnjustifiedAbsences] = useState<string>();
    const [checklistSumValue, setChecklistSumValue] = useState<string>();
    const [followUpItems, setFollowUpItems] = useState<EvaluationFollowUpItemDTO[]>([]);
    const [initialFollowUpItems, setInitialFollowUpItems] = useState<EvaluationFollowUpItemDTO[]>();
    const [isEditionModeEnabled, setIsEditionModeEnabled] = useState<boolean>(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
    const [isNextRole, setIsNextRole] = useState<boolean>(false);
    const [nextRoleDetails, setNextRoleDetails] = useState<NextRoleDetailsDTO>();

    const feedbackManagerEmployeeFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
        isDisabled: true,
    });

    const feedbackEmployeeManagerFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
        isDisabled: true,
    });


    /****************************
    * DATA REQUESTS
    *****************************/

    const getEvaluationDetailsById = useCallback(() => {
        if (!locationIDAsString || !businessLineID || !evaluationPeriodID || !matrixId || !evaluationId) return;

        getEvaluationDetailsByIdCall.invoke(locationIDAsString, businessLineID, evaluationPeriodID, matrixId, evaluationId)
            .then((data) => {
                if (data) {
                    setEvaluationDetails(data);
                    setInitialEvaluationDetails(data);
                    feedbackManagerEmployeeFormControl.setValue(data.feedbackManagerEmployee)
                    feedbackEmployeeManagerFormControl.setValue(data.feedbackEmployeeManager)
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, businessLineID, evaluationPeriodID, matrixId, evaluationId, getEvaluationDetailsByIdCall.invoke, openPopup, feedbackEmployeeManagerFormControl.setValue, feedbackManagerEmployeeFormControl.setValue]);


    const getUnjustifiedAbsences = useCallback(() => {
        if (!locationIDAsString || !businessLineID || !evaluationPeriodID || !matrixId || !evaluationId) return;

        getUnjustifiedAbsencesCall.invoke(locationIDAsString, businessLineID, evaluationPeriodID, matrixId, evaluationId)
            .then((res) => {
                if (!res) return;
                setUnjustifiedAbsences(res);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, businessLineID, evaluationPeriodID, matrixId, evaluationId, getUnjustifiedAbsencesCall.invoke, openPopup]);


    const getEvaluationFollowUpItems = useCallback(() => {
        if (!locationIDAsString || !businessLineID || !evaluationPeriodID || !matrixId || !evaluationId) return;

        getEvaluationFollowUpItemsCall.invoke(locationIDAsString, businessLineID, evaluationPeriodID, matrixId, evaluationId)
            .then((res) => {
                if (!res) return;
                setFollowUpItems(res.items);
                setInitialFollowUpItems(res.items);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, businessLineID, evaluationPeriodID, matrixId, evaluationId, getEvaluationFollowUpItemsCall.invoke, openPopup]);

    const getNextRoleDetails = useCallback((nextRoleId: number) => {
        if (!locationIDAsString || !businessLineID || !matrixId || !matrixId || !nextRoleId) return;

        getNextRoleDetailsCall.invoke(locationIDAsString, businessLineID, matrixId, "" + nextRoleId)
            .then((res) => {
                if (!res) return;
                setNextRoleDetails(res);
                const total = res.skills.map(skill => parseInt(skill.competenceLevel)).reduce((sum, level) => sum + level, 0);
                setNextRoleTotal(total)
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, businessLineID, matrixId, getEvaluationFollowUpItemsCall.invoke, openPopup]);


    const getEmployeeEvaluationDetailsById = useCallback(() => {
        if (!locationIDAsString || !employeeId || !evaluationId) return;

        getEmployeeEvaluationDetailsByIdCall.invoke(locationIDAsString, employeeId, evaluationId)
            .then((data) => {
                if (data) {
                    setEvaluationDetails(data);
                    setInitialEvaluationDetails(data);
                    feedbackManagerEmployeeFormControl.setValue(data.feedbackManagerEmployee)
                    feedbackEmployeeManagerFormControl.setValue(data.feedbackEmployeeManager)
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, employeeId, evaluationId, getEmployeeEvaluationDetailsByIdCall.invoke, openPopup, feedbackEmployeeManagerFormControl.setValue, feedbackManagerEmployeeFormControl.setValue]);


    const getEmployeeEvaluationFollowUpItems = useCallback(() => {
        if (!locationIDAsString || !employeeId || !evaluationId) return;

        getEmployeeEvaluationFollowUpItemsCall.invoke(locationIDAsString, employeeId, evaluationId)
            .then((res) => {
                if (!res) return;
                setFollowUpItems(res.items);
                setInitialFollowUpItems(res.items);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, employeeId, evaluationId, getEmployeeEvaluationFollowUpItemsCall.invoke, openPopup]);


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        if (businessLineID) {
            getEvaluationDetailsById();
            getUnjustifiedAbsences();
            getEvaluationFollowUpItems();
        }
        else {
            getEmployeeEvaluationDetailsById();
            getEmployeeEvaluationFollowUpItems();
        }
    }, [businessLineID]);


    useEffect(() => {
        if (evaluationDetails && evaluationDetails.checklistItems) {
            setChecklistSumValue(`${evaluationDetails.checklistItems.filter(item => item.isChecked).length}/${evaluationDetails.checklistItems.length}`);
        }
    }, [evaluationDetails?.checklistItems]);


    useEffect(() => {
        if (evaluationDetails && feedbackManagerEmployeeFormControl.value)
            setEvaluationDetails({
                ...evaluationDetails,
                feedbackManagerEmployee: feedbackManagerEmployeeFormControl.value
            });
    }, [feedbackManagerEmployeeFormControl.value]);


    useEffect(() => {
        if (evaluationDetails && feedbackEmployeeManagerFormControl.value)
            setEvaluationDetails({
                ...evaluationDetails,
                feedbackEmployeeManager: feedbackEmployeeManagerFormControl.value
            });
    }, [feedbackEmployeeManagerFormControl.value]);


    useEffect(() => {
        if (evaluationDetails || initialEvaluationDetails) {
            setHasUnsavedChanges(!ObjectHelper.deepEqual(evaluationDetails, initialEvaluationDetails));
        }
    }, [evaluationDetails, initialEvaluationDetails]);


    useEffect(() => {
        if (followUpItems || initialFollowUpItems) {
            setHasUnsavedChanges(!ObjectHelper.deepEqual(followUpItems, initialFollowUpItems));
        }
    }, [followUpItems, initialFollowUpItems]);


    useEffect(() => {
        if (!evaluationDetails || !businessLineID) return;
        if (evaluationDetails.evaluationStatus === EvaluationStatus.READY_TO_EVALUATE || evaluationDetails.evaluationStatus === EvaluationStatus.IN_PROGRESS || evaluationDetails.evaluationStatus === EvaluationStatus.REJECTED) {
            setIsEditionModeEnabled(true);
            feedbackEmployeeManagerFormControl.setIsDisabled(false);
            feedbackManagerEmployeeFormControl.setIsDisabled(false);
        }
    }, [evaluationDetails?.evaluationStatus, businessLineID]);


    /****************************
     * USER ACTIONS
     *****************************/

    const handleLevelInputChange = useCallback((skillsEvaluations: SkillsCategoryEvaluationDTO[]) => {
        const total = skillsEvaluations.reduce((total, category) => {
            const categoryTotal = category.skills.reduce((catTotal, skill) => {
                const skillValue = parseFloat(skill.value) || 0;
                return catTotal + skillValue;
            }, 0);
            return total + categoryTotal;
        }, 0);

        setEvaluationTotal(total);

        if (evaluationDetails) {
            setEvaluationDetails({
                ...evaluationDetails,
                skillsCategories: skillsEvaluations,
                evaluationTotal: total,
            });
        }
    }, [evaluationDetails]);


    const updateChecklistItems = useCallback((checklist: EvaluationChecklistDTO[]) => {
        if (!evaluationDetails) return;
        setEvaluationDetails({ ...evaluationDetails, checklistItems: checklist });
    }, [evaluationDetails]);


    const updateFollowUpItems = useCallback((newFollowUpItems: EvaluationFollowUpItemDTO[]) => {
        setFollowUpItems(newFollowUpItems)
    }, []);


    const handleSave = useCallback(() => {
        if (!locationIDAsString || !businessLineID || !evaluationPeriodID || !matrixId || !evaluationId || !evaluationDetails || !followUpItems) return;

        if (evaluationDetails.skillsCategories.some(category => category.skills.some(skill => skill.isValid === false))) {
            openPopup(<ErrorPopup>{translate("BUSINESSLINES.EVALUATIONS.ERRORS.InputFieldInvalid")}</ErrorPopup>);
            return;
        }

        let requestDetailsDto: EvaluationDetailsUpdateDTO = {
            evaluationTotal: evaluationDetails.evaluationTotal,
            skillsCategories: evaluationDetails.skillsCategories,
            checklistItems: evaluationDetails.checklistItems,
            feedbackManagerEmployee: evaluationDetails.feedbackManagerEmployee,
            feedbackEmployeeManager: evaluationDetails.feedbackEmployeeManager,
            nextRoleId: nextRoleDetails?.roleId,
        };

        updateEvaluationDetailsCall.invoke(locationIDAsString, businessLineID, evaluationPeriodID, matrixId, evaluationId, requestDetailsDto)
            .then(() => {
                let requestFollowUpItemsDto: EvaluationFollowupItemsUpdateDTO = {
                    items: followUpItems
                };

                updateEvaluationFollowUpItemsCall.invoke(locationIDAsString, businessLineID, evaluationPeriodID, matrixId, evaluationId, requestFollowUpItemsDto)
                    .then(() => {
                        createNotification(
                            <Notification
                                type="success"
                                title={translate("COMMON.SYSTEMPOPUPS.Success")}
                                text={translate("BUSINESSLINES.EVALUATIONS.NOTIFICATIONS.UpdateEvaluationDetailsSuccess")}
                            />
                        );
                    })
                    .catch((error) => {
                        if (!error) return;
                        openPopup(<ErrorPopup>{translate("BUSINESSLINES.EVALUATIONS.ERRORS.ErrorSavingFollowUpItems")}</ErrorPopup>);
                    })
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{translate("BUSINESSLINES.EVALUATIONS.ERRORS.ErrorSavingEvaluationDetails")}</ErrorPopup>);
            })
    }, [locationIDAsString, businessLineID, evaluationPeriodID, matrixId, evaluationId, evaluationDetails, followUpItems, openPopup, createNotification, updateEvaluationDetailsCall.invoke, updateEvaluationFollowUpItemsCall.invoke, nextRoleDetails?.roleId]);


    const handleNextRoleSelected = useCallback((nextRoleId: number) => {
        if (!evaluationDetails) return;
        setEvaluationDetails({ ...evaluationDetails, nextRoleID: nextRoleId });
        getNextRoleDetails(nextRoleId)
        setIsNextRole(true);
    }, [evaluationDetails, getNextRoleDetails]);


    const handleSuggestNextRole = useCallback(() => {
        if (!evaluationDetails) return;
        openPopup(<SuggestNextRolePopup locationId={locationIDAsString} businessLineId={"" + businessLineID} currentRoleId={evaluationDetails.employeeRoleId} onCompletedOperations={handleNextRoleSelected} />)
    }, [locationIDAsString, businessLineID, evaluationDetails, openPopup, handleNextRoleSelected]);


    /****************************
     * CSS & HTML
     *****************************/

    const renderPageActionsBtns = useMemo(() => {
        if (!evaluationDetails?.evaluationStatus || !businessLineID) return;
        if (loadingTopPermissions) return;

        const saveBtn: React.ReactNode = <ResponsiveButton type="secondary" icon={<SaveIcon />} text={translate("COMMON.Save")} onClick={handleSave} />;
        const suggestNextRoleBtn: React.ReactNode = <ResponsiveButton type="secondary" icon={<SuggestNextRoleIcon />} text={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.SuggestNextRole")} onClick={handleSuggestNextRole} />;

        switch (evaluationDetails.evaluationStatus) {
            case EvaluationStatus.READY_TO_EVALUATE:
                return <>
                    {suggestNextRoleBtn}
                    {hasUnsavedChanges && saveBtn}
                </>;
            case EvaluationStatus.IN_PROGRESS:
            case EvaluationStatus.REJECTED:
                return <>
                    {suggestNextRoleBtn}
                    {hasUnsavedChanges && saveBtn}
                    <ResponsiveButton type="primary" icon={<CheckIcon />} text={translate("COMMON.Confirm")} onClick={() => { }} />
                </>;
            case EvaluationStatus.CONFIRMED:
                return <>
                    <ResponsiveButton type="tertiary" icon={<RevertIcon />} text={translate("COMMON.Revert")} onClick={() => { }} />
                    {hasTopPermissions || evaluationDetails.canReject ? <ResponsiveButton className="reject-btn" type="primary" icon={<RejectIcon />} text={translate("COMMON.Reject")} onClick={() => { }} /> : null}
                    {hasTopPermissions || evaluationDetails.canCalibrate ? <ResponsiveButton type="primary" icon={<CheckIcon />} text={translate("COMMON.Calibrate")} onClick={() => { }} /> : null}
                </>;
            case EvaluationStatus.CALIBRATED:
                return <>
                    {hasTopPermissions || evaluationDetails.canCalibrate ? <ResponsiveButton type="tertiary" icon={<RevertIcon />} text={translate("COMMON.Revert")} onClick={() => { }} /> : null}
                    {hasTopPermissions ? <>
                        <ResponsiveButton className="reject-btn" type="primary" icon={<RejectIcon />} text={translate("COMMON.Reject")} onClick={() => { }} />
                        <ResponsiveButton type="primary" icon={<CheckIcon />} text={translate("COMMON.Conclude")} onClick={() => { }} />
                    </> : null}
                </>;
            case EvaluationStatus.CONCLUDED:
                return hasTopPermissions ? <ResponsiveButton type="tertiary" icon={<RevertIcon />} text={translate("COMMON.Revert")} onClick={() => { }} /> : null;
            default:
                return null;
        }
    }, [evaluationDetails?.evaluationStatus, evaluationDetails?.canCalibrate, evaluationDetails?.canReject, loadingTopPermissions, hasTopPermissions, hasUnsavedChanges, handleSave, handleSuggestNextRole])


    const renderNextRoleStatusCard = useMemo(() => {
        if (isNextRole) {
            return <StatusCard isLoading={getNextRoleDetailsCall.isLoading} title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.TotalNextRole")} value={"" + nextRoleTotal} backgroundColor="status-green" />;
        }
    }, [isNextRole, nextRoleTotal, getNextRoleDetailsCall.isLoading]);


    const renderAdditionalInfoCards = useMemo(() => {
        if (businessLineID) {
            return <div className="additional-info">
                <BonusEvaluationCard locationId={locationIDAsString} businessLineId={"" + businessLineID} periodId={"" + evaluationPeriodID} matrixId={"" + matrixId} evaluationId={"" + evaluationId} />
                <StatusCard isLoading={getUnjustifiedAbsencesCall.isLoading} title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.UnjustifiedAbsences")} value={unjustifiedAbsences ?? "-"} backgroundColor="status-dark-grey" className="absences-status-card" />
                <CertificatesEvaluationCard locationId={locationIDAsString} businessLineId={"" + businessLineID} periodId={"" + evaluationPeriodID} matrixId={"" + matrixId} evaluationId={"" + evaluationId} />
            </div>
        }
    }, [locationIDAsString, businessLineID, matrixId, evaluationId, evaluationPeriodID, getUnjustifiedAbsencesCall.isLoading, unjustifiedAbsences]);


    const renderChecklistCard = useMemo(() => {
        if (evaluationDetails && evaluationDetails.checklistItems.length) {
            return <ChecklistEvaluationCard isEditionModeEnabled={isEditionModeEnabled} items={evaluationDetails?.checklistItems || []} isLoading={getEvaluationDetailsByIdCall.isLoading} onChange={updateChecklistItems} />;
        }
    }, [evaluationDetails, getEvaluationDetailsByIdCall.isLoading, updateChecklistItems, isEditionModeEnabled]);


    return (
        <PagePermissions permissions={basePermissions} >
            <PageLayout
                tabTitle={translate("BUSINESSLINES.EVALUATIONS.Evaluation")}
                showBreadcrumb
                pageTitle={translate("BUSINESSLINES.EVALUATIONS.Evaluation")}
                className="evaluation-details"
                actions={renderPageActionsBtns}
            >
                <div className="evaluation-details-summary">
                    <TitledCardContainer className="employee-data" title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.EmployeeData")} isLoading={getEvaluationDetailsByIdCall.isLoading}>
                        <FormSection unsetFlexGrow>
                            <InfoBlock label={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Name")}
                                value={evaluationDetails ? evaluationDetails.employee.userDisplayName : "-"} />
                            <InfoBlock label={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Role")}
                                value={evaluationDetails ? evaluationDetails.employeeRoleDescription : "-"} />
                        </FormSection>
                    </TitledCardContainer>
                    <div className={isNextRole ? "evaluation-details-summary-cards-updated" : "evaluation-details-summary-cards"}>
                        <StatusCard isLoading={getEvaluationDetailsByIdCall.isLoading} title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Value")} value={evaluationTotal.toString()} backgroundColor="status-dark-grey" />
                        <StatusCard isLoading={getEvaluationDetailsByIdCall.isLoading} title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Checklist")} value={checklistSumValue ?? "-"} backgroundColor="status-dark-grey" />
                        {renderNextRoleStatusCard}
                    </div>
                </div>
                <div className="skills-categories">
                    <SkillsCatEvaluationsCard busineLineId={businessLineID} isEditionModeEnabled={isEditionModeEnabled} isNextRole={isNextRole} nextRoleDetails={nextRoleDetails} skillsEvaluations={evaluationDetails?.skillsCategories || []} role={evaluationDetails?.employeeRoleDescription || ""} roleId={evaluationDetails?.employeeRoleId} handleLevelInputChange={handleLevelInputChange} handleSuggestNextRole={handleSuggestNextRole} />
                </div>
                {renderAdditionalInfoCards}
                {renderChecklistCard}
                <div className="feedback-cards">
                    <TitledCardContainer title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FeedbackManagerEmployee")}>
                        <FormFieldTextArea
                            formControl={feedbackManagerEmployeeFormControl}
                            label={translate("BUSINESSLINES.MATRIX.INFOANDFORM.FeedbackManagerEmployee")}
                            alternativeStyle
                        />
                    </TitledCardContainer>
                    <TitledCardContainer title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FeedbackManagerEmployee")}>
                        <FormFieldTextArea
                            formControl={feedbackEmployeeManagerFormControl}
                            label={translate("BUSINESSLINES.MATRIX.INFOANDFORM.FeedbackEmployeeManager")}
                            alternativeStyle
                        />
                    </TitledCardContainer>
                </div>
                <FollowUpEvaluationCard items={followUpItems} isLoading={getEvaluationFollowUpItemsCall.isLoading} isEditionModeEnabled={isEditionModeEnabled} onChange={updateFollowUpItems} />
            </PageLayout >
        </PagePermissions>
    );
}

