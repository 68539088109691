import "./EmployeesPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { EmployeeDetailsDTO } from "../models/dtos/EmployeeDetailsDTO";
import { EmployeesService } from "../services/EmployeesService";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { Permission } from "../../../models/api/gate/Permission";
import { QueryOptions } from "../../../models/api/QueryOptions";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { ReactComponent as SearchIcon } from "../../../lib/assets/icons/search-icon.svg";
import { Tag } from "../../../lib/components/tag/Tag";
import axios from "axios";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { usePopup } from "../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";

var svc = new EmployeesService();

export interface IOrderInfo {
    direction: "ASC" | "DESC" | "NONE";
    columnKey: string;
}

const topPermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES];

export function EmployeesPage() {
    const { isLoadingPermissions, hasPermission } = useHasPermissions(topPermissions);
    const locationID = useGlobalLocation();
    const navigate = useNavigate();
    const openPopup = usePopup();

    const [employees, setEmployees] = useState<EmployeeDetailsDTO[]>();
    const [totalItems, setTotalItems] = useState<number>();

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentpage, setCurrentPage] = useState(0);

    const [timer, setTimer] = useState<NodeJS.Timeout>();
    const [searchWord, setSearchWord] = useState<string>();

    const getEmployeesCall = useServiceCallPro(svc.getEmployees);

    var searchboxController = useFormControl<string>({
        isDisabled: false,
        enableAutoValidate: false,
    });


    /****************************
    * DATA REQUESTS
    *****************************/

    const getEmployees = useCallback((controller: AbortController | undefined) => {
        if (!locationID) return;

        var queryOpts: QueryOptions = {
            searchWord: searchWord,
            page: currentpage,
            pageLength: itemsPerPage
        };

        getEmployeesCall.invoke("" + locationID, queryOpts, controller?.signal)
            .then((data) => {
                if (data) {
                    setEmployees(data.items);
                    setTotalItems(data.totalItems)
                }
            })
            .catch((error) => {
                if (!error || axios.isCancel(error)) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationID, getEmployeesCall.invoke, openPopup, currentpage, itemsPerPage, searchWord]);


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        const controller = new AbortController();
        getEmployees(controller);
        return () => { controller.abort() };
    }, [currentpage, itemsPerPage, searchWord]);

    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }

        var responseTimeOut = setTimeout(() => {
            if (timer)
                setSearchWord(searchboxController.value);
            setCurrentPage(0);
        }, 1000);

        setTimer(responseTimeOut);
    }, [searchboxController.value]);


    /****************************
     * USER ACTIONS
     *****************************/

    const handleOnPageAndItemsChanged = useCallback(
        (items: number, currentPage: number) => {
            setItemsPerPage(items);
            setCurrentPage(currentPage);
        },
        [setItemsPerPage, setCurrentPage]
    );


    /****************************
     * CSS & HTML
     *****************************/

    const columns: ColumnDefinition<EmployeeDetailsDTO>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => v.employeeInfo.userDisplayName,
                headerRender: translate("EMPLOYEES.INFOANDFORM.Name"),
                isMobilePrimaryCell: true,
                width: "25%",
            },
            {
                cellRenderProp: (v) => v.roleDescription,
                headerRender: translate("EMPLOYEES.INFOANDFORM.Role"),
                width: "65%",
            },
            {
                cellRenderProp: (v) => <Tag text={"" + v.evaluationTotal} />,
                headerRender: translate("EMPLOYEES.INFOANDFORM.Evaluation"),
                width: "10%",
            }
        ], []);


    return <PageLayout
        tabTitle={translate("EMPLOYEES.Employees")}
        pageTitle={translate("EMPLOYEES.Employees")}
        className="employees-page"
        showBreadcrumb
    >
        <FormFieldTextInput
            formControl={searchboxController}
            icon={<SearchIcon />}
            placeholder={translate("COMMON.FORM.SearchPlaceholder")}
            className="search-box"
        />
        <ResponsiveDataTable
            columnDefinitions={columns}
            items={employees || []}
            totalitems={totalItems || 0}
            paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 20, 30] }}
            currentpage={currentpage}
            isLoading={getEmployeesCall.isLoading}
            onPageAndItemsChanged={handleOnPageAndItemsChanged}
            onClickRow={(employee) => navigate(`${employee.employeeInfo.userId}`)}
        />
    </PageLayout>;
}