import "./ChecklistEvaluationCard.css";

import { useCallback, useEffect, useMemo, useState } from "react";

import { Checkbox } from "../../../../lib/components/checkbox/Checkbox";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { EvaluationChecklistDTO } from "../../models/EvaluationChecklistDTO";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { TitledCardContainer } from "../../../../lib/layouts/containers/card/TitledCardContainer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";

interface IChecklistEvaluationCardProps {
    isEditionModeEnabled: boolean;
    items: EvaluationChecklistDTO[];
    isLoading: boolean;
    onChange: (checklist: EvaluationChecklistDTO[]) => void;
}

export function ChecklistEvaluationCard(props: IChecklistEvaluationCardProps) {
    const [checklist, setChecklist] = useState<EvaluationChecklistDTO[]>([]);


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/
    useEffect(() => {
        setChecklist(props.items);
    }, [props.items])

    useEffect(() => {
        if (checklist.length)
            props.onChange(checklist)
    }, [checklist])


    /****************************
     * USER ACTIONS
     *****************************/
    const handleCheckboxChange = useCallback((isChecked: boolean, id: number) => {
        setChecklist(checklist.map((item) => item.id === id ? { ...item, isChecked } : item));
    }, [checklist])


    /****************************
     * CSS & HTML
     *****************************/
    const checklistColumns: ColumnDefinition<EvaluationChecklistDTO>[] = useMemo(() => [
        {
            cellRenderProp: (item) => item.description,
            headerRender: translate("BUSINESSLINES.MATRIX.INFOANDFORM.Description"),
        },
        {
            cellRenderProp: (item) => <Checkbox
                key={item.id}
                text={""}
                isChecked={item.isChecked}
                onClick={(isChecked) => handleCheckboxChange(isChecked, item.id)}
                isDisabled={!props.isEditionModeEnabled}
            />,
            columnKey: "delete",
            width: "24px",
            isMobileHeaderIcon: true,
        },
    ], [handleCheckboxChange, props.isEditionModeEnabled]);


    return <TitledCardContainer className="checklist-evaluation-card" title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Checklist")}>
        <ResponsiveDataTable
            columnDefinitions={checklistColumns}
            items={checklist}
            totalitems={checklist.length}
            isLoading={props.isLoading}
        />
    </TitledCardContainer>;
}