import "./BusinessLinesContainer.css";

import { useEffect, useMemo } from "react";

import { BusinessLinesService } from "../../../features/admin/business-lines/services/BusinessLinesService";
import { ReactComponent as CareerLadderIcon } from "../../../lib/assets/icons/career-ladder.svg";
import { ReactComponent as EvaluationPeriodsIcon } from "../../../lib/assets/icons/evaluations.svg";
import { ReactComponent as MatrixIcon } from "../../../lib/assets/icons/matrix.svg";
import { MobileSubSidebar } from "../../../lib/components/side-bar/MobileSubSidebar";
import { Permission } from "../../../models/api/gate/Permission";
import { ReactComponent as RolesIcon } from "../../../lib/assets/icons/roles.svg";
import { SubSidebar } from "../../../lib/components/side-bar/SubSidebar";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useParams } from "react-router-dom";
import { useSetGlobalBusinessLine } from "../../../lib/infrastructure/business-line/BusinessLineServices";
import { useUpdatePageData } from "../../../lib/infrastructure/navigation/hooks/useUpdatePageData";
import { useWindowResize } from "../../../lib/infrastructure/ui/UIServices";

interface IBusinessLinesContainerProps {
    children: React.ReactNode;
}

export const humanResourcesPermissions = [
    Permission.LOCATION_ADMIN,
    Permission.HUMAN_RESOURCES
];

export const adminPermissions = [
    Permission.GLOBAL_ADMIN,
    Permission.LOCATION_ADMIN,
    Permission.HUMAN_RESOURCES
];

export const basePermissions = [
    Permission.GLOBAL_ADMIN,
    Permission.LOCATION_ADMIN,
    Permission.HUMAN_RESOURCES,
    Permission.LOCATION_MANAGER
];

var businessLinesSvc = new BusinessLinesService();

export function BusinessLinesContainer(props: IBusinessLinesContainerProps) {

    const { isLoadingPermissions: loadingBasePermissions, hasPermission: hasBasePermissions } = useHasPermissions(basePermissions);
    const { isLoadingPermissions: loadingAdminPermissions, hasPermission: hasAdminPermissions } = useHasPermissions(adminPermissions);

    const windowResize = useWindowResize();


    const { id: businessLineID } = useParams();
    const locationID = useGlobalLocation();
    const setBusinessLineDetails = useSetGlobalBusinessLine();


    const updateRouteDate = useUpdatePageData();



    const subSideBarButtons = useMemo(() => [
        {
            icon: <EvaluationPeriodsIcon />,
            navigateTo: "periods",
            name: translate("BUSINESSLINES.EVALUATIONPERIODS.EvaluationPeriods"),
            isHidden: loadingBasePermissions || !hasBasePermissions
        },
        {
            icon: <MatrixIcon />,
            navigateTo: "matrix",
            name: translate("BUSINESSLINES.MATRIX.EvaluationMatrix"),
            isHidden: loadingBasePermissions || !hasBasePermissions
        },
        {
            icon: <RolesIcon />,
            navigateTo: "roles",
            name: translate("BUSINESSLINES.ROLES.Roles"),
            isHidden: loadingAdminPermissions || !hasAdminPermissions
        },
        {
            icon: <CareerLadderIcon />,
            navigateTo: "career",
            name: translate("BUSINESSLINES.CAREER.CareerLadder"),
            isHidden: loadingAdminPermissions || !hasAdminPermissions
        },
    ], [loadingAdminPermissions, hasAdminPermissions, loadingBasePermissions, hasBasePermissions]);



    useEffect(() => {

        if (!locationID || !businessLineID) return;

        businessLinesSvc
            .getBusinessLineById(locationID, businessLineID)
            .then((response) => {
                if (!response) return;
                setBusinessLineDetails(response);
                updateRouteDate(["##BUSINESSLINE##", response.name]);

            }).catch((error) => {
                console.log("### ---   Business Lines Container - Error while getting business line   ---- ###", error);
            })
    }, [businessLineID, locationID]);





    const renderSubSideBar = useMemo(() => {
        return windowResize > 425 ? <SubSidebar buttons={subSideBarButtons} /> : <MobileSubSidebar buttons={subSideBarButtons} />;
    }, [windowResize, subSideBarButtons]);


    return (
        <div className="business-lines-content-container">
            {renderSubSideBar}
            {props.children}
        </div>
    );
}
