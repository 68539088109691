
export function getEvaluationMatrixStateCss(statusId: number) {
    switch (statusId) {
        case 1:
            return "status-yellow";
        case 2:      
            return "status-green";
        default:
            return undefined;
    }
}
