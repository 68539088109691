import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { DateTime } from "luxon";
import { EvaluationMatricesResponseDTO } from "../models/dtos/EvaluationMatricesResponseDTO";
import { EvaluationMatrixSummaryDTO } from "../models/dtos/EvaluationMatrixSummaryDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { QueryOptions } from "../../../models/api/QueryOptions";
import qs from "qs";
import { EvaluationMatrixChecklistItemsResponseDTO } from "../models/dtos/EvaluationMatrixChecklistItemsResponseDTO";
import { EvaluationMatrixUpdateStateDTO } from "../models/dtos/EvaluationMatrixUpdateStateDTO";
import { EvaluationMatrixCreateDTO } from "../models/dtos/create-matrix/EvaluationMatrixCreateDTO";
import { EvaluationMatrixSkillsRolesResponseDTO } from "../models/dtos/EvaluationMatrixSkillsRolesResponseDTO";
import { EvaluationMatrixUpdateDTO } from "../models/dtos/edit-matrix/EvaluationMatrixUpdateDTO";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};


export class EvaluationMatricesService {

    /* ------- GET ------- */

    getEvaluationMatricesSummaries(locationId: string, businessLineId: string, query: QueryOptions, abortSignal: AbortSignal | undefined): Promise<EvaluationMatricesResponseDTO | null> {

        return HttpClient.sessionRequest<EvaluationMatricesResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationMatrices`),
            signal: abortSignal,
            params: query,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {
                if (!response.data) return null;

                return ({
                    evaluationMatrices: response.data.evaluationMatrices,
                    totalItems: response.data.totalItems
                })
            })
            .catch((error) => {
                throw error;
            });
    }




    getEvaluationMatrixSummaryById(locationId: string, businessLineId: string, matrixId: string): Promise<EvaluationMatrixSummaryDTO | null> {

        return HttpClient.sessionRequest<EvaluationMatrixSummaryDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationMatrices/${matrixId}`),
        })
            .then((response) => {

                if (!response.data) return null;

                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    }




    getEvaluationMatrixSummaryByVersionId(locationId: string, businessLineId: string, matrixId: string, matrixVersionId: string): Promise<EvaluationMatrixSummaryDTO | null> {

        return HttpClient.sessionRequest<EvaluationMatrixSummaryDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationMatrices/${matrixId}/versions/${matrixVersionId}`),
        })
            .then((response) => {
                if (!response.data) return null;

                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    }



    getMatrixChecklistItemsByVersionId(locationId: string, businessLineId: string, matrixId: string, matrixVersionId: string): Promise<EvaluationMatrixChecklistItemsResponseDTO | null> {

        return HttpClient.sessionRequest<EvaluationMatrixChecklistItemsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationMatrices/${matrixId}/versions/${matrixVersionId}/ChecklistItems`),
        })
            .then((response) => {
                if (!response.data) return null;

                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    }


    getMatrixSkillsRolesByVersionId(locationId: string, businessLineId: string, matrixId: string, matrixVersionId: string): Promise<EvaluationMatrixSkillsRolesResponseDTO | null> {

        return HttpClient.sessionRequest<EvaluationMatrixSkillsRolesResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationMatrices/${matrixId}/versions/${matrixVersionId}/SkillsRoles`),
        })
            .then((response) => {
                if (!response.data) return null;

                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    }



    /* ------- POST ------- */


    createEvaluationMatrix(locationId: string | number, businessLineId: string, requestDto: EvaluationMatrixCreateDTO): Promise<EvaluationMatrixSummaryDTO> {
        return HttpClient.sessionRequest<EvaluationMatrixSummaryDTO>({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationMatrices`),
            data: requestDto,
        })
            .then((response) => { return response.data })
            .catch((error) => {
                throw error;
            });
    }






    /* ------- PUT ------- */


    updateEvaluationMatrix(locationId: string | number, businessLineId: string, matrixId: string, requestDto: EvaluationMatrixUpdateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationMatrices/${matrixId}`),
            data: requestDto,
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }



    updateMatrixState(locationId: string, businessLineId: string, matrixId: string, request: EvaluationMatrixUpdateStateDTO): Promise<void> {

        return HttpClient.sessionRequest<EvaluationMatrixChecklistItemsResponseDTO>({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationMatrices/${matrixId}/State`),
            data: request
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }
}
