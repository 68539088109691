import "./FollowUpEvaluationCard.css";

import { EvaluationFollowUpItemStatus, EvaluationFollowUpItemStatusTranslationTerms } from "../../models/EvaluationFollowUpItemStatus";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { AddFollowUpItemPopup } from "../popups/AddFollowUpItemPopup";
import { ReactComponent as AddIcon } from "../../../../lib/assets/icons/add.svg";
import { Button } from "../../../../lib/components/buttons/Button";
import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { ConditionalRender } from "../../../../lib/functional/ConditionalRender";
import { DateTimeHelper } from "../../../../lib/helpers/datetime/DateTime";
import { ReactComponent as DeleteIcon } from "../../../../lib/assets/icons/remover.svg";
import { EvaluationFollowUpItemDTO } from "../../models/EvaluationFollowUpItemDTO";
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { Tag } from "../../../../lib/components/tag/Tag";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { getEvaluationFollowUpItemStatusCss } from "../../helpers/getEvaluationFollowUpItemStatusCss";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";

interface IFollowUpEvaluationCardProps {
    items: EvaluationFollowUpItemDTO[];
    isLoading: boolean;
    isEditionModeEnabled: boolean;
    onChange: (newFollowUpItems: EvaluationFollowUpItemDTO[]) => void;
}

export function FollowUpEvaluationCard(props: IFollowUpEvaluationCardProps) {
    const openPopup = usePopup();
    const closePopup = useClosePopup();

    const [followUpItems, setFollowUpItems] = useState<EvaluationFollowUpItemDTO[]>(props.items);


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/
    useEffect(() => {
        setFollowUpItems(props.items);
    }, [props.items])

    useEffect(() => {
        if (props.items.length !== followUpItems.length) {
            props.onChange(followUpItems)
        }
    }, [followUpItems])


    /****************************
     * USER ACTIONS
     *****************************/
    const updateFollowUpItems = useCallback((item: EvaluationFollowUpItemDTO) => {
        setFollowUpItems([...followUpItems, item]);
    }, [followUpItems]);

    const handleRemoveFollowUpItem = useCallback((index: number) => {
        setFollowUpItems(followUpItems.filter((_, idx) => idx !== index));
    }, [followUpItems]);

    const handleRemoveFollowUpItemWarningPopup = useCallback((index: number) => {
        openPopup(
            <WarningPopup
                className="delete-follow-up-item-popup"
                onDismissClicked={() => closePopup()}
                onConfirmClicked={() => handleRemoveFollowUpItem(index)}
                dismissText={translate("COMMON.Cancel")}
                confirmText={translate("COMMON.Delete")}
            >
                {translate("BUSINESSLINES.EVALUATIONS.POPUPS.DeleteFollowUpItemConfirmText")}
            </WarningPopup>
        )
    }, [closePopup, handleRemoveFollowUpItem, openPopup])

    const handleAddFollowUpItem = useCallback(() => {
        openPopup(<AddFollowUpItemPopup onCompletedOperations={(item) => updateFollowUpItems(item)} />);
    }, [openPopup, updateFollowUpItems]);


    /****************************
     * CSS & HTML
     *****************************/
    const columns: ColumnDefinition<EvaluationFollowUpItemDTO>[] = useMemo(() => [
        {
            cellRenderProp: (item) => item.name,
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FollowUpItem"),
            isMobilePrimaryCell: true,
            width: "20%",
        },
        {
            cellRenderProp: (item) => item.description,
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FollowUpDescription"),
        },
        {
            cellRenderProp: (item) => <Tag
                isTiny
                text={item ? translate(EvaluationFollowUpItemStatusTranslationTerms[item.status]) : "-"}
                backgroundColor={getEvaluationFollowUpItemStatusCss(item.status ?? -1)} />,
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FollowUpStatus"),
            isStatusCell: true,
            width: "100px",
        },
        {
            cellRenderProp: (item) => item.startDate ? DateTimeHelper.formatDateTimeLocale(item.startDate) : "-",
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FollowUpStartDate"),
            width: "120px",
        },
        {
            cellRenderProp: (item) => item.conclusionDate ? DateTimeHelper.formatDateTimeLocale(item.conclusionDate) : "-",
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FollowUpConclusionDate"),
            width: "132px",
        },
        {
            cellRenderProp: (item) => {
                const index = followUpItems.indexOf(item);
                if (!props.isEditionModeEnabled || index === -1) return null;
                return (
                    <ScalableIconButton
                        icon={<DeleteIcon />}
                        onClick={() => handleRemoveFollowUpItemWarningPopup(index)}
                        size={24}
                        isDisabled={item.status !== EvaluationFollowUpItemStatus.INPROGRESS}
                    />)
            },
            columnKey: "delete",
            width: "24px",
            isMobileHeaderIcon: true,
        },
    ], [followUpItems, props.isEditionModeEnabled, handleRemoveFollowUpItemWarningPopup]);


    return <CardContainer className="follow-up-card">
        <div className="card-header">
            <div className="subtitle">{translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FollowUp")}</div>
            {followUpItems.length && props.isEditionModeEnabled ? <IconButton
                icon={<AddIcon />}
                type="secondary"
                onClick={handleAddFollowUpItem}
            /> : null}
        </div>
        {followUpItems.length ?
            <ResponsiveDataTable
                columnDefinitions={columns}
                items={followUpItems}
                totalitems={followUpItems.length}
                isLoading={props.isLoading}
            /> :
            <div className="add-follow-up-item">
                <Spacer mode="vertical" px={20} />
                <ConditionalRender if={props.isEditionModeEnabled}>
                    <Button
                        text={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.AddFollowUpItem")}
                        type="secondary"
                        onClick={handleAddFollowUpItem}
                    />
                </ConditionalRender>
                <Spacer mode="vertical" px={20} />
            </div>}
    </CardContainer>;
}