import { useCallback, useEffect, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FormContainer } from "../../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { SkillsService } from "../../services/SkillsService";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../../lib/utils/TrimString";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../../lib/hooks/useServiceCall";
import { validateWhiteSpaceStrings } from "../../../../../lib/validators/ValidateWhiteSpaceStrings";
import { ConditionalRender } from "../../../../../lib/functional/ConditionalRender";
import { MessageBar } from "../../../../../lib/components/message-bar/MessageBar";
import { ValidationErrorMessage } from "../../../../../lib/infrastructure/http/APIError";
import { ExceptionType } from "../../../../../lib/infrastructure/http/ExceptionType";
import { useUpdatePageData } from "../../../../../lib/infrastructure/navigation/hooks/useUpdatePageData";

var skillsSvc = new SkillsService();

interface IEditSkillsCategoryPopupProps {
  skillCategoryID: number;
  locationID: string;
  onCompleted: () => void;
}


export function EditSkillsCategoryPopup(props: IEditSkillsCategoryPopupProps) {
  const updateRouteData = useUpdatePageData();
  const closePopup = useClosePopup();
  const openPopup = usePopup();

  const createNotification = useCreateNotification();

  const getSkillsCategoryByIdCall = useServiceCallPro(skillsSvc.getSkillsCategoryById);
  const updateSkillsCategoryCall = useServiceCallPro(skillsSvc.updateSkillsCategory);

  const nameFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
  });


  const [validationAlert, setValidationAlert] = useState<ValidationErrorMessage>({ showAlert: false });




  useEffect(() => {
    if (props.locationID && props.skillCategoryID) {
      getSkillsCategoryByIdCall
        .invoke(props.locationID, "" + props.skillCategoryID)
        .then((response) => {
          nameFormControl.setValue(response.categoryName);

          updateRouteData(["##CATEGORY##", response.categoryName ? response.categoryName : "-"]);

        })
        .catch((_) => {
          openPopup(<ErrorPopup>{translate("GLOBALADMIN.LOCATIONS.SKILLS.POPUP.LoadingSkillsCategoryError")}</ErrorPopup>);
        });
    }
  }, []);





  const handleEditSkillCategoryClicked = useCallback(() => {

    if (!AllValid(nameFormControl.validate())) return;


    const handleSuccess = () => {
      props.onCompleted();
      closePopup();
      createNotification(
        <Notification
          type="success"
          title={translate("COMMON.SYSTEMPOPUPS.Success")}
          text={translate("GLOBALADMIN.LOCATIONS.SKILLS.POPUP.EditSkillsCategorySuccess")}
        />
      );
    };



    const handleError = (error: any) => {
      if (!error) return;

      const errorMessage = error?.response?.data?.message || translate("ERRORS.UnknownError");
      const enumCode = error?.response?.data?.enumCode;

      if (enumCode === ExceptionType.ERROR_VALIDATION_NAME_ALREADY_EXISTS) {
        setValidationAlert({
          showAlert: true,
          message: errorMessage,
        });
      } else {
        openPopup(<ErrorPopup>{errorMessage}</ErrorPopup>);
      }
    };



    updateSkillsCategoryCall.invoke(props.locationID, props.skillCategoryID, trimString(nameFormControl.value))
      .then(handleSuccess)
      .catch(handleError);


  }, [nameFormControl.value, nameFormControl.validate, closePopup, updateSkillsCategoryCall.invoke,
    openPopup, props.onCompleted, createNotification, , setValidationAlert]);





  return (
    <PopupContainer className="popup-edit-skills-category">
      {updateSkillsCategoryCall.isLoading ? <FullScreenLoader /> : null}

      <PopupHeader
        title={translate("GLOBALADMIN.LOCATIONS.SKILLS.POPUP.EditSkillsCategory")}
      />
      <PopupContent isLoading={getSkillsCategoryByIdCall.isLoading}>
        <FormContainer>

          <FormFieldTextInput
            formControl={nameFormControl}
            label={translate("GLOBALADMIN.LOCATIONS.SKILLS.INFOANDFORM.CategoryName")}
            placeholder={translate("GLOBALADMIN.LOCATIONS.SKILLS.INFOANDFORM.CategoryNamePlaceholder")}
          />

          <ConditionalRender if={validationAlert.showAlert}>
            <div className="name-already-exists">
              <MessageBar type="error" text={"" + validationAlert.message} />
            </div>
          </ConditionalRender>

        </FormContainer>
        <Spacer mode={"vertical"} px="30" />
        <PopupActionButtons
          buttons={[
            {
              text: translate("COMMON.Cancel"),
              type: "tertiary",
              onClick: () => closePopup(),
            },
            {
              text: translate("COMMON.Save"),
              type: "primary",
              onClick: handleEditSkillCategoryClicked,
            },
          ]}
        />
      </PopupContent>
    </PopupContainer>
  );
}
