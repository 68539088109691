import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { EvaluationSummariesResponseDTO } from "../models/dtos/EvaluationSummariesResponseDTO";
import { PeriodMatrixEvaluationsSummary } from "../models/dtos/PeriodMatrixEvaluationsSummaryDTO";
import { QueryOptions } from "../../../models/api/QueryOptions";

const evaluationsMock: EvaluationSummariesResponseDTO = {
    evaluations: [
        {
            evaluationId: 1,
            employee: {
                userId: "1",
                userEmployeeID: "45230",
                userEmail: "john.doe@doamin.com",
                userDisplayName: "John Doe"
            },
            sumRoleCompetencyLevels: 18,
            roleDescription: "Operador Logistica II",
            evaluationTotal: 18,
            isEvaluationCompleted: true,
        },
        {
            evaluationId: 2,
            employee: {
                userId: "2",
                userEmployeeID: "53100",
                userEmail: "john.doe2@doamin.com",
                userDisplayName: "John Doe 2"
            },
            roleDescription: "Operador Logistica Sénior",
            sumRoleCompetencyLevels: 13,
            evaluationTotal: 10,
            isEvaluationCompleted: true
        },
        {
            evaluationId: 3,
            employee: {
                userId: "3",
                userEmployeeID: "12345",
                userEmail: "john.doe3@doamin.com",
                userDisplayName: "John Doe 4"
            },
            roleDescription: "Operador Logistica Sénior",
            sumRoleCompetencyLevels: 10,
            evaluationTotal: 5,
            isEvaluationCompleted: false
        },
    ],
    totalItems: 3
}






const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};


export class EvaluationPeriodMatrixService {


    getPeriodMatrixEvaluations(locationId: string, businessLineId: string, periodId: string, matrixId: string, query: QueryOptions): Promise<EvaluationSummariesResponseDTO> {

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    evaluations: evaluationsMock.evaluations,
                    totalItems: evaluationsMock.totalItems
                });
            }, 1000);
        });
    }




    getPeriodMatrixEvaluationsSummary(locationId: string, businessLineId: string, periodId: string, matrixId: string): Promise<PeriodMatrixEvaluationsSummary> {

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    matrixId: 1,
                    matrixDescription: "Production",
                    matrixManager: {
                        userId: "1",
                        userEmployeeID: "123456",
                        userDisplayName: "Sandra Isabel Silva",
                        userEmail: "johndoe@valmet.pt"
                    },
                    allEvaluationsCompleted: false,
                    totalEvaluations: 20,
                    totalEvaluationsInProgress: 12
                });
            }, 1000);
        });
    }







}

