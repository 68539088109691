import "./EditLocationGroupAccessPage.css";

import { AccessGroupsService, LocationGroups, UpdateLocationGroupItem, UpdateLocationGroups } from "../services/AccessGroupsService";
import { IFormControl, useFormControl, } from "../../../../lib/components/form/Form";
import { Table, TableBody, TableHeader, TableHeaderCell, TableRow, TableRowCell } from '../../../../lib/components/table/Table';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCreateNotification, usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../common/validators/ValidateFormControls";
import { Button } from "../../../../lib/components/buttons/Button";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { Loader } from "../../../../lib/components/loader/Loader";
import { LocationGroupType } from "../../locations/models/enums/LocationGroupType";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { ReactComponent as SaveIcon } from "../../../../lib/assets/icons/save.svg";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useParams } from "react-router-dom";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { validateGuid } from "../../../../lib/validators/ValidateGuid";

var accessGroupsService = new AccessGroupsService();

interface IGroupAccessRow {
  type: string;
  control: IFormControl<string>;
  AADName: string;
}

export function GroupAccessRow(props: IGroupAccessRow) {
  const windowResize = useWindowResize();

  if (windowResize > 768)
    return <TableRow>
      <TableRowCell>{props.type}</TableRowCell>
      <TableRowCell> <FormFieldTextInput formControl={props.control} /> </TableRowCell>
      <TableRowCell>{props.AADName}</TableRowCell>
    </TableRow>;
  else
    return <TableRow>
      <TableRowCell>
        <div className="mobile-access-groups-table-cell">
          <div>
            <div className="small-copy mobile-access-groups-table-cell-label">{translate("GLOBALADMIN.LOCATIONS.ACCESSGROUPS.INFOANDFORM.Type")}</div>
            <div>{props.type}</div>
          </div>
          <div>
            <div className="small-copy mobile-access-groups-table-cell-label">{translate("GLOBALADMIN.LOCATIONS.ACCESSGROUPS.INFOANDFORM.ID")}</div>
            <FormFieldTextInput formControl={props.control} />
          </div>
          {props.AADName ?
            <div>
              <div className="small-copy mobile-access-groups-table-cell-label">{translate("GLOBALADMIN.LOCATIONS.ACCESSGROUPS.INFOANDFORM.Name")}</div>
              <div>{props.AADName}</div>
            </div> : null}
        </div>
      </TableRowCell>
    </TableRow>;
}


export interface LocationGroup {
  type: string;
  typeId: LocationGroupType;
  id: string;
  name: string;
}

export function EditLocationGroupAccessPage() {

  const { id: locationId } = useParams();

  const windowResize = useWindowResize();
  const openPopup = usePopup();
  const createNotification = useCreateNotification();

  const [locationGroups, setLocationGroups] = useState<LocationGroups>();

  const getGroupsCall = useServiceCallPro(accessGroupsService.getLocationGroups);
  const updateGroupsCall = useServiceCallPro(accessGroupsService.updateLocationGroup);


  /****************************
      * FORM CONTROL
      *****************************/
  const localAdministratorGuidFormControl = useFormControl<string>({
    validators: [validateGuid()],
    enableAutoValidate: true,
  });

  const rhGuidFormControl = useFormControl<string>({
    validators: [validateGuid()],
    enableAutoValidate: true,
  });

  const teamManagerGuidFormControl = useFormControl<string>({
    validators: [validateGuid()],
    enableAutoValidate: true,
  });

  const collaboratorGuidFormControl = useFormControl<string>({
    validators: [validateGuid()],
    enableAutoValidate: true,
  });



  useEffect(() => {
    getLocationGroups();
  }, [locationId]);



  const getLocationGroups = useCallback(() => {
    if (!locationId) return;

    getGroupsCall
      .invoke("" + locationId)
      .then((response) => {
        setLocationGroups(response);
        localAdministratorGuidFormControl.setValue(response.locationAdmin.objectId);
        rhGuidFormControl.setValue(response.humanResources.objectId);
        teamManagerGuidFormControl.setValue(response.evaluator.objectId);
        collaboratorGuidFormControl.setValue(response.locationAccess.objectId);
      }).catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      })

  }, [getGroupsCall, locationId, setLocationGroups, localAdministratorGuidFormControl.setValue, rhGuidFormControl.setValue, teamManagerGuidFormControl.setValue,
    collaboratorGuidFormControl.setValue, openPopup]);



  const handleSaveButtonClicked = useCallback(() => {
    if (locationId) {

      if (!AllValid(localAdministratorGuidFormControl.validate(), rhGuidFormControl.validate(),
        teamManagerGuidFormControl.validate(), collaboratorGuidFormControl.validate())) {
        openPopup(<ErrorPopup>
          {translate("GLOBALADMIN.LOCATIONS.ACCESSGROUPS.INFOANDFORM.SubmitError")}
        </ErrorPopup>);
        return;
      }


      const mapGroup = (type: LocationGroupType, objectId: string | undefined): UpdateLocationGroupItem => {
        return ({ type, objectId: objectId || "" });
      }

      var lg: UpdateLocationGroups = {
        locationAdmin: mapGroup(LocationGroupType.LOCATION_ADMIN, localAdministratorGuidFormControl.value),
        humanResources: mapGroup(LocationGroupType.HUMAN_RESOURCES, rhGuidFormControl.value),
        evaluator: mapGroup(LocationGroupType.EVALUATOR, teamManagerGuidFormControl.value),
        locationAccess: mapGroup(LocationGroupType.ACCESS, collaboratorGuidFormControl.value)
      };

      updateGroupsCall
        .invoke(locationId, lg)
        .then((_) => {
          getLocationGroups();
          createNotification(<Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")} text={translate("GLOBALADMIN.LOCATIONS.ACCESSGROUPS.INFOANDFORM.EditedGroupSuccess")}></Notification>);
        })
        .catch((error) => {
          if (!error) return;
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>)
        });
    }
  }, [openPopup, locationId, getLocationGroups, createNotification, updateGroupsCall,
    localAdministratorGuidFormControl.value, rhGuidFormControl.value, teamManagerGuidFormControl.value, collaboratorGuidFormControl.value,
    localAdministratorGuidFormControl.validate, rhGuidFormControl.validate,
    teamManagerGuidFormControl.validate, collaboratorGuidFormControl.validate]);



  const renderActionButtons = useMemo(() => {
    if (windowResize > 768)
      return <Button
        text={translate("COMMON.Save")}
        type="primary"
        onClick={handleSaveButtonClicked}
        isDisabled={getGroupsCall.isLoading || updateGroupsCall.isLoading}
      />;
    else
      return (
        <IconButton
          icon={<SaveIcon />}
          type="primary"
          onClick={handleSaveButtonClicked}
          className="edit-btn"
        />
      );
  }, [getGroupsCall.isLoading, updateGroupsCall.isLoading, handleSaveButtonClicked, windowResize]);

  return (
    <PageLayout
      tabTitle={`laMP - ${translate("GLOBALADMIN.LOCATIONS.AccessGroups")}`}
      pageTitle={translate("GLOBALADMIN.LOCATIONS.AccessGroups")}
      className="location-groups"
      showBreadcrumb
      actions={renderActionButtons}
    >
      {updateGroupsCall.isLoading ? <FullScreenLoader /> : null}
      {getGroupsCall.isLoading ? <div className="access-groups-loader">
        <Loader />
      </div> : (

        <div className={"group-access-table"}>
          <Table>
            {windowResize > 768 ?
              <TableHeader>
                <TableHeaderCell key="1">{translate("GLOBALADMIN.LOCATIONS.ACCESSGROUPS.INFOANDFORM.Type")}</TableHeaderCell>
                <TableHeaderCell key="2">{translate("GLOBALADMIN.LOCATIONS.ACCESSGROUPS.INFOANDFORM.ID")}</TableHeaderCell>
                <TableHeaderCell key="3">{translate("GLOBALADMIN.LOCATIONS.ACCESSGROUPS.INFOANDFORM.Name")}</TableHeaderCell>
              </TableHeader> : null}

            <TableBody>
              <GroupAccessRow control={localAdministratorGuidFormControl} AADName={locationGroups?.locationAdmin.name || ""} type={translate("GLOBALADMIN.LOCATIONS.ACCESSGROUPS.TYPES.LocalAdmin")} />
              <GroupAccessRow control={rhGuidFormControl} AADName={locationGroups?.humanResources.name || ""} type={translate("GLOBALADMIN.LOCATIONS.ACCESSGROUPS.TYPES.RH")} />
              <GroupAccessRow control={teamManagerGuidFormControl} AADName={locationGroups?.evaluator.name || ""} type={translate("GLOBALADMIN.LOCATIONS.ACCESSGROUPS.TYPES.TeamManager")} />
              <GroupAccessRow control={collaboratorGuidFormControl} AADName={locationGroups?.locationAccess.name || ""} type={translate("GLOBALADMIN.LOCATIONS.ACCESSGROUPS.TYPES.Collaborator")} />
            </TableBody>
          </Table>
        </div>)}
    </PageLayout>
  );
}