import "./BusinessLinesPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";

import { AddBusinessLinePopup } from "./popups/AddBusinessLinePopup";
import { ReactComponent as AddIcon } from "../../../../lib/assets/icons/add.svg";
import { BusinessLine } from "../models/domain/BusinessLine";
import { BusinessLinesQueryOptions } from "../models/dtos/BusinessLinesQueryOptions";
import { BusinessLinesService } from "../services/BusinessLinesService";
import { Button } from "../../../../lib/components/buttons/Button";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { EditBusinessLinePopup } from "./popups/EditBusinessLinePopup";
import { ReactComponent as EditIcon } from "../../../../lib/assets/icons/editar.svg";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { Permission } from "../../../../models/api/gate/Permission";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { Toggle } from "../../../../lib/components/form/toggle/Toggle";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useHasPermissions } from "../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useParams } from "react-router-dom";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";

var businessLinesSvc = new BusinessLinesService();

const permissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN];

export function BusinessLinesPage() {
  const windowResize = useWindowResize();
  const openPopup = usePopup();
  const closePopup = useClosePopup();
  const { id: locationID } = useParams();
  const createNotification = useCreateNotification();

  const { isLoadingPermissions, hasPermission } = useHasPermissions(permissions);

  const [businessLines, setBusinessLines] = useState<BusinessLine[]>();
  const [totalBusinessLines, setTotalBusinessLines] = useState<number>();

  const getBusinessLinesCall = useServiceCallPro(businessLinesSvc.getBusinessLines);

  const updateBusinessLineStateCall = useServiceCallPro(businessLinesSvc.updateBusinessLineState);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentpage, setCurrentPage] = useState(0);

  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [searchWord, setSearchWord] = useState<string>();


  var searchboxController = useFormControl<string>({
    isDisabled: false,
    enableAutoValidate: false,
  });


  /****************************
 * DATA REQUESTS
 *****************************/

  const getBusinessLines = useCallback(() => {
    if (!locationID) return;

    var queryOptions: BusinessLinesQueryOptions = {
      page: currentpage,
      pageLength: itemsPerPage,
      searchWord: searchWord,
    };

    getBusinessLinesCall.invoke("" + locationID, queryOptions)
      .then((data) => {
        if (data) {
          setBusinessLines(data.businessLines);
          setTotalBusinessLines(data.totalItems);
        }
      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [locationID, setBusinessLines, getBusinessLinesCall.invoke, openPopup, setTotalBusinessLines, currentpage, itemsPerPage, searchWord]);








  const updateBusinessLineState = useCallback((businessLineId: number, isToDisable: boolean) => {

    if (!businessLineId || !locationID) return;

    updateBusinessLineStateCall.invoke(locationID + "", "" + businessLineId, isToDisable)
      .then((_) => {
        getBusinessLines();
        createNotification(<Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")} text={isToDisable ? translate("GLOBALADMIN.LOCATIONS.BUSINESSLINES.POPUP.DeactivateBusinessLineSuccess") : translate("GLOBALADMIN.LOCATIONS.BUSINESSLINES.POPUP.ActivateBusinessLineSuccess")}></Notification>);
      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });

  }, [getBusinessLines, openPopup, locationID,
    createNotification, updateBusinessLineStateCall]);





  const handleOnPageAndItemsChanged = useCallback(
    (items: number, currentPage: number) => {
      setItemsPerPage(items);
      setCurrentPage(currentPage);
    },
    [setItemsPerPage, setCurrentPage]
  );


  /****************************
  * DATA MANIPULATION EFFECTS
  *****************************/

  useEffect(() => {
    getBusinessLines();
  }, [currentpage, itemsPerPage, searchWord]);


  useEffect(() => {
    if (timer)
      clearTimeout(timer);

    var responseTimeOut = setTimeout(() => {
      if (timer) {
        setCurrentPage(0);
        setSearchWord(searchboxController.value);
      }
    }, 1000);

    setTimer(responseTimeOut);
  }, [searchboxController.value]);




  const columns: ColumnDefinition<BusinessLine>[] = useMemo(
    () => [
      {
        cellRenderProp: (v) => v.name,
        headerRender: translate("GLOBALADMIN.LOCATIONS.BUSINESSLINES.INFOANDFORM.Name"),
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => (
          <div className="toggle-div">
            <Toggle value={!v.isDisabled} />
            {isLoadingPermissions || !hasPermission ? null :
              <div
                className="toggle-div-overlay"
                onClick={(ev) => {
                  ev.stopPropagation();
                  if (!v.isDisabled) {
                    openPopup(
                      <WarningPopup
                        className="disable-business-line-popup"
                        onDismissClicked={() => closePopup()}
                        onConfirmClicked={() => updateBusinessLineState(v.id, true)}
                        dismissText={translate("COMMON.Cancel")}
                        confirmText={translate("GLOBALADMIN.LOCATIONS.BUSINESSLINES.POPUP.Deactivate")}
                      >
                        {translate("GLOBALADMIN.LOCATIONS.BUSINESSLINES.POPUP.DeactivateBusinessLineConfirmText")}
                      </WarningPopup>
                    );
                  }
                  else {
                    if (v.id) {
                      openPopup(
                        <WarningPopup
                          onDismissClicked={() => closePopup()}
                          onConfirmClicked={() => updateBusinessLineState(v.id, false)}
                          dismissText={translate("COMMON.Cancel")}
                          confirmText={translate("GLOBALADMIN.LOCATIONS.BUSINESSLINES.POPUP.Activate")}
                        >
                          {translate("GLOBALADMIN.LOCATIONS.BUSINESSLINES.POPUP.ActivateBusinessLineConfirmText")}
                        </WarningPopup>
                      );
                    }
                  }
                }}
              />}
          </div>
        ),
        headerRender: <></>,
        columnKey: "status",
        width: "5%",
        isSortable: false,
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => {
          if (locationID && !isLoadingPermissions && hasPermission)
            return (
              <ScalableIconButton
                icon={<EditIcon />}
                onClick={() =>
                  openPopup(
                    <EditBusinessLinePopup
                      businessLineId={v.id}
                      locationId={locationID}
                      onCompleted={() => getBusinessLines()}
                    />
                  )
                }
                size={24}
              />)
          return null;
        },
        columnKey: "edit",
        width: "24px",
        isMobileHeaderIcon: true,
      },
    ], [openPopup, closePopup, isLoadingPermissions, hasPermission, updateBusinessLineState, getBusinessLines, locationID]);



  const handleClickAddBtn = useCallback(() => {

    openPopup(
      <AddBusinessLinePopup
        onChangesMade={() => getBusinessLines()}
        locationId={locationID}
      />);

  }, [getBusinessLines, locationID, openPopup])


  const renderAddBusinessLineBtn = useMemo(() => {
    if (isLoadingPermissions || !hasPermission) return null;

    if (windowResize > 768)
      return (
        <Button
          text={translate("COMMON.Add")}
          type="primary"
          onClick={handleClickAddBtn}
        />
      );
    else
      return (
        <IconButton
          icon={<AddIcon />}
          type="primary"
          onClick={handleClickAddBtn}
        />
      );
  }, [windowResize, isLoadingPermissions, hasPermission, handleClickAddBtn]);


  /****************************
  * USER ACTIONS
  *****************************/


  /****************************
   * CSS & HTML
   *****************************/

  return <PageLayout
    tabTitle={translate("GLOBALADMIN.LOCATIONS.BUSINESSLINES.BusinessLines")}
    showBreadcrumb
    pageTitle={translate("GLOBALADMIN.LOCATIONS.BUSINESSLINES.BusinessLines")}
    className="business-lines-page"
    actions={renderAddBusinessLineBtn}
  >

    {updateBusinessLineStateCall.isLoading && <FullScreenLoader />}

    <ResponsiveDataTable
      columnDefinitions={columns}
      items={businessLines || []}
      totalitems={totalBusinessLines || 0}
      paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 15, 20] }}
      currentpage={currentpage}
      isLoading={getBusinessLinesCall.isLoading}
      onPageAndItemsChanged={handleOnPageAndItemsChanged}
    />
  </PageLayout>;
}