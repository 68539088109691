import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";

export enum EmployeeFollowUpItemStatus {
    REGISTERED = 1,
    IN_PROGRESS = 2,
    CONCLUDED = 3,
    CANCELED = 4,
}


export const EmployeeStatusTranslationTerms: { [key: string]: string } = {
    1: "COMMON.STATUS.Registered",
    2: "COMMON.STATUS.InProgress",
    3: "COMMON.STATUS.Concluded",
    4: "COMMON.STATUS.Canceled",
}


export const EmployeeFollowUpItemStatusAsEnum: { [key: number]: EmployeeFollowUpItemStatus } = {
    1: EmployeeFollowUpItemStatus.REGISTERED,
    2: EmployeeFollowUpItemStatus.IN_PROGRESS,
    3: EmployeeFollowUpItemStatus.CONCLUDED,
    4: EmployeeFollowUpItemStatus.CANCELED,
};

export interface EmployeeFollowUpItemStatusInfo {
    id: string;
    description: string;
}

export function getAnalysisRequestStatus() {
    const followUpItemsStatusArray: EmployeeFollowUpItemStatusInfo[] = Object.values(EmployeeFollowUpItemStatus)
        .filter(value => typeof value === "number")
        .map((enumValue) => {
            var followUpItemsStatus: EmployeeFollowUpItemStatusInfo = {
                id: "" + enumValue,
                description: translate(EmployeeStatusTranslationTerms[enumValue]) || "-unknown-"
            };
            return followUpItemsStatus;
        });

    return followUpItemsStatusArray;
}