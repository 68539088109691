import "./SelectRolesPopup.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, usePopup } from "../../../lib/infrastructure/ui/UIServices";

import { ReactComponent as AddIcon } from "../../../lib/assets/icons/add.svg";
import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { ConditionalRender } from "../../../lib/functional/ConditionalRender";
import { ReactComponent as DeleteIcon } from "../../../lib/assets/icons/remover.svg";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FormFieldSelectSingle } from "../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormSection } from "../../../lib/components/form/section/FormSection";
import { IconButton } from "../../../lib/components/buttons/IconButton";
import { MessageBar } from "../../../lib/components/message-bar/MessageBar";
import { PopupActionButtons } from "../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../lib/layouts/containers/popup-header/PopupHeader";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { RoleSummary } from "../../roles/models/domain/RoleSummary";
import { RolesService } from "../../roles/services/RolesService";
import { ScalableIconButton } from "../../../lib/components/buttons/ScalableIconButton";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { ValidationErrorMessage } from "../../../lib/infrastructure/http/APIError";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";

interface ISelectRolesPopupProps {
    onCompletedOperations: (selectedRoles: RoleSummary[]) => void;
    locationId: string | undefined;
    businessLineId: string | undefined;
    matrixId?: string | undefined;
    selectedRoles: RoleSummary[];
}

const labelRoleSelector = (item: RoleSummary) => item.description;
const idRoleSelector = (item: RoleSummary) => item.roleId;

var rolesSvc = new RolesService();

export function SelectRolesPopup(props: ISelectRolesPopupProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();

    const getActiveRolesCall = useServiceCallPro(rolesSvc.getRoles);

    const [roles, setRoles] = useState<RoleSummary[]>([])
    const [selectedRoles, setSelectedRoles] = useState<RoleSummary[]>(props.selectedRoles)
    const [validationAlert, setValidationAlert] = useState<ValidationErrorMessage>({ showAlert: false });


    const getFilteredRoles = useCallback(() => {
        return roles.filter(role => !selectedRoles.some(selectedRole => selectedRole.roleId === role.roleId));
    }, [roles, selectedRoles]);


    const rolesFormControl = useFormControl<RoleSummary>({});


    useEffect(() => {
        getActiveRoles();
    }, [])


    const getActiveRoles = useCallback(() => {
        if (!props.locationId && !props.businessLineId) return;

        getActiveRolesCall.invoke("" + props.locationId, "" + props.businessLineId, { filterByIsActive: true }, undefined)
            .then((data) => {
                setRoles(data?.roles || []);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationId, props.businessLineId, getActiveRolesCall, openPopup]);


    const handleClickAddRoleBtn = useCallback((role: RoleSummary) => {
        setSelectedRoles(prevSelectedRoles => {
            if (!prevSelectedRoles.some(r => r.roleId === role.roleId)) {
                return [...prevSelectedRoles, role];
            }
            return prevSelectedRoles;
        });
        rolesFormControl.setValue({ roleId: -1, description: "", jobGrade: undefined, isDisabled: false, numberOfPositions: 0 });
        setValidationAlert({ showAlert: false });
    }, [rolesFormControl]);


    const handleClickRemoveRoleBtn = useCallback((role: RoleSummary) => {
        setSelectedRoles(selectedRoles.filter(r => r.roleId !== role.roleId));
    }, [selectedRoles]);


    const columns: ColumnDefinition<RoleSummary>[] = useMemo(() => [
        {
            cellRenderProp: (role) => role.description,
            headerRender: translate("BUSINESSLINES.MATRIX.INFOANDFORM.Name"),
            isMobilePrimaryCell: true,
        },
        {
            cellRenderProp: (role) => {
                return (
                    <ScalableIconButton
                        icon={<DeleteIcon />}
                        onClick={() => handleClickRemoveRoleBtn(role)}
                        size={24}
                    />)
            },
            columnKey: "delete",
            width: "24px",
            isMobileHeaderIcon: true,
        },
    ], [handleClickRemoveRoleBtn]);


    const handleSelectClicked = useCallback(() => {
        if (selectedRoles.length) {
            props.onCompletedOperations(selectedRoles);
            closePopup();
        }
        else
            setValidationAlert({
                showAlert: true,
                message: translate("BUSINESSLINES.MATRIX.ERRORS.NoRolesSelected"),
            });
    }, [props.onCompletedOperations, selectedRoles, closePopup]);


    return (
        <PopupContainer className="popup-select-roles">
            <PopupHeader title={translate("BUSINESSLINES.MATRIX.POPUP.SelectRoles")} />
            <PopupContent isLoading={getActiveRolesCall.isLoading}>
                <FormSection isInputGap>
                    <FormFieldSelectSingle
                        formControl={rolesFormControl}
                        label={translate("BUSINESSLINES.MATRIX.INFOANDFORM.Roles")}
                        placeholder={translate("BUSINESSLINES.MATRIX.INFOANDFORM.RolesPlaceholder")}
                        options={getFilteredRoles() || []}
                        labelSelector={labelRoleSelector}
                        idSelector={idRoleSelector}
                        maxHeightOptions={"16rem"}
                    />
                    <IconButton
                        className="add-role-btn"
                        icon={<AddIcon />}
                        type="secondary"
                        onClick={() => handleClickAddRoleBtn(rolesFormControl.value as RoleSummary)}
                        isDisabled={getFilteredRoles().length === 0}
                    />
                </FormSection>
                <Spacer mode={"vertical"} px="12" />
                {selectedRoles.length ?
                    <ResponsiveDataTable
                        columnDefinitions={columns}
                        items={selectedRoles}
                        totalitems={selectedRoles.length}
                    // isLoading={} 
                    /> : null}
                <ConditionalRender if={validationAlert.showAlert}>
                    <Spacer mode="vertical" px="10" />
                    <MessageBar type="error" text={"" + validationAlert.message} />
                </ConditionalRender>
                <Spacer mode={"vertical"} px="30" />
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Select"),
                            type: "primary",
                            onClick: handleSelectClicked,
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
