import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { EmployeeCareerLadderStepCreateDTO } from "../models/dtos/EmployeeCareerLadderStepCreateDTO";
import { EmployeeCareerLadderTreeResponseDTO } from "../models/dtos/EmployeeCareerLadderTreeResponseDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";

const employeeCareerLadderMock: EmployeeCareerLadderTreeResponseDTO = {
    nodes: [
        {
            careerLadderStepId: 38,
            role: { roleId: 1, description: 'Operador Logistica I', jobGrade: 3, isDisabled: false, numberOfPositions: 4 },
            roleId: 1,
            startDate: "2024-01-01T00:00:00+00:00",
            endDate: "2024-01-01T00:00:00+00:00",
        },
        {
            careerLadderStepId: 39,
            role: { roleId: 9, description: 'Operador Logistica II', jobGrade: 4, isDisabled: false, numberOfPositions: 0 },
            roleId: 9,
            startDate: "2024-01-01T00:00:00+00:00",
            endDate: "2024-01-01T00:00:00+00:00",
        }
    ]
}

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class EmployeeCareerLadderService {

    getEmployeeCareerLadder(locationId: string, employeeId: string): Promise<EmployeeCareerLadderTreeResponseDTO | null> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    nodes: employeeCareerLadderMock.nodes
                });
            }, 1000);
        });
    }

    addEmployeeNode(locationId: string, requestDto: EmployeeCareerLadderStepCreateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "POST",
            // url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/CareerLadder`),
            data: requestDto,
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    removeEmployeeNode(locationId: string, employeeId: string, nodeId: string): Promise<void> {
        return HttpClient.sessionRequest({
            method: "DELETE",
            // url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/CareerLadder/${nodeId}`),
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }
}