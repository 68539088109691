import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { EvaluationBonusResponseDTO } from "../models/EvaluationBonusResponseDTO";
import { EvaluationCertificatesResponseDTO } from "../models/EvaluationCertificatesResponseDTO";
import { EvaluationDetailsDTO } from "../models/EvaluationDetailsDTO";
import { EvaluationDetailsUpdateDTO } from "../models/EvaluationDetailsUpdateDTO";
import { EvaluationFollowUpItemStatus } from "../models/EvaluationFollowUpItemStatus";
import { EvaluationFollowUpItemsResponseDTO } from "../models/EvaluationFollowUpItemsResponseDTO";
import { EvaluationFollowupItemsUpdateDTO } from "../models/EvaluationFollowupItemsUpdateDTO";
import { EvaluationStatus } from "../models/EvaluationStatus";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { NextRoleDetailsDTO } from "../pages/EvaluationDetailsPage";

const evaluationDetailsMock: EvaluationDetailsDTO = {
    evaluationId: 1,
    evaluationStatus: EvaluationStatus.READY_TO_EVALUATE,
    employee: {
        userId: "1",
        userEmployeeID: "1",
        userEmail: "john.doe@doamin.com",
        userDisplayName: "John Doe"
    },
    employeeRoleId: 1,
    employeeRoleDescription: "Operador Logística",
    evaluationTotal: 0,
    skillsCategories: [
        {
            id: 1,
            name: "Behavioral Skills",
            skills: [
                {
                    id: 1,
                    name: "Adapting and Responding to Change",
                    value: "",
                    refValue: "1",
                    isValid: true
                },
                {
                    id: 2,
                    name: "Analysing",
                    value: "",
                    refValue: "1",
                    isValid: true
                },
            ]
        },
        {
            id: 2,
            name: "Needling",
            skills: [
                {
                    id: 3,
                    name: "Cabine Agulhas",
                    value: "",
                    refValue: "1",
                    isValid: true
                },
                {
                    id: 4,
                    name: "Needling Di 4.5",
                    value: "",
                    refValue: "1",
                    isValid: true
                },
            ]
        },
    ],
    checklistItems: [
        {
            id: 2,
            description: "Desempenho: Média da avaliação do bónus > 4 nos últimos 12 meses",
            isChecked: false
        },
        {
            id: 1,
            description: "Cumprimento do regulamento interno: Últimos 3 anos",
            isChecked: true
        },
        {
            id: 3,
            description: "Absentismo: Máx X faltas injustificadas nos últimos 3 anos",
            isChecked: false
        },
    ],
    feedbackManagerEmployee: "Lorem Ipsum",
    feedbackEmployeeManager: "Lorem Ipsum",
    canCalibrate: true,
    canReject: true,
    nextRoleID: undefined
}

const bonusMock: EvaluationBonusResponseDTO = {
    items: [
        {
            id: 1,
            periodName: "1º Trimestre 2023",
            evaluationValue: 4
        },
        {
            id: 2,
            periodName: "2º Trimestre 2023",
            evaluationValue: 4
        },
    ]
}

const unjustifiedAbsencesMock: string = "4";

const certificatesMock: EvaluationCertificatesResponseDTO = {
    items: [
        {
            id: 1,
            certificateName: "Certificado A",
            date: "2024-01-01T00:00:00+00:00",
            isApproved: false
        },
        {
            id: 2,
            certificateName: "Certificado B",
            date: "2024-01-01T00:00:00+00:00",
            isApproved: true
        },
        {
            id: 3,
            certificateName: "Certificado C",
            date: "2024-01-01T00:00:00+00:00",
            isApproved: true
        },
    ]
}

const followUpItemsMock: EvaluationFollowUpItemsResponseDTO = {
    items: [
        // {
        //     name: "Item A",
        //     description: "Lorem Ipsum",
        //     startDate: "2024-01-01T00:00:00+00:00",
        //     conclusionDate: "2024-01-01T00:00:00+00:00",
        //     status: EvaluationFollowUpItemStatus.REGISTERED,
        // },
        {
            name: "Item B",
            description: "Lorem Ipsum",
            startDate: "2024-01-01T00:00:00+00:00",
            conclusionDate: "2024-01-01T00:00:00+00:00",
            status: EvaluationFollowUpItemStatus.INPROGRESS,
        },
    ]
};

const nextRoleLevelsMock: NextRoleDetailsDTO = {
    roleId: 9,
    roleDescription: "Operador Logística II",
    skills: [
        {
            skillId: 1,
            competenceLevel: "1",
        },
        {
            skillId: 2,
            competenceLevel: "1",
        },
        {
            skillId: 3,
            competenceLevel: "1",
        },
        {
            skillId: 4,
            competenceLevel: "1",
        }
    ]
};

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class EvaluationsService {

    getEvaluationDetailsById(locationId: string, businessLineId: string, preiodId: string, matrixId: string, evaluationId: string): Promise<EvaluationDetailsDTO> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(evaluationDetailsMock);
            }, 1000);
        });
    }

    getEvaluationBonus(locationId: string, businessLineId: string, preiodId: string, matrixId: string, evaluationId: string): Promise<EvaluationBonusResponseDTO> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(bonusMock);
            }, 1000);
        });
    }

    getUnjustifiedAbsences(locationId: string, businessLineId: string, preiodId: string, matrixId: string, evaluationId: string): Promise<string> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(unjustifiedAbsencesMock);
            }, 1000);
        });
    }

    getEvaluationCertificates(locationId: string, businessLineId: string, preiodId: string, matrixId: string, evaluationId: string): Promise<EvaluationCertificatesResponseDTO> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(certificatesMock);
            }, 1000);
        });
    }

    getEvaluationFollowUpItems(locationId: string, businessLineId: string, preiodId: string, matrixId: string, evaluationId: string): Promise<EvaluationFollowUpItemsResponseDTO> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(followUpItemsMock);
            }, 1000);
        });
    }

    getNextRoleDetails(locationId: string, businessLineId: string, matrixId: string, nextRoleId: string): Promise<NextRoleDetailsDTO> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(nextRoleLevelsMock);
            }, 1000);
        });
    }

    updateEvaluationDetails(locationId: string, businessLineId: string, periodId: string, matrixId: string, evaluationId: string, requestDto: EvaluationDetailsUpdateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${periodId}/EvaluationPeriodMatrices/${matrixId}/Evaluation/${evaluationId}`),
            data: requestDto,
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    updateEvaluationFollowUpItems(locationId: string, businessLineId: string, periodId: string, matrixId: string, evaluationId: string, requestDto: EvaluationFollowupItemsUpdateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${periodId}/EvaluationPeriodMatrices/${matrixId}/Evaluation/${evaluationId}`),
            data: requestDto,
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

}
