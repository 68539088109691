export enum EvaluationStatus {
    READY_TO_EVALUATE = 1,
    IN_PROGRESS = 2,
    CONFIRMED = 3,
    CALIBRATED = 4,
    CONCLUDED = 5,
    REJECTED = 6,
    CANCELED = 7,
}


export const EvaluationStatusTranslationTerms: { [key: string]: string } = {
    1: "COMMON.STATUS.ReadyToEvaluate",
    2: "COMMON.STATUS.InProgress",
    3: "COMMON.STATUS.Confirmed",
    4: "COMMON.STATUS.Calibrated",
    5: "COMMON.STATUS.Concluded",
    6: "COMMON.STATUS.Rejected",
    7: "COMMON.STATUS.Canceled",
}


export const EvaluationFollowUpItemStatusAsEnum: { [key: number]: EvaluationStatus } = {
    1: EvaluationStatus.READY_TO_EVALUATE,
    2: EvaluationStatus.IN_PROGRESS,
    3: EvaluationStatus.CONFIRMED,
    4: EvaluationStatus.CALIBRATED,
    5: EvaluationStatus.CONCLUDED,
    6: EvaluationStatus.REJECTED,
    7: EvaluationStatus.CANCELED,
};